import React from "react";
import {IonToolbar, IonTitle, IonButtons, IonButton, IonHeader} from "@ionic/react";
import {useRecoilValue} from "recoil";
import {useHistory} from "react-router-dom";

import {searchPageTypeState} from "../store/atoms";
import {SwitchCase} from ".";

interface IAppProps {
	title?: any;
	backHref?: string;
	onBackButton?: (data: any) => void;
	onHomeButton?: () => void;
	enableSearch?: boolean;
	backMode?: boolean;
	searchMode?: boolean;
	homeMode?: boolean;
	cancelMode?: boolean;
	cancel?: any;
	transparent?: boolean;
}

const HeaderBase: React.FC<IAppProps> = props => {
	const history = useHistory();
	const searchPageType = useRecoilValue(searchPageTypeState);

	return (
		<IonHeader className={`common-header${props.transparent ? " transparent" : ""}`}>
			<IonToolbar>
				{props.backMode === true || props.backHref || props.onBackButton ? (
					<IonButtons slot="start">
						<IonButton>
							<img
								src="assets/icon/icons_back.png"
								alt="back"
								onClick={props.onBackButton || (() => history.goBack())}
							/>
						</IonButton>
					</IonButtons>
				) : (
					<></>
				)}
				<SwitchCase
					tests={[
						{
							test: props.title,
							component: <IonTitle>{props.title}</IonTitle>,
						},
						{
							test: props.enableSearch,
							component: (
								<IonButtons slot="end">
									<IonButton routerLink={`/search/${searchPageType}`}>
										<img src="assets/icon/icons_search.png" alt="search" />
									</IonButton>
								</IonButtons>
							),
						},
						{
							test: props.cancelMode,
							component: (
								<IonButtons slot="end">
									<IonButton onClick={props.cancel}>
										<img src="assets/icon/icon_cancel.svg" alt="" />
									</IonButton>
								</IonButtons>
							),
						},
					]}
				/>

				{props.homeMode && (
					<IonButtons slot="primary">
						<IonButton
							onClick={
								props.onHomeButton ||
								(() => {
									history.push("/t/home");
								})
							}
						>
							<img src="assets/icon/icon_home.png" alt="" />
						</IonButton>
					</IonButtons>
				)}
			</IonToolbar>
		</IonHeader>
	);
};

export default HeaderBase;
