import {
	IonButton,
	IonButtons,
	IonCheckbox,
	IonChip,
	IonCol,
	IonContent,
	IonGrid,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonModal,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import {optionsOutline} from "ionicons/icons";
import React, {useState} from "react";
import {useHistory} from "react-router";
import {useRecoilState, useRecoilValue} from "recoil";
import {
	clinicAroundListState,
	clinicFilterState,
	clinicListState,
	clinicSearchTypeState,
	isOpenClinicModalState,
} from "../../store/atoms";
import {radiusItemSelector} from "../../store/selectors";
import {ClinicFilter} from "../../store/types";
import ClinicItem from "./ClinicItem";

const ClinicListModal: React.FC = () => {
	// 모달 시작 위치 조건 변수
	const clientHeight = document.body.clientHeight;
	const refVal = 880;

	const [clinicAroundList, setClinicAroundList] = useRecoilState(clinicAroundListState);
	const clinicList = useRecoilValue(clinicListState);
	const clinicSearchType = useRecoilValue(clinicSearchTypeState);
	const [isOpenClinicModal, setIsOpenClinicModal] = useRecoilState(isOpenClinicModalState);
	const [filter, setFilter] = useRecoilState(clinicFilterState);

	// 반경 선택 모달
	const [isOpenRadiusModal, setIsOpenRadiusModal] = useState(false);
	const radiusItems = useRecoilValue(radiusItemSelector);

	// 필터 모달
	const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);
	const [radius, setRadius] = useState<number>(filter.radius);
	const [checkedSpecilist, setcheckedSpecilist] = useState<boolean>(filter.onlySpecilist);

	const history = useHistory();

	return (
		<>
			{/* 병원 리스트 모달 */}
			<IonModal
				id="clinic-list-modal"
				isOpen={isOpenClinicModal}
				className="my-clinic-modal to-top"
				breakpoints={[(refVal / clientHeight) * 0.1, 1, 1]}
				initialBreakpoint={(refVal / clientHeight) * 0.1}
				showBackdrop={false}
				backdropDismiss={false}
				//onClick={() => setSelectedClinic(undefined)}
			>
				<div className="clinic-filter-wrap">
					<IonChip
						outline
						onClick={() => {
							//history.push(`/clinic-filter/${clinicSearchType}`);
							setIsOpenFilterModal(true);
						}}
					>
						<IonIcon icon={optionsOutline} />
						<IonLabel color="primary">필터</IonLabel>
					</IonChip>

					{clinicSearchType === "Around" ? (
						<IonChip outline onClick={() => setIsOpenRadiusModal(true)}>
							{radiusItems.find(x => x.value === filter.radius)?.name}
							<img src="assets/icon/icons_arrow_down.png" alt="down arrow" className="icon" />
						</IonChip>
					) : (
						<></>
					)}

					<IonChip
						outline
						className={filter.onlySpecilist ? "specilist" : "normal"}
						onClick={() =>
							setFilter((prev: ClinicFilter) => {
								return {
									radius: prev.radius,
									onlySpecilist: !prev.onlySpecilist,
								};
							})
						}
					>
						<IonLabel color="primary">전문의</IonLabel>
					</IonChip>
				</div>

				<IonContent>
					<IonList>
						{((clinicSearchType === "Around" ? clinicAroundList : clinicList)?.length ?? 0) === 0 ? (
							<p className="ion-padding no-result">
								검색 결과가 없습니다.
								<br />
								검색어를 다시 확인해주세요.
							</p>
						) : (
							<></>
						)}
						{(clinicSearchType === "Around" ? clinicAroundList : clinicList)?.map((clinic, index) => {
							return (
								<ClinicItem clinic={clinic} key={`clinic_${clinic.id}`} showBookmark={true}></ClinicItem>
							);
						})}
					</IonList>
				</IonContent>
			</IonModal>

			{/* 반경 선택 모달 */}
			<IonModal
				isOpen={isOpenRadiusModal}
				// onWillPresent={() => setIsOpenClinicModal(false)}
				// onWillDismiss={() => setIsOpenClinicModal(true)}
				className="filter"
				showBackdrop
			>
				<IonItem className="ion-padding modal-title">
					<IonLabel>검색 반경</IonLabel>
					<IonButton slot="end" onClick={() => setIsOpenRadiusModal(false)} className="">
						<img src="assets/icon/icons_close_black.png" alt="close" className="icon" />
					</IonButton>
				</IonItem>
				<IonList>
					<IonRadioGroup
						className="modal-radio"
						value={filter.radius}
						onIonChange={event => {
							setFilter((prev: ClinicFilter) => {
								return {
									radius: Number(event.detail.value),
									onlySpecilist: prev.onlySpecilist,
								};
							});
							setIsOpenRadiusModal(false);
						}}
					>
						{radiusItems.map((item, index) => {
							return (
								// <IonSelectOption key={index} value={item.value}>{item.name}</IonSelectOption>
								<IonItem key={`radius_filter_${item.name}`} className="ion-padding">
									<IonLabel>{item.name}</IonLabel>
									<IonRadio slot="end" value={item.value} mode="md" />
								</IonItem>
							);
						})}
					</IonRadioGroup>
				</IonList>
			</IonModal>

			{/* 필터 모달 */}
			<IonModal
				className="my-clinic-modal to-top pg-filter"
				isOpen={isOpenFilterModal}
				//breakpoints={[0.0, 1, 1]}
				initialBreakpoint={1}
				showBackdrop={true}
				swipeToClose={true}
				onWillPresent={() => {
					setIsOpenRadiusModal(false);
					setRadius(filter.radius);
					setcheckedSpecilist(filter.onlySpecilist);
				}}
				onDidPresent={() => setIsOpenFilterModal(true)}
				onDidDismiss={() => setIsOpenFilterModal(false)}
			>
				<IonToolbar>
					<IonButtons slot="start">
						<IonButton>
							<img
								src="assets/icon/icons_back.png"
								className="icon"
								alt="back"
								onClick={() => setIsOpenFilterModal(false)}
							/>
						</IonButton>
					</IonButtons>

					<IonTitle>필터</IonTitle>

					<IonButtons slot="primary">
						<IonButton
							className="reset"
							onClick={() => {
								if (clinicSearchType === "Around") setRadius(filter.radius);
								setcheckedSpecilist(filter.onlySpecilist);
							}}
						>
							초기화
						</IonButton>
					</IonButtons>
				</IonToolbar>

				<IonContent>
					<div className="content-inner">
						<IonGrid className="ion-padding">
							{clinicSearchType === "Around" ? (
								<IonRow>
									<IonCol>
										<IonLabel className="title">검색 반경</IonLabel>
										<IonList>
											<IonRadioGroup
												id="RadioGroup"
												value={radius}
												onIonChange={event => setRadius(Number(event.detail.value))}
											>
												{radiusItems.map((item: any, index) => (
													<IonItem key={item.name}>
														<IonLabel>{item.name}</IonLabel>
														<IonRadio slot="end" value={item.value} mode="md" />
													</IonItem>
												))}
											</IonRadioGroup>
										</IonList>
									</IonCol>
								</IonRow>
							) : (
								<></>
							)}

							<IonRow>
								<IonCol>
									<IonLabel className="title">의사</IonLabel>
									<IonItem>
										<IonCheckbox
											slot="end"
											color="primary"
											checked={checkedSpecilist}
											onIonChange={event => setcheckedSpecilist(event.detail.checked)}
										/>
										<IonLabel className="label">전문의</IonLabel>
									</IonItem>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonButton
										expand="full"
										shape="round"
										mode="md"
										onClick={() => {
											setFilter(prev => {
												return {
													radius: clinicSearchType === "Map" ? prev.radius : radius,
													onlySpecilist: checkedSpecilist,
												};
											});
											setIsOpenFilterModal(false);
										}}
									>
										필터 적용하기
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</IonContent>
			</IonModal>
		</>
	);
};

export default ClinicListModal;
