/* eslint-disable react-hooks/exhaustive-deps */
import {IonAvatar, IonBadge, IonItem, IonLabel} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Clinic} from "../store/types";
import BookmarkButton from "./container/BookmarkButton";
import {useRecoilState, useSetRecoilState} from "recoil";

import {selectedClinicState} from "../store/atoms";

import {useApi} from "../hooks/api";

interface SelectedClnicItemProps {
	clinic: Clinic;
	setSelectedClinic?: any;
}

const SelectedClinicItem: React.FC<SelectedClnicItemProps> = props => {
	const clinic = props.clinic;
	const [bookmark, setBookmark] = useState(clinic.isFav);
	const [selectedClinic, setSelectedClinic] = useRecoilState(selectedClinicState);

	const history = useHistory();
	const{processApi} = useApi();

	async function fetchClinicItemAndSetBookmark() {
		try {
			let parematers: any = {id: clinic.id};
			const data = await processApi<any>("clinic-item", parematers);
			setBookmark(data.isFav);
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		fetchClinicItemAndSetBookmark();
	}, [selectedClinic]);

	return (
		<div className="wrap">
			<IonItem className="custom_typecontrol">
				<IonLabel
					onClick={() => {
						history.push(`/clinic/${clinic.id}`);
					}}
				>
					{clinic.doctorType === "SPECIALIST" && <IonBadge color="primary">전문의</IonBadge>}
					<h3>{clinic.name}</h3>
					{(clinic?.distance ?? 0) !== 0 ? (
						<p>{`${(clinic.distance ?? 0) > 1000 ? (clinic.distance ?? 0) / 1000 : clinic.distance ?? 0}
								${(clinic.distance ?? 0) > 1000 ? "km" : "m"} | ${clinic.address}`}</p>
					) : (
						<p>{clinic.address}</p>
					)}
				</IonLabel>
				<BookmarkButton
					type="Clinic"
					item={clinic}
					bookmark={bookmark}
					setBookmark={setBookmark}
				></BookmarkButton>
			</IonItem>
		</div>
	);
};

export default SelectedClinicItem;
