/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {userState, tokenState, guidePopupState} from "../store/atoms";
import jwt_decode from "jwt-decode";
import {cleanup} from "@testing-library/react";

const UserEffect: React.FC = (): any => {
	const [token, setToken] = useRecoilState(tokenState);
	const [user, setUser] = useRecoilState(userState);
	const setGuideState = useSetRecoilState(guidePopupState);

	function cleanUp() {
		setToken(null as any);
		setUser(null as any);
		setGuideState(null as any);

		localStorage.removeItem("analysisKey");
		localStorage.removeItem("analysisValue");
		localStorage.removeItem("analysisPartValue");
		localStorage.removeItem("guidePopup");
		localStorage.removeItem("recoil-persist");
		localStorage.removeItem("compareAnalysisKey");
	}
	useEffect(() => {
		return cleanup();
	}, []);

	useEffect(() => {
		try {
			if (token) {
				const decoded: any = jwt_decode(token.token);
				setUser(decoded);
			} else {
				cleanUp();
			}
		} catch (e) {
			console.log(e);
			cleanUp();
		}
	}, [token]);

	return null;
};

export default UserEffect;
