/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback, useState} from "react";
import {
	IonPage,
	IonLoading,
	IonContent,
	IonItem,
	IonLabel,
	IonList,
	IonButton,
	IonToggle,
	useIonAlert,
} from "@ionic/react";
import HeaderBase from "../../components/HeaderBase";
import {useHistory} from "react-router";
import {useRecoilState} from "recoil";
import {guidePopupState, tokenState, userDetailState} from "../../store/atoms";
import {useApi} from "../../hooks/api";
import {useAuth} from "../../hooks/auth";

const MyPage: React.FC = () => {
	const [token, setToken] = useRecoilState(tokenState);
	const [guidePopup, setGuidePopup] = useRecoilState(guidePopupState);
	const [userDetail, setUserDetail] = useRecoilState(userDetailState);
	const {isLoggedIn, user} = useAuth();
	const history = useHistory();
	const [emailAgreeChecked, setEmailAgreeChecked] = useState(false);
	const [isToggleEnabled, setIsToggleEnabled] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [present] = useIonAlert();

	const {processApi} = useApi();

	const fetchData = useCallback(async () => {
		try {
			const userData = await processApi<any>("user");
			setEmailAgreeChecked(userData.emailAgree);
			setIsToggleEnabled(true);
			setShowLoading(false);
			setUserDetail(data => {
				return {...data, ...userData};
			});
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		if (user) {
			setShowLoading(true);
			fetchData();
		}
	}, [user]);

	const logout = useCallback(() => {
		setToken(null as any);
		setGuidePopup(null as any);
		// 전체 페이지 reload (cache 삭제)
		(window as any).location = "/";
	}, []);

	const onCheckChange = async (e: any) => {
		if (isToggleEnabled) {
			if (userDetail) {
				try {
					await processApi<any>("update-user", {
						emailAgree: e.detail.checked,
					});
					setEmailAgreeChecked(e.detail.checked);
				} catch (error) {
					console.error(error);
				}
			}
		}
	};

	function handleLoginClick() {
		if (isLoggedIn) return;
		present({
			message: "로그인이 필요한 서비스입니다.</br>AI2U 회원이라면 로그인해주세요.",
			buttons: [
				{
					text: "취소",
					handler: () => window.location.reload(),
				},
				{
					text: "로그인",
					cssClass: "primary",
					handler: () => history.push("/login"),
				},
			],
			cssClass: "alert-css2",
		});
	}

	function handleLogoutClick() {
		present({
			/*header: 'Use this lightsaber?',*/
			message: "로그아웃 하시겠습니까?",
			buttons: [
				"취소",
				{
					text: "로그아웃",
					cssClass: "primary",
					handler: logout,
				},
			],
			cssClass: "alert-css1",
		});
	}

	return (
		<IonPage className="pg-mypage">
			<HeaderBase title="마이 페이지" backHref="" />

			<IonContent fullscreen>
				<div className="content-inner">
					<IonLoading
						isOpen={showLoading}
						showBackdrop={false}
						translucent={true}
						onDidDismiss={() => setShowLoading(false)}
					/>
					{!showLoading && (
						<IonList>
							{user ? (
								<IonItem className="ion-padding">
									<IonLabel>
										<h2>{user.realName}</h2>
										<h3>{user.route === "email" ? user.username : `${user.route} 로그인 중`}</h3>
										<IonButton
											routerLink="/myinfo"
											className="mypage"
											expand="block"
											size="default"
											mode="md"
										>
											내 정보 수정
										</IonButton>
									</IonLabel>
									<img src="assets/img/img_banner_2.png" alt="information" />
								</IonItem>
							) : (
								<IonItem className="ion-padding">
									<IonLabel>
										<p>
											<strong>로그인</strong>을 하시면 <strong>다양한</strong>
											<br />
											<strong>서비스 이용</strong>이 가능합니다.
										</p>
										<IonButton routerLink="/login" className="mypage" expand="block" size="default" mode="md">
											로그인
										</IonButton>
									</IonLabel>
									<img src="assets/img/img_banner_2.png" alt="information" />
								</IonItem>
							)}
							<IonItem
								routerLink={user ? "/mypage/bookmark" : "/t/mypage"}
								detail={false}
								onClick={() => (user ? "" : handleLoginClick())}
							>
								<IonLabel className="ion-padding">나의 북마크</IonLabel>
								<img src="assets/icon/icons_next.png" className="icon" alt="move" />
							</IonItem>
							<IonItem routerLink="/notice" detail={false}>
								<IonLabel className="ion-padding">공지사항</IonLabel>
								<img src="assets/icon/icons_next.png" className="icon" alt="move" />
							</IonItem>
							<IonItem routerLink="/faq" detail={false}>
								<IonLabel className="ion-padding">자주 묻는 질문</IonLabel>
								<img src="assets/icon/icons_next.png" className="icon" alt="move" />
							</IonItem>
							<IonItem detail={false}>
								<IonLabel className="ion-padding">이메일 수신 동의</IonLabel>
								<IonToggle
									className="ion-padding"
									checked={emailAgreeChecked}
									onIonChange={e => user && onCheckChange(e)}
									disabled={!user}
								></IonToggle>
							</IonItem>
							<IonItem routerLink="/mypage/terms" detail={false}>
								<IonLabel className="ion-padding">약관보기</IonLabel>
								<img src="assets/icon/icons_next.png" className="icon" alt="move" />
							</IonItem>
							{user ? (
								<IonItem routerLink={"/t/mypage"} detail={false} onClick={() => handleLogoutClick()}>
									<IonLabel className="ion-padding">로그아웃</IonLabel>
									<img src="assets/icon/icons_next.png" className="icon" alt="move" />
								</IonItem>
							) : (
								<p></p>
							)}
						</IonList>
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default MyPage;
