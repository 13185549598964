import React, {useState} from "react";
import {
	IonPage,
	IonContent,
	IonLoading,
	IonItem,
	IonLabel,
	IonGrid,
	IonCol,
	IonRow,
	IonButton,
	IonCheckbox,
	useIonAlert,
	useIonToast,
} from "@ionic/react";

import HeaderBase from "../../components/HeaderBase";
import {tokenState} from "../../store/atoms";
import {useRecoilState} from "recoil";
import {useHistory} from "react-router";
import {useApi} from "../../hooks/api";
import {alertController} from "@ionic/core";

const MyAccountClose: React.FC = () => {
	const [token, setToken] = useRecoilState(tokenState);
	const history = useHistory();
	const {processApi} = useApi();
	const [showLoading, setShowLoading] = useState(false);
	const [present, showAlert] = useIonAlert();
	const [showToast] = useIonToast();
	const [isChecked, setIsChecked] = useState(false);

	function confirm(message: string) {
		present({
			message: message,
			buttons: [
				{
					text: "취소",
				},
				{
					text: "탈퇴",
					cssClass: "primary",
					handler: () => deleteUser(),
				},
			],
			cssClass: "alert-css1",
		});
	}
	const deleteUser = async () => {
		try {
			setShowLoading(true);
			await processApi<any>("delete-user");
			setShowLoading(false);
			const alert = await alertController.create({
				/*header: 'Use this lightsaber?',*/
				message: "회원 탈퇴가<br/>완료되었습니다.",
				buttons: [{text: "확인", cssClass: "primary", handler: () => goToMain()}],
				cssClass: "alert-css2",
			});
			await alert.present();
		} catch (error: any) {
			setShowLoading(false);
			showToast({
				message: "처리 중 오류가 발생하였습니다.",
				duration: 600,
			});
		}
	};

	const goToMain = () => {
		setToken(null as any);
		history.replace("/");
	};

	return (
		<IonPage className="pg-mypage-info pg-myaccount-close">
			<HeaderBase title="회원 탈퇴" backHref="/" />
			<IonContent fullscreen>
				<div className="content-inner">
					<IonGrid className="ion-padding">
						<IonLoading
							isOpen={showLoading}
							message="잠시만 기다려주세요."
							onDidDismiss={() => setShowLoading(false)}
						/>
						<IonRow>
							<IonCol>
								<h2>회원 탈퇴하시기 전에 안내사항을 꼭 확인하세요.</h2>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<p>1. 탈퇴 신청 시 즉시 탈퇴 처리되며 철회는 불가능합니다.</p>
								<p>2. 탈퇴 후 동일한 휴대폰번호로 30일 동안 재가입이 불가능합니다.</p>
								<p>
									3. 탈퇴시 분석기록, 북마크 등 저장된 데이터가 모두 삭제되고, 재가입 시에도 데이터는 복구되지
									않습니다.
								</p>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonItem className="check">
									<IonCheckbox
										onIonChange={e => setIsChecked(e.detail.checked)}
										slot="start"
										color="primary"
									/>
									<IonLabel className="label">안내사항을 모두 확인하였습니다.</IonLabel>
								</IonItem>
								<IonButton
									className="btn-close"
									expand="full"
									shape="round"
									mode="md"
									onClick={() => confirm("정말 탈퇴 하시겠습니까?")}
									disabled={!isChecked}
								>
									회원 탈퇴하기
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default MyAccountClose;
