import {IonItem, IonLabel} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Video} from "../store/types";
import default_Img from "../img/example.png";
import BookmarkButton from "./container/BookmarkButton";

interface IVideoItemProps {
	video: Video;
	bookmark?: boolean;
}

export const onErrorImg = (e: any) => {
	e.target.src = default_Img;
};

const VideoItem: React.FC<IVideoItemProps> = props => {
	const video = props.video;
	const history = useHistory();

	const [bookmark, setBookmark] = useState(video.isFav);

	useEffect(() => {
		setBookmark(video.isFav);
	}, [video.isFav]);
	return (
		<>
			<IonItem lines="none" className="ion-padding video-item">
				<img
					className="clinic"
					src={`${video.thumbnail}`}
					slot="start"
					//onClick={() => window.open(video.videoLink, "_blank")}
					onClick={() => history.push(`/video/${video.id}`)}
					onError={onErrorImg}
					alt="clinic"
				/>

				<IonLabel onClick={() => history.push(`/video/${video.id}`)}>
					<h5>{video.category?.name}</h5>
					<h4>{video.title}</h4>
					<h5>{video.doctorName}</h5>
				</IonLabel>

				{video.isAd ? (
					<div className="bookmark-area ad">
						<i className="icon-ad" />
					</div>
				) : (
					<BookmarkButton
						bookmark={bookmark}
						setBookmark={setBookmark}
						type={"Video"}
						item={video}
					></BookmarkButton>
				)}
			</IonItem>
		</>
	);
};

export default VideoItem;
