/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback, useState} from "react";
import {
	IonPage,
	IonContent,
	IonItem,
	IonLabel,
	IonList,
	IonGrid,
	IonCol,
	IonRow,
	IonButton,
	IonCheckbox,
	CheckboxChangeEventDetail,
	IonModal,
	IonHeader,
	IonTitle,
	IonButtons,
	IonToolbar,
	useIonToast,
} from "@ionic/react";

import HeaderBase from "../../components/HeaderBase";
import {useRecoilState} from "recoil";
import {termsListState, termsSingleState} from "../../store/atoms";
import {useApi} from "../../hooks/api";
import shortid from "shortid";

interface TermsProps {
	onPrev: (data: any) => void;
	onNext: (data: any) => void;
}

interface Checkboxes {
	checked: boolean;
}

const Terms: React.FC<TermsProps> = ({onPrev, onNext}) => {
	const {processApi} = useApi();

	const [list, setList] = useRecoilState(termsListState);
	const [term, setTerm] = useRecoilState(termsSingleState);

	const [modal, setModal] = useState(false);
	const [timestamp, setTimestamp] = useState(0);
	const [checkboxes, setCheckboxes] = useState<Checkboxes[]>([{checked: false}]);
	const [disabledNextButton, setDisabledNextButton] = useState(true);
	const [present] = useIonToast();

	const fetchList = useCallback(async () => {
		const list = await processApi<any>("terms");
		setList(list);
		setCheckboxes(
			new Array(list.length + 1).fill({
				checked: false,
			}),
		);
	}, []);

	const fetchTermDetailData = useCallback(async id => {
		const data = await processApi<any>("terms-item", {id});
		setTerm(data);
	}, []);

	useEffect(() => {
		fetchList();
		return () => {
			setCheckboxes([]);
			setList([]);
		};
	}, []);

	useEffect(() => {
		if (list.length === 0) {
			setDisabledNextButton(true);
		} else {
			setDisabledNextButton(
				!list.every((item, index) => !item.required || (item.required && checkboxes[index + 1].checked)),
			);
		}
	}, [checkboxes]);

	const onClickTermsDetail = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		e.preventDefault();
		await fetchTermDetailData(index);
		setModal(true);
	};

	const onDismissModal = () => {
		setModal(false);
	};

	const onCheckboxChange = (event: CustomEvent<CheckboxChangeEventDetail>, index: number) => {
		if (event.timeStamp - timestamp < 30) {
			return;
		}
		setTimestamp(event.timeStamp);
		if (index > 0) {
			const checkboxTemp = [
				...checkboxes.slice(1, index),
				{
					checked: event.detail.checked,
				},
				...checkboxes.slice(index + 1),
			];
			setCheckboxes([{checked: checkboxTemp.every(item => item.checked)}, ...checkboxTemp]);
		} else {
			setCheckboxes([...checkboxes.fill({checked: event.detail.checked})]);
		}
	};

	const isDisabled = list.every(
		(item, index) => !item.required || (item.required && checkboxes[index + 1].checked),
	);

	const onAgree = () => {
		if (list.every((item, index) => !item.required || (item.required && checkboxes[index + 1].checked))) {
			const ids = list.map(it => it.id).join(";");
			onNext({terms: ids});
		} else {
			present("필수 이용약관에 동의해주세요.", 3000);
		}
	};

	return (
		<>
			<IonPage className="pg-terms">
				<HeaderBase title="약관동의" backHref="/" onBackButton={onPrev} />
				<IonContent fullscreen>
					<div className="content-inner">
						<IonGrid className="ion-padding">
							<IonRow>
								<IonCol>
									<div className="title-sub">SDOC 서비스 이용을 위해 약관을 동의해주세요.</div>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonList>
										<IonItem>
											<IonCheckbox
												slot="start"
												color="primary"
												checked={checkboxes[0].checked}
												onIonChange={event => onCheckboxChange(event, 0)}
											/>
											<IonLabel className="label">전체동의</IonLabel>
										</IonItem>
										{list.map((x, index) => (
											<IonItem key={`terms-item-${index}`}>
												<IonCheckbox
													slot="start"
													color="primary"
													checked={checkboxes[index + 1].checked}
													onIonChange={event => onCheckboxChange(event, index + 1)}
												/>
												<IonLabel className="label">
													{x.title}
													{x.required ? "(필수)" : "(선택)"}
												</IonLabel>
												<div slot="end" className="detail" onClick={e => onClickTermsDetail(e, x.id)}>
													보기
												</div>
											</IonItem>
										))}
									</IonList>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<IonButton
										disabled={disabledNextButton}
										className=""
										expand="full"
										onClick={onAgree}
										mode="md"
										shape="round"
									>
										확인
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</IonContent>
			</IonPage>
			<IonModal isOpen={modal} className="modal-terms">
				<IonHeader translucent>
					<IonToolbar>
						<IonTitle>{`${term.title}${term.required ? "(필수)" : "(선택)"}`}</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={onDismissModal}>
								<img src="assets/icon/icons_close_black.png" alt="close" className="icon" />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{term.content?.split("\n").map(item => {
						return (
							<p className="ion-padding" key={shortid.generate()}>
								{item}
								<br />
							</p>
						);
					})}
				</IonContent>
			</IonModal>
		</>
	);
};

export default Terms;
