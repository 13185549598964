/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useState} from "react";
import {IonButton, IonContent, IonPage, IonGrid, IonRow, IonCol, useIonAlert} from "@ionic/react";
import {useHistory} from "react-router";
import HeaderBase from "../../components/HeaderBase";
import Verification from "../login/Verification";

const FindEmail: React.FC = () => {
	const history = useHistory();
	const [showAlert, hideAlert] = useIonAlert();
	const [email, setEmail] = useState<string>();
	const [error, setError] = useState<string>();

	const onNext = useCallback((payload: any) => {
		if (payload.email) {
			setEmail(payload.email);
		}
		else if(payload.error) {
			setError(payload.error)
		}
		else {
			showAlert({
				cssClass: "alert-css2",
				message: "입력하신 정보와 일치하는</br>회원이 없습니다.",
				buttons: [{text: "확인", cssClass: "primary", handler: d => history.replace("/login/email")}],
			});
		}
	}, []);

	
	if(error === "notExist")
		return (
			<IonPage className="pg-sign-up pg-vrf">
				<HeaderBase title="이메일 찾기" backHref="" />
				<IonContent fullscreen>
					<div className="content-inner">
						<IonGrid className="ion-padding pg-find-email">
							<IonRow>
								<IonCol>
									<div className="content-sub">서비스에 가입된 내역이 없습니다. 회원가입 해주세요<br /><br /></div>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton
										className=""
										expand="full"
										onClick={() => history.replace("/login")}
										mode="md"
										shape="round"
									>
										회원가입
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</IonContent>
			</IonPage>
		)
	else 
		return (
			<>
				{!email && (
					<Verification
						action={"find-email"}
						headerTitle={"이메일찾기"}
						backHref={"/"}
						onNext={onNext}
						onError={() => history.replace("/login/email")}
						content={"본인 인증을 통해\n이메일을 찾으실 수 있습니다."}
					/>
				)}

				{email && (
					<IonPage className="pg-sign-up pg-vrf">
						<HeaderBase title="이메일 찾기" backHref="" />
						<IonContent fullscreen>
							<div className="content-inner">
								<IonGrid className="ion-padding pg-find-email">
									<IonRow>
										<IonCol>
											<div className="content-sub">
											회원님이 가입하신 이메일 주소입니다. <br />
												<br />
											</div>
											<div className="content-main">{email}</div>	
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonButton
												className=""
												expand="full"
												onClick={() => history.replace("/login/email")}
												mode="md"
												shape="round"
											>
												로그인
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							</div>
						</IonContent>
					</IonPage>
				)}
			</>
		);
};

export default FindEmail;
