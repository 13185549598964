/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useState} from "react";
import {IonLoading, useIonToast} from "@ionic/react";

import {useHistory} from "react-router";
import Verification from "./Verification";
import SignUp from "./SignUp";
import SignUpStep2 from "./SignUpStep2";
import Survey from "./Survey";
import Terms from "./Terms";

import {useRecoilState} from "recoil";
import {tokenState} from "../../store/atoms";
import {useApi} from "../../hooks/api";

interface FormData {
	certToken?: string;
	username?: string;
	password?: string;
	passwordComfirm?: string;
	realName?: string;
	birthDay?: string;
	gender?: string;
	nick?: string;
}

const RegAsEmail: React.FC = () => {
	const [token, setToken] = useRecoilState(tokenState);
	const [showLoading, setShowLoading] = useState(false);
	const [present] = useIonToast();

	const [page, setPage] = useState<number>(1);
	const [max, setMax] = useState<number>(5);
	const [form, setForm] = useState<FormData>({});

	//let form : FormData = {};
	const history = useHistory();
	const {processApi} = useApi();

	const goNext = useCallback(() => {
		setPage(prev => Math.min(prev + 1, max));
	}, []);

	const goPrev = useCallback(() => {
		setPage(prev => Math.max(1, prev - 1));
	}, []);

	const backPage = (data: any) => {
		goPrev();
	};

	const saveData = (data: any) => {
		setForm({...form, ...data});
		goNext();
	};

	const saveDataAndSubmit = async (data: any) => {
		const body = {...form, ...data};
		try {
			setShowLoading(true);
			await processApi<any>("reg", body);
			// 회원가입 성공 -> 로그인 처리
			const response = await processApi<any>("login", {username: body.username, password: body.password});
			setToken({token: response});
			history.replace("/sign-up/complete");
		} catch (e: any) {
			present(`회원가입 중 문제가 발생하였습니다. ${e}`, 3000);
		} finally {
			setShowLoading(false);
		}
	};

	return (
		<>
			<span className="login-page-no">
				{page}/{max}
			</span>
			<IonLoading
				isOpen={showLoading}
				message="잠시만 기다려주세요."
				onDidDismiss={() => setShowLoading(false)}
			/>
			{page === 1 && <Verification action={"reg"} onNext={saveData} onError={() => history.replace("/")} />}
			{page === 2 && <Terms onPrev={backPage} onNext={saveData} />}
			{page === 3 && <SignUp onPrev={backPage} onNext={saveData} />}
			{page === 4 && <SignUpStep2 onPrev={backPage} token={form.certToken} onNext={saveData} />}
			{page === 5 && <Survey onPrev={backPage} onNext={saveDataAndSubmit} />}
		</>
	);
};

export default RegAsEmail;
