/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {useRecoilState} from "recoil";
import {IonContent, IonPage} from "@ionic/react";
import {useApi} from "../../hooks/api";
import {HeaderBase} from "../../components";
import {infoDetailState} from "../../store/atoms";
import BookmarkButton from "../../components/container/BookmarkButton";

const SkinInfoDetailPage: React.FC = () => {
	const params: any = useParams();
	const {processApi} = useApi();

	const [info, setInfo] = useRecoilState(infoDetailState);
	const [bookmark, setBookmark] = useState<any>(info?.isFav);

	const getData = useCallback(async () => {
		try {
			const data = await processApi<any>("info-item", {id: params.id});
			setInfo(data);
			setBookmark(data.isFav);
		} catch (error) {
			setInfo(null);
			setBookmark(null);
		}
	}, [params.id]);

	useEffect(() => {
		getData();

		return () => {
			setInfo(null);
			setBookmark(null);
		};
	}, [params.id]);

	return (
		<IonPage className="pg-skin-info-detail">
			<HeaderBase title={info?.title} backHref="/" />
			<div className="bookmark-contents">
				{info?.isAd ? (
					<div className="bookmark-area ad">
						<i className="icon-ad" />
					</div>
				) : (
					<BookmarkButton
						bookmark={bookmark}
						bookmarkInfo
						setBookmark={setBookmark}
						type={"Info"}
						item={info}
						className={`${bookmark ? "info-bookmark-active" : "info-bookmark"}`}
					/>
				)}
			</div>
			<IonContent>
				<div className="content-inner">
					<div className="contents">
						{info?.imageList?.map((item, index) => (
							<div className="detail-item" key={index}>
								<img src={item} alt="" />
							</div>
						))}
						{info?.isAd && (
							<div className="ad-area">
								<button className="btn-ad" onClick={() => window.open(info?.adLink, "_blank")}>
									{info?.adLinkTitle}
								</button>
							</div>
						)}
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SkinInfoDetailPage;
