/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {IonContent, IonPage, getPlatforms, useIonAlert} from "@ionic/react";
import {useRecoilState} from "recoil";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {useApi} from "../../../hooks/api";
import {useAuth} from "../../../hooks/auth";
import {HeaderBase, SwitchCase} from "../../../components";
import {clinicSearchTypeState, compareLogState, logTypeState} from "../../../store/atoms";
import ChangeThumbnailSlider from "../../../components/presentational/ChangeThumbnailSlider";
import {bannerSliderOption, mainBannerImg} from "../../configs";
import {BannerType} from "../../../store/types";

const ChangeResultPage: React.FC<RouteComponentProps> = ({history}) => {
	const {user} = useAuth();
	const {analysisProcessApi, processApi} = useApi();

	const [showAlert] = useIonAlert();

	const [data, setData] = useState<any>([]);
	const [analysisImgData, setAnalysisImgData] = useState<any>([]);
	const [skinwikiData, setSkinwikiData] = useState<any>([]);
	const [analysisImgId, setAnalysisImgId] = useState<any>([]);
	const [arMode, setArMode] = useState<boolean>(false);
	const [device, setDevice] = useState("");

	const [clinicSearchType, setClinicSearchType] = useRecoilState(clinicSearchTypeState);
	const [compareAnalysisKey] = useRecoilState(compareLogState);
	const [analysisMenuValue] = useRecoilState(logTypeState);

	const [adBannerList, setAdBannerList] = useState<BannerType>();
	const [bannerAdType, setBannerAdType] = useState<number>(1);
	const [bannerSectionType, setBannerSectionType] = useState<number>(5);

	const paginationOption = {
		customPaging: function (slider: any) {
			return (
				<span>
					{slider + 1}/{adBannerList?.totalCount}
				</span>
			);
		},
	};

	const getData = async () => {
		try {
			const {data} = await analysisProcessApi<any>("log-comparison", {
				analysisKeyArr: compareAnalysisKey,
			});

			setData(data);
			setAnalysisImgData(data.resultOfComparedImages);
			setSkinwikiData(data.skinwikiList);
		} catch (error) {
			console.log(error);
		}
	};

	const getdBannerList = async () => {
		let params = {
			adType: bannerAdType,
			sectionType: bannerSectionType,
		};

		try {
			const data = await processApi<any>("ad", params);
			setAdBannerList(data);
		} catch (e) {
			console.log(e);
		}
	};

	const handleChangeResultImg = (id: any, checked: any) => {
		const imgData = [...analysisImgId];

		if (checked) {
			imgData[id].checked = true;
			setAnalysisImgId(imgData);
		} else {
			imgData[id].checked = false;
			setAnalysisImgId(imgData);
		}
	};

	const handleMoveToHospitalPage = () => {
		setClinicSearchType("Around");
		history.push({pathname: "/t/hospital", state: {hospitalSearchType: clinicSearchType}});
	};

	const handleMoveToVideoPage = (value: any) => {
		if (data) {
			history.push({pathname: "/t/video", state: {videoFilterCategory: value}});
		}
	};

	const handleMoveToInfoPage = (value: any) => {
		if (data) {
			history.push({pathname: "/t/info", state: {infoFilterCategory: value}});
		}
	};

	const handleClickAr = () => {
		setArMode(!arMode);

		if (device === "desktop") {
			showAlert({
				message: "모바일에서 사용 가능한 서비스입니다.",
				buttons: [
					{
						text: "확인",
						cssClass: "primary",
						handler: d => history.go(0),
					},
				],
				cssClass: "alert-css2",
			});
		} else {
			setTimeout(() => {
				history.push("/skin-change/ar/result");
			}, 500);
		}
	};

	useEffect(() => {
		getPlatforms().map(item => {
			setDevice(item);
		});
	}, []);

	useEffect(() => {
		getData();
		getdBannerList();
	}, []);

	useEffect(() => {
		const imgData = [...analysisImgData];
		imgData.map((item: any) => (item["checked"] = true));
		setAnalysisImgId(imgData);
	}, [analysisImgData]);

	return (
		<IonPage>
			<HeaderBase title="내 피부 분석 결과" backMode />
			<IonContent>
				<div className="content-inner analysis-result__wrap analysis-result__change">
					<div className="title-area">
						<div className="title">
							<h2>
								<span className="name">{user?.realName}</span> 님의 피부변화
							</h2>
							{analysisMenuValue === "face" && (
								<button className={`${arMode ? "btn-ar active" : "btn-ar"}`} onClick={handleClickAr}>
									AR 분석
								</button>
							)}
						</div>
					</div>

					<SwitchCase
						tests={[
							{
								test: analysisMenuValue === "face",
								component: (
									<ChangeThumbnailSlider
										slideImg={analysisImgId}
										text="피부변화비교"
										onChangeToggle={handleChangeResultImg}
									/>
								),
							},
							{
								test: analysisMenuValue === "body",
								component: (
									<>
										{analysisImgData?.map(
											(item: any, index: any) =>
												item.index !== 0 && (
													<div className={`body-img${index === 1 ? " after" : ""}`} key={index}>
														<div className="img-area">
															<img src={item.beforeImageUrl} alt="" />
															<p>
																{item.date}의 예상 질환 <br />
																{item.diseaseClazz?.map((disease: any, index: any) => (
																	<span key={index}>
																		{`${index === 1 ? ". " : ""}`}
																		{disease.text}
																	</span>
																))}
															</p>
														</div>
													</div>
												),
										)}
									</>
								),
							},
						]}
					/>

					<div className="analysis-result__contents">
						<div className="contents">
							<div className="info-area">
								<i className="icon-hospital" />
								<p className="info">
									<span>{user?.realName}</span>님의 분석결과는 <span className="highlight">예상질환</span>
									으로써,
									<br />
									정확한 진단은 반드시&nbsp;
									<span className="highlight">전문적인 의료기관을 방문하여 진료</span>를 받으시길 바랍니다.
								</p>
							</div>

							<div className="analysis-result__hospital">
								<div className="button-area">
									<button className="btn" onClick={handleMoveToHospitalPage}>
										가까운 피부과 병원 추천해 드릴까요?
										<i className="icon-arrow icon-arrow-white" />
									</button>
								</div>
							</div>

							<Slider {...bannerSliderOption} {...paginationOption} className="banner-slider">
								{adBannerList?.detailList?.map((item, index) => (
									<div key={index}>
										<img src={item.fileUrl} onClick={() => window.open(item.targetUrl)} alt="ad" />
									</div>
								))}
							</Slider>

							<div className="analysis-result__list">
								{skinwikiData?.map((item: any, index: any) => (
									<div className="list-area" key={index}>
										<div className="list-title">
											<h3>{item.category?.name}</h3>
											<button onClick={() => handleMoveToInfoPage(item.category?.key)}>
												<i className="icon-guide" />
												질환 정보
											</button>
										</div>
										<div className="photo-area">
											{item.imageList.map((img: any, index: any) => (
												<div className="photo" key={index}>
													<img src={img.url} alt="" />
												</div>
											))}
										</div>
										<div className="info-area">
											<p>{item.description}</p>
										</div>

										<div className="button-area">
											<button className="btn" onClick={() => handleMoveToVideoPage(item.category?.key)}>
												관련 톡톡 피부 영상을 보러 갈까요?
												<i className="icon-arrow icon-arrow-black" />
											</button>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ChangeResultPage;
