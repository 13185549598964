import {IonAvatar, IonBadge, IonItem, IonLabel} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Clinic} from "../../store/types";
import BookmarkButton from "../container/BookmarkButton";
import clinic_default_Img from "../../img/img_default.png";

interface IClnicItemProps {
	clinic: Clinic;
	thumbnail?: boolean;
	showBookmark?: boolean;
	bookmark?: boolean;
	homepage?: boolean;
	notGoToDetailPage?: boolean;
}

const ClinicItem: React.FC<IClnicItemProps> = props => {
	const clinic = props.clinic;
	const history = useHistory();

	const images: string[] = clinic.images?.split(";") ?? [];

	const distanceUnit: string = (clinic.distance ?? 0) > 1000 ? "km" : "m";
	const distanceCalc: number =
		(clinic.distance ?? 0) > 1000 ? (clinic.distance ?? 0) / 1000 : clinic.distance ?? 0;

	let numberFormatOptions = {};
	if (distanceUnit === "km")
		numberFormatOptions = {
			maximumFractionDigits: 1,
			minimumFractionDigits: 1,
		};

	const distance: string = distanceCalc.toLocaleString("ko-kr", numberFormatOptions);

	const clickHandler = () => {
		if (!(props.notGoToDetailPage ?? false)) {
			history.push(`/clinic/${clinic.id}`);
		}
	};
	const [bookmark, setBookmark] = useState(clinic.isFav);

	useEffect(() => {
		setBookmark(clinic.isFav);
	}, [clinic.isFav]);

	return (
		<>
			<IonItem className="ion-padding video-item">
				{props.thumbnail ?? false ? (
					<IonAvatar slot="start" onClick={clickHandler}>
						<img
							src={`${images.length > 0 ? images[0] : ""}`}
							alt=""
							onError={(e: any) => (e.target.src = clinic_default_Img)}
						/>
					</IonAvatar>
				) : (
					<></>
				)}

				<IonLabel className="clinic-item" onClick={clickHandler}>
					{clinic.doctorType === "SPECIALIST" && <IonBadge color="primary">전문의</IonBadge>}
					<h3>{clinic.name}</h3>
					{(clinic?.distance ?? 0) !== 0 ? (
						<p>{`${distance}${distanceUnit} | ${clinic.address}`}</p>
					) : (
						<p>{clinic.address}</p>
					)}

					{clinic.url && (props.homepage ?? false) ? (
						<a style={{fontSize: 12}} target="_blank" href={clinic.url ? clinic.url : ""} rel="noreferrer">
							<span>병원 홈페이지</span>
							<img src="assets/icon/icons_next.png" alt="move" className="pg-find-clinic-detail icon" />
						</a>
					) : (
						""
					)}
				</IonLabel>
				{props.showBookmark ?? false ? (
					<BookmarkButton
						bookmark={bookmark}
						setBookmark={setBookmark}
						item={clinic}
						type={"Clinic"}
					></BookmarkButton>
				) : (
					<></>
				)}
			</IonItem>
		</>
	);
};

export default ClinicItem;
