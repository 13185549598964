/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {IonGrid, IonItem, IonLabel, IonRadio, IonRadioGroup} from "@ionic/react";

interface TypeProps {
	data: any;
	id?: number;
	updateIdData?: any;
	isSelected?: any;
}

const Type4: React.FC<TypeProps & RouteComponentProps> = ({data, id, updateIdData, isSelected}) => {
	const [list, setList] = useState<any>();
	const [selectedItemNum, setSelectedItemNum] = useState<any>([]);
	const [isSelectedItem, setIsSelectedItem] = useState<boolean>(false);

	const handleChangeSelectedItem = (e: any) => {
		setIsSelectedItem(true);
		setSelectedItemNum(e.detail.value);
	};

	useEffect(() => {
		updateIdData([selectedItemNum]);
	}, [selectedItemNum]);

	useEffect(() => {
		setList(data.moonjinLabelPageDto.moonjinLabelDetailList);
		setSelectedItemNum([]);
	}, [data]);

	useEffect(() => {
		setIsSelectedItem(false);
	}, [list]);

	useEffect(() => {
		isSelected(isSelectedItem);
	}, [isSelectedItem]);

	return (
		<div className="question-contents__list--wrap">
			<div className="question-contents__list--title">
				<div className="ion-padding">
					<span className="num">질문{id}</span>
					<p className="category">{data.moonjinLabelPageDto.title}</p>
				</div>
			</div>
			<IonGrid className="question-contents__list card-contents lines-item">
				<div className="card-item">
					{list?.map((item: any, index: any) => (
						<div key={index} className="list-wrap list-photo">
							<IonRadioGroup value={selectedItemNum} onIonChange={handleChangeSelectedItem}>
								<IonItem>
									{item.imageList === null ? (
										<IonLabel>
											<p className="label-none">{item.title}</p>
										</IonLabel>
									) : (
										<IonLabel>
											{item.subNote ? (
												<p className="title">{item.title}</p>
											) : (
												<p className="none-info">{item.title}</p>
											)}
											{item.subNote && <span className="info">{item.subNote}</span>}
										</IonLabel>
									)}
									{item.imageList && (
										<div className="photo-area">
											{item.imageList.map((img: any, index: any) => (
												<img src={img.url} alt="" key={index} />
											))}
										</div>
									)}
									<IonRadio value={item.id} className="photo-radio" />
								</IonItem>
							</IonRadioGroup>
						</div>
					))}
				</div>
			</IonGrid>
		</div>
	);
};

export default withRouter(Type4);
