/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from "react";
import {useIonAlert, useIonToast} from "@ionic/react";
import {useHistory} from "react-router";
import Verification from "../login/Verification";
import {useApi} from "../../hooks/api";

const MyInfoPhone: React.FC = () => {
	const history = useHistory();
	const [showAlert] = useIonAlert();
	const [present] = useIonToast();
	const {processApi} = useApi();

	const onNext = useCallback(async data => {
		try {
			await processApi<any>("update-user", {certToken: data.certToken});
			showAlert({
				cssClass: "alert-css2",
				message: "전화번호 변경이</br>완료되었습니다.",
				buttons: [{text: "확인", cssClass: "primary", handler: d => history.replace("/myinfo")}],
			});
		} catch (e) {
			present("처리 중 문제가 발생되었습니다. 잠시 후 다시 시도해주세요.");
		}
	}, []);

	return (
		<Verification
			action={"reset-info"}
			headerTitle={"휴대폰번호 변경"}
			backHref={"/myinfo"}
			onNext={onNext}
			onError={() => history.replace("/myinfo")}
		/>
	);
};

export default MyInfoPhone;
