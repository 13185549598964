/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useCallback} from "react";
import {
	IonPage,
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonGrid,
	IonCol,
	IonRow,
	IonButton,
	IonLoading,
	useIonAlert,
	useIonToast,
} from "@ionic/react";

import HeaderBase from "../../components/HeaderBase";
import {useHistory} from "react-router";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useApi} from "../../hooks/api";
import {ErrorMessage} from "@hookform/error-message";

interface MyInfoPWProps {
	certToken?: string;
	onNext: () => void;
}

interface FormData {
	currentPassword?: string;
	newPassword?: string;
	newPasswordConfirm?: string;
}

const MyInfoPW: React.FC<MyInfoPWProps> = ({certToken, onNext}) => {
	const history = useHistory();
	const {processApi} = useApi();

	const [showLoading, setShowLoading] = useState(false);

	const [form, setForm] = useState({
		currentPassword: "",
		newPassword: "",
		newPasswordConfirm: "",
		route: "EMAIL", // 임시
	});
	const [showAlert] = useIonAlert();
	const [showToast] = useIonToast();

	const message = "영문, 숫자, 기호를 모두 포함 6자리 이상 입력해주세요.";

	const {
		control,
		handleSubmit,
		watch,
		formState: {errors},
	} = useForm<FormData>({
		defaultValues: form,
	});

	const toast = (message: string) => {
		showToast({
			message: message,
			duration: 600,
		});
	};

	const password: any = useRef();
	password.current = watch("newPassword");

	const goNext = useCallback(() => {
		if (onNext) {
			onNext();
		} else {
			history.replace("/myinfo");
		}
	}, []);

	const onSubmit: SubmitHandler<FormData> = async data => {
		try {
			setShowLoading(true);
			if (certToken) {
				await processApi<any>("update-password-by-token", {
					newPassword: data.newPassword,
					certToken: certToken,
				});
			} else {
				await processApi<any>("update-password", {
					currentPassword: data.currentPassword,
					newPassword: data.newPassword,
				});
			}

			setShowLoading(false);
			showAlert({
				message: "비밀번호 변경이</br>완료되었습니다.",
				buttons: [{text: "확인", cssClass: "primary", handler: () => goNext()}],
				cssClass: "alert-css2",
			});
		} catch (error: any) {
			setShowLoading(false);
			if (error) {
				switch (error) {
					case "no-match-password":
						toast("현재 비밀번호를 확인해주시기 바랍니다.");
						break;
					case "same-password":
						toast("현재 비밀번호와 새로운 비밀번호가 같습니다");
						break;
					default:
						toast("처리 중 오류가 발생하였습니다.");
				}
			} else toast("처리 중 오류가 발생하였습니다.");
		}
	};

	return (
		<IonPage className="pg-mypage-info">
			{!certToken ? (
				<HeaderBase title="비밀번호 변경" backHref="/" />
			) : (
				<HeaderBase title="비밀번호 재설정" backHref="/" />
			)}
			<IonContent fullscreen>
				<div className="content-inner">
					<IonLoading
						isOpen={showLoading}
						message="잠시만 기다려주세요."
						onDidDismiss={() => setShowLoading(false)}
					/>
					<form onSubmit={handleSubmit(onSubmit)} method="post" action="">
						<IonGrid className="ion-padding">
							<IonRow>
								<IonCol>
									<IonList>
										{!certToken && (
											<IonItem>
												<IonLabel position="stacked">현재 비밀번호</IonLabel>
												<Controller
													name="currentPassword"
													control={control}
													rules={{
														required: true,
													}}
													render={({field}) => (
														<IonInput
															{...field}
															maxlength={40}
															type="password"
															onIonChange={field.onChange}
															placeholder={message}
														/>
													)}
												/>
											</IonItem>
										)}
										<IonItem>
											<IonLabel position="stacked">새 비밀번호</IonLabel>
											<Controller
												name="newPassword"
												control={control}
												rules={{
													required: true,
													pattern: {
														value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$/,
														message,
													},
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={40}
														type="password"
														onIonChange={field.onChange}
														placeholder={message}
														className={errors.newPassword === undefined ? "" : "error"}
													/>
												)}
											/>
										</IonItem>
										<ErrorMessage
											errors={errors}
											message={message}
											name="newPassword"
											as={<div className="error-message" />}
										/>
										<IonItem>
											<IonLabel position="stacked">새 비밀번호 확인</IonLabel>
											<Controller
												name="newPasswordConfirm"
												control={control}
												rules={{
													required: true,
													validate: value => value === password.current,
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={40}
														type="password"
														onIonChange={field.onChange}
														placeholder={message}
														className={errors.newPasswordConfirm === undefined ? "" : "error"}
													/>
												)}
											/>
										</IonItem>
										<ErrorMessage
											errors={errors}
											name="newPasswordConfirm"
											message="새 비밀번호가 일치하지 않습니다."
											as={<div className="error-message" />}
										/>
									</IonList>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton className="" expand="full" type="submit" mode="md" shape="round">
										{!certToken ? "비밀번호 변경하기" : "비밀번호 재설정하기"}
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					</form>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default MyInfoPW;
