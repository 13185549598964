/* eslint-disable react-hooks/exhaustive-deps */
import {ErrorMessage} from "@hookform/error-message";
import {
	IonButton,
	IonContent,
	IonLoading,
	IonList,
	IonInput,
	IonItem,
	IonLabel,
	IonPage,
	useIonToast,
	IonGrid,
	IonRow,
	IonCol,
	IonCheckbox,
} from "@ionic/react";
import {useEffect, useState, useCallback} from "react";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {useHistory} from "react-router";
import {useRecoilState} from "recoil";
import localforage from "localforage";
import {useApi} from "../../hooks/api";
import HeaderBase from "../../components/HeaderBase";
import {userState, tokenState} from "../../store/atoms";

interface FormData {
	username?: string;
	password?: string;
}

const EmailLogin: React.FC = () => {
	const [form, setForm] = useState({
		username: "", // test@test.com
		password: "", // qwer1234!
	});
	const [showLoading, setShowLoading] = useState(false);
	const [token, setToken] = useRecoilState(tokenState);
	const [user] = useRecoilState(userState);
	const history = useHistory();
	const [isChecked, setIsChecked] = useState(false);
	const [showToast] = useIonToast();
	const {
		control,
		handleSubmit,
		setValue,
		formState: {errors},
	} = useForm<FormData>({
		defaultValues: form,
	});
	const {processApi} = useApi();

	useEffect(() => {
		(async () => {
			const storedEmail = (await localforage.getItem("email")) as string;
			if (storedEmail) {
				setValue("username", storedEmail);
				setIsChecked(true);
			}
		})();
	}, []);

	useEffect(() => {
		if (user) {
			history.replace("/");
		}
	}, [user]);

	const onSubmit: SubmitHandler<FormData> = data => {
		processLogin(data, isChecked);
	};

	const processLogin = useCallback(async (formData, checked) => {
		try {
			setShowLoading(true);
			const response = await processApi<any>("login", formData);
			setToken({token: response});
			if (checked) {
				localforage.setItem("email", formData.username);
			} else {
				localforage.removeItem("email");
			}
			setShowLoading(false);
		} catch (e) {
			setShowLoading(false);
			showToast({
				/*header: 'Use this lightsaber?',*/
				message: "입력한 이메일과 비밀번호가 일치하지 않습니다.",
				duration: 600,
			});
		}
	}, []);

	const onRememberCheck = (event: any) => {
		setIsChecked(event.detail.checked);
	};

	return (
		<IonPage className="pg-sign-up pg-email-login">
			<HeaderBase title="로그인" backHref="/" />

			<IonContent fullscreen>
				<div className="content-inner">
					<IonGrid className="ion-padding">
						<IonLoading
							isOpen={showLoading}
							message="잠시만 기다려주세요."
							onDidDismiss={() => setShowLoading(false)}
						/>
						<form onSubmit={handleSubmit(onSubmit)} method="post" action="">
							<IonRow>
								<IonCol>
									<IonList>
										<IonItem>
											<IonLabel position="stacked">이메일</IonLabel>

											<Controller
												name="username"
												control={control}
												rules={{
													required: true,
													pattern: {
														value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
														message: "이메일 형식을 확인해주세요.",
													},
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={40}
														onIonChange={field.onChange}
														placeholder="이메일을 입력해주세요."
														className={errors.username === undefined ? "" : "error"}
													/>
												)}
											/>
										</IonItem>
										<ErrorMessage
											errors={errors}
											message="이메일을 입력해주세요."
											name="username"
											as={<div className="error-message" />}
										/>
									</IonList>
									<IonList>
										<IonItem>
											<IonLabel position="stacked">비밀번호</IonLabel>
											<Controller
												name="password"
												control={control}
												rules={{
													required: true,
													pattern: {
														value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$/,
														message: "영문, 숫자, 기호를 모두 포함 6자리 이상 입력해주세요.",
													},
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={40}
														type="password"
														onIonChange={field.onChange}
														placeholder="비밀번호를 입력해주세요."
														className={errors.password === undefined ? "" : "error"}
													/>
												)}
											/>
										</IonItem>
										<ErrorMessage
											errors={errors}
											message="비밀번호를 입력해주세요."
											name="password"
											as={<div className="error-message" />}
										/>
									</IonList>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol className="check">
									<IonItem>
										<IonCheckbox checked={isChecked} onIonChange={onRememberCheck} />
										<IonLabel>아이디 저장</IonLabel>
									</IonItem>
								</IonCol>
							</IonRow>
							<IonRow className="mt-24">
								<IonCol>
									<IonButton className="" expand="full" type="submit" mode="md" shape="round">
										로그인
									</IonButton>
								</IonCol>
							</IonRow>
						</form>
						<IonRow>
							<IonCol className="find">
								<span>
									<span onClick={() => history.push("/find-email")}>이메일 찾기</span>
								</span>
								<em></em>
								<span>
									<span onClick={() => history.push("/find-password")}>비밀번호 찾기</span>
								</span>
							</IonCol>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default EmailLogin;
