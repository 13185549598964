/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {IonPage, IonContent, getPlatforms, useIonAlert, IonGrid, IonItem, IonLabel} from "@ionic/react";
import {useHistory} from "react-router";
import {useRecoilState} from "recoil";
import {
	analysisFaceCenter,
	analysisFaceLeft,
	analysisFacePhotoList,
	analysisFaceRight,
	analysisGuidePopupState,
	analysisPartState,
	analysisState,
	centerFaceCamMode,
	faceLocation,
	leftFaceCamMode,
	rightFaceCamMode,
} from "../../store/atoms";
import {Modal} from "../../components/presentational";
import {partPopupTitle, selectText} from "./config";
import {faceModeUser} from "../configs";
import {useAuth} from "../../hooks/auth";

const SkinAnalysis: React.FC = () => {
	const history = useHistory();
	const [showAlert] = useIonAlert();
	const [showPopupAlert] = useIonAlert();
	const userState = localStorage.getItem("accessToken");

	const [device, setDevice] = useState("");
	const [showPartPopup, setShowPartPopup] = useState<boolean>(false);
	const [isSelected, setIsSelected] = useState<boolean>(false);

	const [centerPhotoState, setCenterPhotoState] = useRecoilState(analysisFaceCenter);
	const [leftPhotoState, setLeftPhotoState] = useRecoilState(analysisFaceLeft);
	const [rightPhotoState, setRightPhotoState] = useRecoilState(analysisFaceRight);

	const [analysisValue, setAnalysisValue] = useRecoilState(analysisState);
	const [analysisPartValue, setAnalysisPartValue] = useRecoilState(analysisPartState);

	const [faceLocationValue, setFaceLocationValue] = useRecoilState(faceLocation);
	const [facePhotoListState, setFacePhotoListState] = useRecoilState(analysisFacePhotoList);

	const [centerFacingMode, setCenterFacingMode] = useRecoilState(centerFaceCamMode);
	const [leftFacingMode, setLeftFacingMode] = useRecoilState(leftFaceCamMode);
	const [rightFacingMode, setRightFacingMode] = useRecoilState(rightFaceCamMode);

	const [showGuidePopup, setShowGuidePopup] = useRecoilState(analysisGuidePopupState);

	const handleShowPartPopup = () => {
		setShowPartPopup(true);
	};

	const handleClickAnalysisValue = (value: any) => {
		localStorage.setItem("analysisValue", value);
		setAnalysisValue(value);
	};

	const handleClosePartPopup = () => {
		showPopupAlert({
			message: "분석하고 싶은 부위를<br />선택하지 않고 닫으시겠어요?",
			buttons: [{text: "닫기", handler: () => setShowPartPopup(false)}, "다시 선택"],
			cssClass: "alert-css2",
		});
	};

	const handleSelectComplete = () => {
		setShowPartPopup(false);
		localStorage.setItem("analysisPartValue", analysisPartValue);

		setTimeout(() => {
			switch (analysisPartValue) {
				case "face":
					setFaceLocationValue("center");
					history.push("/skin-analysis/upload/face");
					break;
				case "body":
					history.push("/skin-analysis/upload/body");
					break;
			}
		}, 500);
	};

	const handleClickSelectType = (type: string) => {
		setIsSelected(true);
		setAnalysisPartValue(type);
	};

	useEffect(() => {
		setFacePhotoListState([]);
		setCenterPhotoState(null);
		setLeftPhotoState(null);
		setRightPhotoState(null);
		setAnalysisValue(null);

		setCenterFacingMode(faceModeUser);
		setLeftFacingMode(faceModeUser);
		setRightFacingMode(faceModeUser);

		setShowGuidePopup(true);
	}, []);

	useEffect(() => {
		getPlatforms().map(item => {
			setDevice(item);
		});
	}, []);

	useEffect(() => {
		if (device === "desktop") {
			showAlert({
				message: "모바일에서 이용 가능한 서비스입니다.",
				buttons: [
					{
						text: "확인",
						cssClass: "primary",
						handler: d => history.push("/t/home"),
					},
				],
				cssClass: "alert-css2",
			});
		}
	}, [device]);

	useEffect(() => {
		if (userState) return;

		showAlert({
			message: "로그인이 필요한 서비스입니다.</br>SDOC 회원이라면 로그인해주세요.",
			buttons: [
				{
					text: "취소",
					handler: () => history.push("/t/home"),
				},
				{text: "로그인", cssClass: "primary", handler: d => history.push("/login")},
			],
			cssClass: "alert-css2",
		});
	}, []);

	return (
		<IonPage>
			<IonContent fullscreen>
				<div className="content-inner analysis-guide">
					<div className="analysis-guide__header">
						<h2>내 피부 분석</h2>
					</div>
					<IonGrid className="ion-padding">
						<div className="analysis-guide__title">
							<h3>사진 찍고 문진 쓰면 끝!</h3>
							<h2>
								스닥에서
								<br />
								쉽게 피부 분석<span>하세요.</span>
							</h2>
						</div>
						<div className="analysis-guide__category">
							<div className="category">
								<IonItem
									button
									onClick={() => {
										handleShowPartPopup();
										handleClickAnalysisValue("detail");
									}}
									className={showPartPopup && analysisValue === "detail" ? "active" : ""}
								>
									<IonLabel>
										<h3>정밀 분석</h3>
										<p>증상 부위를 정확하게 심층 분석해 드려요!</p>
									</IonLabel>
								</IonItem>
							</div>
							<div className="category">
								<IonItem
									button
									onClick={() => {
										handleShowPartPopup();
										handleClickAnalysisValue("simple");
									}}
									className={showPartPopup && analysisValue === "simple" ? "active" : ""}
								>
									<IonLabel>
										<h3>빠른 분석</h3>
										<p>증상 부위를 빠르고 간단하게 분석해 드려요!</p>
									</IonLabel>
								</IonItem>
							</div>
						</div>
						<div className="analysis-guide__subtitle">
							<span>※ 회원님의 모든 분석 데이터는 익명화 처리되어 안전하게 보관됩니다.</span>
						</div>
					</IonGrid>
				</div>
			</IonContent>

			<Modal
				isOpen={showPartPopup}
				isClose={() => setShowPartPopup(false)}
				isCloseComplete={handleClosePartPopup}
				closeMode
				info
				subTitle={partPopupTitle}
				select
				selectBtnText={selectText}
				handleClickFace={() => handleClickSelectType("face")}
				handleClickBody={() => handleClickSelectType("body")}
				selectComplete={handleSelectComplete}
				btnClassName={isSelected ? "checked" : ""}
				className="analysis-popup"
			/>
		</IonPage>
	);
};

export default SkinAnalysis;
