import {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import {IonToggle} from "@ionic/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ChabgeThumbnailSliderProps {
	slideImg?: any;
	text?: any;
	checked?: boolean;
	onChangeToggle?: any;
}
const ChabgeThumbnailSlider: React.FC<ChabgeThumbnailSliderProps> = ({slideImg, text, onChangeToggle}) => {
	const mainSlickRef = useRef(null);
	const pagingSlickRef = useRef(null);

	const [mainSlick, setMainSlick] = useState<any>();
	const [pagingSlick, setPagingSlick] = useState<any>();

	const mainSettings = {
		initialSlide: -1,
		dots: false,
		arrows: true,
		infinite: false,
		slidesToShow: 1,
	};

	const pagingSettings = {
		dots: false,
		arrows: false,
		slidesToShow: 3,
		infinite: false,
		focusOnSelect: true,
	};

	useEffect(() => {
		setMainSlick(mainSlickRef.current);
		setPagingSlick(pagingSlickRef.current);
	}, []);

	return (
		<div className="thumbnail-slider change-slider">
			<div className="main-slider">
				<Slider ref={mainSlickRef} asNavFor={pagingSlick} {...mainSettings}>
					{slideImg.map((item: any, index: any) => (
						<div key={index} className="img-area">
							{item.checked ? (
								<img src={item.afterImageUrl} alt={index} />
							) : (
								<img src={item.beforeImageUrl} alt={index} />
							)}

							{item.index !== 0 && (
								<>
									<div className="toggle">
										<IonToggle
											defaultChecked={true}
											checked={item.checked}
											onIonChange={e => onChangeToggle(item.index, e.detail.checked)}
										/>
									</div>
									<p>
										{item.date}의 예상 질환
										<br />
										{item.diseaseClazz?.map((disease: any, index: any) => (
											<span key={index}>
												{`${index === 1 ? ". " : ""}`}
												{disease.text}
											</span>
										))}
									</p>
								</>
							)}
						</div>
					))}
				</Slider>
			</div>

			<div className="pagination-slider">
				<Slider ref={pagingSlickRef} asNavFor={mainSlick} {...pagingSettings}>
					{slideImg.map((item: any, index: any) => (
						<div key={index}>
							{index === 0 ? (
								<h2 className="slick-slide-title">{text}</h2>
							) : (
								<h2 className="slick-slide-title">{item.date}</h2>
							)}
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default ChabgeThumbnailSlider;
