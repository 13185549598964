/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback} from "react";
import {
	IonPage,
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonGrid,
	IonCol,
	IonRow,
} from "@ionic/react";

import HeaderBase from "../../components/HeaderBase";
import {useRecoilState, useSetRecoilState} from "recoil";
import {userDetailState} from "../../store/atoms";
import {useApi} from "../../hooks/api";
import {useAuth} from "../../hooks/auth";
import {useHistory} from "react-router-dom";

const MyInfo: React.FC = () => {
	const history = useHistory();
	const {user} = useAuth();
	const {processApi} = useApi();
	const [isEmailAccount, setIsEmailAccount] = useState(false);
	const [userDetail] = useRecoilState(userDetailState);
	const setUserDetail = useSetRecoilState(userDetailState);

	const fetchData = useCallback(async () => {
		try {
			const userData = await processApi<any>("user");
			setUserDetail(data => {
				return {...data, ...userData};
			});
		} catch (error) {
			console.error(error);
		}
	}, []);

	useEffect(() => {
		if (user) {
			fetchData();
			setIsEmailAccount(user.route === "email");
		}
	}, [user]);

	const genderFormat = (gender: string) => {
		switch (gender) {
			case "MALE":
				return "남";
			case "FEMALE":
				return "여";
			default:
				return "";
		}
	};

	return (
		<IonPage className="pg-mypage-info">
			<HeaderBase title="내 정보 수정" backHref="/" />
			<IonContent fullscreen>
				<div className="content-inner">
					<IonGrid>
						{user && user.route !== "email" && (
							<IonRow>
								<IonCol>
									<div className="title-sub ion-padding">{user.route} 로그인 회원</div>
								</IonCol>
							</IonRow>
						)}
					</IonGrid>
					<IonGrid className="ion-padding">
						<IonRow>
							<IonCol>
								<IonList>
									{user && user.route === "email" && (
										<>
											<IonItem>
												<IonLabel position="stacked">이메일</IonLabel>
												<IonInput
													value={userDetail?.username || ""}
													readonly={true}
													type="email"
													color="medium"
												></IonInput>
											</IonItem>
											<IonItem>
												<IonLabel position="stacked">비밀번호</IonLabel>
												<IonInput
													type={isEmailAccount ? "password" : "text"}
													// value={
													// 	isEmailAccount ? userDetail?.password || "" : "가입한 SNS계정에서 변경할 수 있습니다."
													// }
													value={"0000000000"}
													color={isEmailAccount ? "dark" : "medium"}
													readonly={true}
												></IonInput>
												{isEmailAccount && (
													<span className="change" onClick={() => history.push("/myinfo-pw")}>
														변경
													</span>
												)}
											</IonItem>
										</>
									)}
									<IonItem>
										<IonLabel position="stacked">이름</IonLabel>
										<IonInput value={userDetail?.realName || ""} readonly={true} color="medium"></IonInput>
									</IonItem>
									<IonItem>
										<IonLabel position="stacked">휴대폰번호</IonLabel>
										<IonInput value={userDetail?.phoneNo || ""} readonly={true}></IonInput>
										<span className="change" onClick={() => history.push("/myinfo-phone")}>
											변경
										</span>
									</IonItem>
									<IonItem>
										<IonLabel position="stacked">생년월일</IonLabel>
										<IonInput value={userDetail?.birthDay || ""} readonly={true} color="medium"></IonInput>
									</IonItem>
									<IonItem>
										<IonLabel position="stacked">성별</IonLabel>
										<IonInput
											value={genderFormat(userDetail?.gender || "")}
											readonly={true}
											color="medium"
										></IonInput>
									</IonItem>
									<IonItem>
										<IonLabel position="stacked">닉네임</IonLabel>
										<IonInput value={userDetail?.nick || ""} readonly={true}></IonInput>
										<span className="change" onClick={() => history.push("/myinfo-nicknm")}>
											변경
										</span>
									</IonItem>
								</IonList>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<div className="close">
									<label>회원 탈퇴</label>
									<span className="account" onClick={() => history.push("/account-close")}>
										탈퇴 신청
									</span>
								</div>
							</IonCol>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default MyInfo;
