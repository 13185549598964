import {useCallback, useEffect, useRef, useState} from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ThumbnailSlider.scss";

import {faceLocation, analysisFaceCenter, analysisFaceLeft, analysisFaceRight} from "../../../store/atoms";

interface ThumbnailSliderProps {
	slideImg?: any;
}
const ThumbnailSlider: React.FC<ThumbnailSliderProps> = ({slideImg}) => {
	const mainSlickRef = useRef(null);
	const pagingSlickRef = useRef(null);

	const [mainSlick, setMainSlick] = useState<any>();
	const [pagingSlick, setPagingSlick] = useState<any>();
	const [tag, setTag] = useState<any>("center");

	const setFaceLocationValue = useSetRecoilState(faceLocation);
	const [centerPhotoState] = useRecoilState(analysisFaceCenter);
	const [leftPhotoState] = useRecoilState(analysisFaceLeft);
	const [rightPhotoState] = useRecoilState(analysisFaceRight);

	const mainSettings = {
		dots: false,
		arrows: true,
		infinite: false,
		slidesToShow: 1,
		beforeChange: (oldIndex: any, newIndex: any) => {
			switch (newIndex) {
				case 0:
					setTag("center");
					setFaceLocationValue("center");
					break;
				case 1:
					setTag("left");
					setFaceLocationValue("left");
					break;
				case 2:
					setTag("right");
					setFaceLocationValue("right");
					break;
			}
		},
	};

	const pagingSettings = {
		dots: false,
		arrows: true,
		slidesToShow: 3,
		infinite: false,
		focusOnSelect: true,
	};

	const convTag = (tag: any) => {
		switch (tag) {
			case "center":
				return "정면";
			case "left":
				return "좌측";
			case "right":
				return "우측";
		}
	};

	const handleClass = useCallback(
		(id: any, tag: any): any => {
			if (tag === "center" && centerPhotoState?.isMirrored === false && id === 0) {
				return "reverse";
			} else if (tag === "left" && leftPhotoState?.isMirrored === false && id === 1) {
				return "reverse";
			} else if (tag === "right" && rightPhotoState?.isMirrored === false && id === 2) {
				return "reverse";
			} else {
				return "";
			}
		},
		[centerPhotoState, leftPhotoState, rightPhotoState],
	);

	useEffect(() => {
		setMainSlick(mainSlickRef.current);
		setPagingSlick(pagingSlickRef.current);

		setTag("center");
		setFaceLocationValue("center");
	}, []);

	return (
		<div className="thumbnail-slider analysis-slider">
			<div className="main-slider">
				<Slider ref={mainSlickRef} asNavFor={pagingSlick} {...mainSettings}>
					{slideImg.map((item: any, index: any) => (
						<div key={index}>
							<h2 className="slick-slide-title" id="title">
								{convTag(tag)}
							</h2>
							<img src={item.contentBase64} alt={item.tag} className={handleClass(index, item.tag)} />
						</div>
					))}
				</Slider>
			</div>

			<div className="pagination-slider">
				<Slider ref={pagingSlickRef} asNavFor={mainSlick} {...pagingSettings}>
					{slideImg.map((item: any, index: any) => (
						<div key={index}>
							<img src={item.contentBase64} alt={item.tag} className={handleClass(index, item.tag)} />
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
};

export default ThumbnailSlider;
