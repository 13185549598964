import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {easeCircleInOut} from "d3-ease";

import AnimatedProgressProvider from "./AnimatedProgressProvider";
import "./ProgressBar.scss";

interface ProgressProps {
	title?: string;
	subTitle?: string;
	info?: string;
	adMode?: boolean;
	adSlideList?: any;
	progressComplete?: boolean;
	handleClickResult?: any;
	duration?: any;
	percentage?: any;
}

const Progress: React.FC<ProgressProps> = ({
	title,
	subTitle,
	info,
	duration,
	percentage,
	adMode,
	progressComplete,
	handleClickResult,
}) => {
	return (
		<div className={`${adMode ? "ad-progress" : "dimmed"}`}>
			<div className="progress-wrap">
				<div className="progress-inner">
					{title && (
						<div className="title-area">
							<p className={`${progressComplete ? "title ad-title" : "title"}`}>{`${title} ${
								progressComplete ? "님의 피부 분석이 완료되었습니다." : "님의 피부를 분석 중입니다."
							}`}</p>
							{progressComplete ? <></> : <p className="sub-title">{subTitle}</p>}
						</div>
					)}
					<div className="progress">
						<CircularProgressbar
							value={percentage}
							text={`${adMode && progressComplete ? "" : percentage + "%"}`}
							className={`${adMode && progressComplete ? "icon-complete" : ""}`}
						/>
						{/* <AnimatedProgressProvider
							valueStart={0}
							valueEnd={100}
							duration={duration}
							easingFunction={easeCircleInOut}
						>
							{value => {
								const roundedValue = Math.round(value);
								return (
									<>
										<CircularProgressbar
											value={value}
											text={`${adMode && progressComplete ? "" : roundedValue}`}
											styles={buildStyles({pathTransition: "none"})}
										/>
										{adMode && progressComplete && <i className="icon-complete" />}
									</>
								);
							}}
						</AnimatedProgressProvider> */}
					</div>
					{info && progressComplete ? (
						<div className="info-area ad-info">
							<div className="bottom-contents">
								<div className="btn-bottom">
									<button className="button" onClick={handleClickResult}>
										내 피부 분석 결과 보기
									</button>
								</div>
							</div>
						</div>
					) : (
						<div className="info-area">
							<p className="info">{info}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Progress;
