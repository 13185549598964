/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {useHistory, useParams, withRouter} from "react-router";
import {IonButton, IonFooter} from "@ionic/react";
import {useApi} from "../../../../hooks/api";
import {SwitchCase} from "../../../../components";
import {Type1, Type2, Type3, Type4, Type5, Type6, Type997} from "./list";

const SimpleTypePage: React.FC = () => {
	const params: any = useParams();
	const history = useHistory();
	const {analysisProcessApi} = useApi();
	const keyValue = localStorage.getItem("analysisKey");

	const [id, setId] = useState<number>(1);
	const [pageId, setPageId] = useState<any>(params.pageId);
	const [nodeId, setNodeId] = useState<any>("0");
	const [type, setType] = useState<string>();

	const [data, setData] = useState<any>();
	const [updateIdData, setUpdateIdData] = useState<any>();
	const [isLastPage, setIsLastPage] = useState<boolean>(false);
	const [isNextSelected, setIsNextSelected] = useState<boolean>(false);
	const [isPrevSelected, setIsPrevSelected] = useState<boolean>(false);

	const getData = async (nodeNum: any, pageNum: any) => {
		try {
			const {data} = await analysisProcessApi<any>("simple-item", {
				id: nodeNum,
				analysisKey: keyValue,
				pageId: pageNum,
			});

			setData(data);
			setType(data.moonjinLabelPageDto.type);
			setNodeId(String(data.nodeId));
			setPageId(data.moonjinLabelPageDto.id);
		} catch (error) {
			console.log(error);
		}
	};

	const updateData = async () => {
		if (updateIdData && isNextSelected) {
			try {
				const result = await analysisProcessApi<any>("simple-item-put", {
					id: nodeId,
					pageId: "?pageId=" + pageId,
					analysisKey: keyValue,
					detailIdList: updateIdData,
				});

				if (result && result.data.isLeaves === false) {
					setId(id + 1);

					getData(String(result.data.nextNodeId), result.data.nextPageId);
				}

				if (result.data.isLeaves === true) {
					setIsLastPage(true);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const handleClickNextPage = () => {
		updateData();

		if (nodeId && pageId) {
			history.push(`/skin-analysis/question/simple/list/${nodeId}/${pageId}`);
		}
	};

	const handleClickPrevPage = () => {
		setId(id - 1);
		setIsLastPage(false);
		setIsPrevSelected(true);

		history.goBack();
		getData(params.nodeId, params.pageId);
	};

	const handleMoveToResultPage = () => {
		history.push("/skin-analysis/question/result");
	};

	const renderPage = () => {
		return (
			<>
				{type === "1" && (
					<Type1 id={id} data={data} updateIdData={setUpdateIdData} isSelected={setIsNextSelected} />
				)}
				{type === "2" && (
					<Type2 id={id} data={data} updateIdData={setUpdateIdData} isSelected={setIsNextSelected} />
				)}
				{type === "3" && (
					<Type3 id={id} data={data} updateIdData={setUpdateIdData} isSelected={setIsNextSelected} />
				)}
				{type === "4" && (
					<Type4 id={id} data={data} updateIdData={setUpdateIdData} isSelected={setIsNextSelected} />
				)}
				{type === "5" && (
					<Type5 id={id} data={data} updateIdData={setUpdateIdData} isSelected={setIsNextSelected} />
				)}
				{type === "6" && (
					<Type6 id={id} data={data} updateIdData={setUpdateIdData} isSelected={setIsNextSelected} />
				)}
				{type === "997" && (
					<Type997 id={id} data={data} updateIdData={setUpdateIdData} isSelected={setIsNextSelected} />
				)}
			</>
		);
	};

	useEffect(() => {
		if (isLastPage === false) {
			getData(nodeId, pageId);
		}
	}, []);

	useEffect(() => {
		setUpdateIdData(updateIdData);
	}, [updateIdData]);

	useEffect(() => {
		setIsNextSelected(isNextSelected);
	}, [isNextSelected]);

	return (
		<>
			{renderPage()}

			<IonFooter className="floating-menu">
				<div className={`btn-wrap ${nodeId === "0" ? "align-right" : ""}`}>
					{nodeId !== "0" && (
						<IonButton onClick={handleClickPrevPage} className="btn btn-prev">
							<i className="icon-prev" />
						</IonButton>
					)}
					<SwitchCase
						tests={[
							{
								test: isLastPage,
								component: (
									<IonButton onClick={handleMoveToResultPage} className={`btn btn-complete ${isNextSelected ? "selected" : ""}`}>
										완료
									</IonButton>
								),
							},
							{
								test: !isLastPage,
								component: (
									<IonButton
										className={`btn btn-next${isNextSelected ? " btn-next-active" : ""}`}
										onClick={handleClickNextPage}
									>
										<i className="icon-next" />
									</IonButton>
								),
							},
						]}
					/>
				</div>
			</IonFooter>
		</>
	);
};

export default withRouter(SimpleTypePage);
