export const partPopupTitle = `분석하고 싶은 부위를 선택해 주세요.`;

export const faceTitle = "얼굴";

export const faceInfo = "정면, 좌측, 우측 사진을 촬영";

export const bodyTitle = "신체";

export const bodyInfo = "얼굴을 제외한 나머지 신체 부분 사진을 촬영";

export const selectText = "선택 완료";
