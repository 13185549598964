/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback, useState} from "react";
import {IonPage, IonList, IonItem, IonLabel, IonContent} from "@ionic/react";
import {useRecoilState} from "recoil";
import {termsListState} from "../../store/atoms";
import HeaderBase from "../../components/HeaderBase";
import {useApi} from "../../hooks/api";
import {useHistory} from "react-router";

const ViewTerms: React.FC = () => {
	const {processApi} = useApi();

	const [list, setList] = useRecoilState(termsListState);

	const fetchList = useCallback(async () => {
		const list = await processApi<any>("terms");
		setList(list);
	}, []);

	useEffect(() => {
		fetchList();
	}, []);

	return (
		<IonPage className="pg-view-terms">
			<HeaderBase title="약관보기" backHref="/"></HeaderBase>
			<IonContent fullscreen>
				<div className="content-inner">
					<IonList>
						{list.map((x, index) => (
							<IonItem
								key={`item${index}`}
								routerLink={`/mypage/terms/${x.id}`}
								detail={false}
								// onClick={() => {
								// 	history.push(`/mypage/terms/${x.id}`);
								// }}
							>
								<IonLabel className="ion-padding">{`${x.title}`}</IonLabel>
								<img src="assets/icon/icons_next.png" className="icon" alt="move" />
							</IonItem>
						))}
					</IonList>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ViewTerms;
