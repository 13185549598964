/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {IonCheckbox, IonGrid, IonItem, IonLabel} from "@ionic/react";
import {RouteComponentProps, withRouter} from "react-router";

interface TypeProps {
	data?: any;
	id?: number;
	updateIdData?: any;
	isSelected?: any;
}

const Type3: React.FC<TypeProps & RouteComponentProps> = ({data, id, updateIdData, isSelected}) => {
	const [list, setList] = useState<any>();
	const [selectedItemNum, setSelectedItemNum] = useState<any>([]);
	const [isSelectedItem, setIsSelectedItem] = useState<boolean>(false);

	const handleSelectedItem = (e: any, index: number) => {
		const selectedItems = [...list];
		const selectedItemNums = [...selectedItemNum];
		const value = e.detail.value;

		if (e.detail.checked) {
			selectedItems[index].checked = true;

			if (value === "해당 없음") {
				selectedItems.find(item => {
					if (item.title !== "해당 없음") {
						item.checked = false;
					} else {
						selectedItems[selectedItems.length - 1].checked = true;
						setSelectedItemNum([item.id]);
					}
				});
			} else {
				selectedItemNums.push(selectedItems[index].id);
				setSelectedItemNum(selectedItemNums);

				selectedItems.map(item => {
					if (item.title === "해당 없음") {
						item.checked = false;
						selectedItems[selectedItems.length - 1].checked = false;
					}
				});
			}

			setList(selectedItems);
		} else {
			[...list][index].checked = false;

			const selectedItemNums = [...selectedItemNum].filter(v => v !== selectedItems[index].id);
			setSelectedItemNum(selectedItemNums);
		}

		if (selectedItems) {
			const selectedItemsState = selectedItems.map(item => {
				if (item.checked === true) {
					return true;
				} else {
					return false;
				}
			});

			if (selectedItemsState.includes(true)) {
				setIsSelectedItem(true);
			} else {
				setIsSelectedItem(false);
			}
		}

		setList(selectedItems);
	};

	useEffect(() => {
		updateIdData(selectedItemNum);
	}, [selectedItemNum]);

	useEffect(() => {
		isSelected(isSelectedItem);
	}, [isSelectedItem]);

	useEffect(() => {
		setList(data.moonjinLabelPageDto.moonjinLabelDetailList);
		setSelectedItemNum([]);
	}, [data]);

	return (
		<div className="question-contents__list--wrap">
			<div className="question-contents__list--title">
				<div className="ion-padding">
					<span className="num">질문{id}</span>
					<p className="category">{data.moonjinLabelPageDto.title}</p>
					<span className="description">(중복 선택 가능)</span>
				</div>
			</div>
			<IonGrid className="question-contents__list list">
				<div className="question-contents__list--item">
					{list?.map((item: any, index: any) => (
						<div key={index} className="list-wrap multiple-check">
							<IonItem className="lines-item">
								<IonCheckbox
									value={item.title}
									onIonChange={e => handleSelectedItem(e, index)}
									checked={item.checked}
								/>
								<IonLabel>
									{item.title.split("\n").map((text: any, index: any) => (
										<p key={index}>
											{text}
											<br />
											{item.subNote}
										</p>
									))}
								</IonLabel>
							</IonItem>
						</div>
					))}
				</div>
			</IonGrid>
		</div>
	);
};

export default withRouter(Type3);
