import React, {useEffect, useState, useRef} from "react";
import {
	IonPage,
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonGrid,
	IonCol,
	IonRow,
	IonButton,
	IonCheckbox,
	IonSlides,
	IonSlide,
	InputChangeEventDetail,
	CheckboxChangeEventDetail,
	useIonToast,
} from "@ionic/react";

import HeaderBase from "../../components/HeaderBase";
import {useRecoilState} from "recoil";
import {answeredSurveyState} from "../../store/atoms";
import {AnsweredSurvey} from "../../store/types";

const surveyJson: AnsweredSurvey[] = [
	{
		id: 1,
		question: "회원님이 겪고 있으시거나 관심있는 피부 증상을 선택해 주세요!",
		answer: [
			{id: 1, text: "접촉성 피부염"},
			{id: 2, text: "손발톱백선"},
			{id: 3, text: "백반증"},
			{id: 4, text: "바이러스사마귀"},
			{id: 5, text: "아토피 피부염"},
			{id: 6, text: "지루피부염"},
			{id: 7, text: "건선"},
			{id: 8, text: "여드름"},
			{id: 9, text: "대상포진"},
			{id: 10, text: "두드러기"},
			{id: 11, text: "기미"},
			{id: 12, text: "탈모"},
			{id: 13, text: "없음"},
		],
	},
	{
		id: 2,
		question: "저희앱에서 회원님이 주로 이용하실 것 같은 서비스를 알려주세요!",
		answer: [
			{id: 1, text: "피부질환 분석 서비스"},
			{id: 2, text: "피부 상태 기록 서비스"},
			{id: 3, text: "피부 정보 컨텐츠 서비스"},
			{id: 4, text: "내 주변 병원 찾기"},
			{id: 5, text: "없음"},
		],
	},
];

interface SurveyProps {
	onPrev: (data: any) => void;
	onNext: (data: any) => void;
}

const Survey: React.FC<SurveyProps> = ({onPrev, onNext}) => {
	const [surveys, setSurveys] = useState<AnsweredSurvey[]>([]);
	const [surveyCustomAnswers, setSurveyCustomAnswers] = useState<any>();
	const [surveyPage, setSurveyPage] = useState(0);
	const [answeredSurvey, setAnsweredSurvey] = useRecoilState(answeredSurveyState);
	const [showToast] = useIonToast();

	const slideOpts = {
		allowTouchMove: false,
	};

	const slidesRef: any = useRef();

	useEffect(() => {
		setSurveys(
			surveyJson.map(survey => ({
				...survey,
				answer: survey.answer.map(i => ({...i, checked: false})),
			})),
		);
	}, []);

	const onCustomAnswerChange = (event: CustomEvent<InputChangeEventDetail>) => {
		setSurveyCustomAnswers(event.detail.value);
		if (event.detail.value) {
			const survey = [...surveys];
			survey[surveyPage].answer.forEach(item => {
				if (item.text === "없음") {
					item.checked = false;
				}
			});
			setSurveys(survey);
		}
	};

	const onCheckChange = (event: CustomEvent<CheckboxChangeEventDetail>, index: number, text: string) => {
		const survey = [...surveys];
		if (event.detail.checked) {
			//체크 해제가 아닌 체크를 한 경우
			if (text === "없음") {
				//없음을 체크 한 경우
				survey[surveyPage].answer.forEach(item => {
					if (item.text !== "없음") item.checked = false; //없음을 제외 한 항목 체크해제
				});
				setSurveyCustomAnswers("");
			} else if (text !== "없음") {
				//없음이 아닌 값을 체크한 경우
				survey[surveyPage].answer.forEach(item => {
					if (item.text === "없음") item.checked = false; //없음을 체크해제
				});
			}
		}
		survey[surveyPage].answer = [
			...survey[surveyPage].answer.slice(0, index),
			{
				...survey[surveyPage].answer[index],
				checked: event.detail.checked,
			},
			...survey[surveyPage].answer.slice(index + 1),
		];
		setSurveys(survey);
	};
	const toast = () => {
		showToast({
			/*header: 'Use this lightsaber?',*/
			message: "항목을 한 개 이상 선택해주세요.",
			duration: 600,
		});
		return;
	};
	const onSlideNextClick = async () => {
		document.querySelector("ion-content")!.scrollToTop(500);

		const answer = [...surveys[surveyPage].answer.filter(answer => answer.checked)];
		if (answer.length === 0 && !surveyCustomAnswers) return toast();
		setAnsweredSurvey([
			...answeredSurvey,
			{
				id: answeredSurvey.length + 1,
				question: surveyJson[surveyPage].question,
				answer,
				customAnswer: surveyCustomAnswers,
			},
		]);
		const nextSlidePage = surveyPage + 1;
		setSurveyPage(nextSlidePage);
		setSurveyCustomAnswers("");
		await slidesRef.current.slideTo(nextSlidePage);
	};

	const onSubmitSurvey = () => {
		const answer = [...surveys[surveyPage].answer.filter(answer => answer.checked)];
		if (answer.length === 0 && !surveyCustomAnswers) return toast();
		const surveyTemp = [
			...answeredSurvey,
			{
				id: answeredSurvey.length + 1,
				question: surveyJson[surveyPage].question,
				answer,
				customAnswer: surveyCustomAnswers,
			},
		];
		setAnsweredSurvey(surveyTemp);
		onNext({exam: JSON.stringify(surveyTemp)});
	};

	return (
		<IonPage className="pg-survey">
			<HeaderBase title="내 피부 관련 문진" backHref="/" onBackButton={onPrev} />
			<IonContent fullscreen scrollEvents={true}>
				<div className="content-inner">
					<IonGrid>
						<IonRow>
							<IonCol>
								<div className="title-sub ion-padding">
									회원님의 맞춤형 최신 정보 및 서비스 제공에 도움이 됩니다.
								</div>
							</IonCol>
						</IonRow>

						<IonSlides ref={slidesRef} options={slideOpts}>
							{surveys.map((survey, surveyIndex) => (
								<IonSlide key={`survey-slide-${surveyIndex}`}>
									<IonGrid className="ion-padding">
										<IonRow>
											<IonCol>
												<h2>
													<span>질문 {survey.id}</span>
												</h2>
												<IonLabel>
													<h1>
														{survey.question}
														<span>(중복 선택 가능)</span>
													</h1>
												</IonLabel>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<IonList>
													{survey.answer.map((item, index) => (
														<IonItem key={index}>
															<IonCheckbox
																slot="start"
																color="primary"
																checked={item.checked}
																onIonChange={event => onCheckChange(event, index, item.text)}
															/>
															<IonLabel className="label">{item.text}</IonLabel>
														</IonItem>
													))}
												</IonList>
											</IonCol>
										</IonRow>

										<IonRow>
											<IonCol>
												<IonItem>
													<IonInput
														value={surveyCustomAnswers}
														onIonChange={event => onCustomAnswerChange(event)}
														placeholder="기타(직접입력)"
													></IonInput>
												</IonItem>
											</IonCol>
										</IonRow>

										<IonRow className="row-btn">
											<IonCol>
												{surveyJson.length > surveyPage + 1 ? (
													<IonButton
														className=""
														expand="full"
														mode="md"
														shape="round"
														onClick={onSlideNextClick}
													>
														다음
													</IonButton>
												) : (
													<IonButton
														className=""
														expand="full"
														mode="md"
														shape="round"
														onClick={onSubmitSurvey}
													>
														확인
													</IonButton>
												)}
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonSlide>
							))}
						</IonSlides>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Survey;
