import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from "../store/atoms";

export const useAuth = () => {
  const [user] = useRecoilState(userState);
  const latestUser = useRef<any>();

  //console.log('useAuth', user)
  useEffect(() => {
    latestUser.current = user;
    //console.log('latestUser', latestUser.current)
  }, [user]);

  //return { isLoggedIn: !!latestUser.current, user: latestUser.current };
  return { isLoggedIn: !!user, user: user };
}