import React from "react";
import {useHistory, withRouter} from "react-router";
import {IonContent, IonGrid, IonPage} from "@ionic/react";
import {HeaderBase, SwitchCase} from "../../../components";
import DetailTypePage from "./detail/DetailTypePage";
import SimpleTypePage from "./simple/SimpleTypePage";

const QuestionDetailPage: React.FC = () => {
	const history = useHistory();
	const analysisValue = localStorage.getItem("analysisValue");
	
	return (
		<IonPage>
			<HeaderBase title="" cancelMode cancel={() => history.push("/skin-analysis/question")} />
			<IonContent>
				<div className="content-inner">
					<IonGrid>
						<SwitchCase
							tests={[
								{
									test: analysisValue === "detail",
									component: <DetailTypePage />,
								},
								{
									test: analysisValue === "simple",
									component: <SimpleTypePage />,
								},
							]}
						/>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default withRouter(QuestionDetailPage);
