import {IonItem} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {SkinInfo} from "../store/types";
import default_Img from "../img/example.png";
import {BookmarkButton} from "./container";

interface SkinInfoItemProps {
	info: SkinInfo;
	bookmark?: boolean;
}

export const onErrorImg = (e: any) => {
	e.target.src = default_Img;
};

const SkinInfoItem: React.FC<SkinInfoItemProps> = props => {
	const info = props.info;
	const history = useHistory();

	const [bookmark, setBookmark] = useState(info.isFav);

	useEffect(() => {
		setBookmark(info.isFav);
	}, [info.isFav]);

	return (
		<IonItem className="info-item">
			<div className="img">
				<img
					className="clinic"
					src={`${info.thumbnail}`}
					slot="start"
					onClick={() => history.push(`/info/${info.id}`)}
					onError={onErrorImg}
					alt="info"
				/>
			</div>

			{info.isAd ? (
				<div className="bookmark-area ad">
					<i className="icon-ad" />
				</div>
			) : (
				<div className="bookmark-area label-icon">
					<BookmarkButton
						bookmark={bookmark}
						setBookmark={setBookmark}
						type={"Info"}
						item={info}
						className={`${bookmark ? "list-bookmark-active" : "list-bookmark"}`}
					/>
				</div>
			)}
		</IonItem>
	);
};

export default SkinInfoItem;
