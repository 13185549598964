import React from "react";
import {useIonAlert, useIonToast} from "@ionic/react";
import {useApi} from "../../hooks/api";
import {useAuth} from "../../hooks/auth";
import {useHistory} from "react-router";
import {useRecoilState} from "recoil";

import {clinicAroundListState, clinicListState, selectedClinicState} from "../../store/atoms";
import {SwitchCase} from "..";

interface IBookmarkButtonProps {
	type: "Clinic" | "Video" | "Info";
	item?: any;
	bookmark?: any;
	setBookmark?: any;
	bookmarkInfo?: any;
	className?: string;
}

const BookmarkButton: React.FC<IBookmarkButtonProps> = props => {
	const {processApi} = useApi();
	const history = useHistory();
	const [showAlert] = useIonAlert();
	const [showToast] = useIonToast();
	const {isLoggedIn, user} = useAuth();

	const [clinicAroundList, setClinicAroundList] = useRecoilState(clinicAroundListState);
	const [clinicList, setClinicList] = useRecoilState(clinicListState);
	const [selectedClinic, setSelectedClinic] = useRecoilState(selectedClinicState);

	function syncBookmark(id: number, value: boolean) {
		console.log("syncBookmark", id, value);
		let _clinicList = JSON.parse(JSON.stringify(clinicList));
		let index = _clinicList.findIndex((clinic: any) => clinic.id === id);
		if (index !== -1) {
			console.log(_clinicList[index]);
			_clinicList[index].isFav = value;
			setClinicList(_clinicList);
		}

		let _clinicAroundList = JSON.parse(JSON.stringify(clinicAroundList));
		index = _clinicAroundList.findIndex((clinic: any) => clinic.id === id);
		if (index !== -1) {
			_clinicAroundList[index].isFav = value;
			setClinicAroundList(_clinicAroundList);
		}

		console.log("selectedClinic", selectedClinic);
		if (id === selectedClinic?.id) {
			let _clinic = JSON.parse(JSON.stringify(selectedClinic));
			_clinic.isFav = value;
			setSelectedClinic(_clinic);
		}
	}

	function handleUnAthorized(history: {push: (path: string) => void}) {
		showAlert({
			message: "로그인이 필요한 서비스입니다.</br>AI2U 회원이라면 로그인해주세요.",
			buttons: ["취소", {text: "로그인", cssClass: "primary", handler: d => history.push("/login")}],
			cssClass: "alert-css2",
		});
	}

	function updateBookmark(id: number, value: boolean) {
		if (!isLoggedIn) handleUnAthorized(history);

		if (value) {
			// 즐겨찾기 추가
			addBookmark();
		} else {
			//즐겨찾기 삭제
			showAlert({
				message: "북마크를 해제 하시겠습니까?",
				buttons: [
					"취소",
					{
						text: "확인",
						cssClass: "primary",
						handler: async d => {
							removeBookmark();
						},
					}, //24251 1
				],
				cssClass: "alert-css1",
			});
		}

		function getPath() {
			let path = "";
			if (props.type === "Video") {
				path = value ? "video-fav-add" : "video-fav-del";
			} else if (props.type === "Clinic") {
				path = value ? "clinic-fav-add" : "clinic-fav-del";
			} else if (props.type === "Info") {
				path = value ? "info-fav-add" : "info-fav-del";
			}

			return path;
		}

		async function addBookmark() {
			let path = getPath();
			let params = {id: id};

			try {
				processApi<any>(path, params).then(() => {
					props.setBookmark(value);
					if (props.type === "Clinic") {
						syncBookmark(id, value);
					}
				});
				showToast({
					message: "북마크가 등록되았습니다.",
					duration: 400,
				});
			} catch (e: any) {
				console.log(e);
				if (e?.data === "duplicated") {
					//이미 저장되어 있는 경우
					props.setBookmark(value);
					syncBookmark(id, value);
				}
			}
		}

		async function removeBookmark() {
			let path = getPath();
			let params = {id: id};

			try {
				processApi<any>(path, params).then(() => {
					props.setBookmark(value);
					if (props.type === "Clinic") {
						syncBookmark(id, value);
					}
				});
				showToast({
					message: "북마크가 해제되았습니다.",
					duration: 400,
				});
			} catch (e: any) {
				console.log(e);
				if (e?.data === "already-deleted") {
					//저장되어 있지 않은 경우
					props.setBookmark(value);
					syncBookmark(id, value);
				}
			}
		}
	}

	return (
		<>
			<SwitchCase
				tests={[
					{
						test: props.type === "Info",
						component: (
							<button
								className={`bookmark ${props.className}`}
								onClick={() => {
									updateBookmark(props.item.id, !props.bookmark);
								}}
							/>
						),
					},
				]}
				defaultComponent={
					<img
						src={props.bookmark ? "assets/icon/icons-bookmark.svg" : "assets/icon/icons-bookmark-border.svg"}
						alt="bookmark"
						className="icon bookmark"
						onClick={() => {
							updateBookmark(props.item.id, !props.bookmark);
						}}
					/>
				}
			/>
		</>
	);
};

export default BookmarkButton;
