import React, {useEffect, useRef, useState} from "react";
import {
	IonPage,
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonGrid,
	IonCol,
	IonRow,
	IonButton,
	IonSelect,
	IonSelectOption,
	useIonToast,
} from "@ionic/react";
import {ErrorMessage} from "@hookform/error-message";

import HeaderBase from "../../components/HeaderBase";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {useApi} from "../../hooks/api";
import {useRecoilValue} from "recoil";
import {emailDomainState} from "../../store/atoms";
import {EmailDomain} from "../../store/types";

interface SignUpProps {
	onPrev: (data: any) => void;
	onNext: (form: FormData | undefined) => void;
}

interface FormData {
	username?: string;
	emailEnd1?: string;
	emailEnd2?: string;
	password?: string;
	passwordConfirm?: string;
}

const SignUp: React.FC<SignUpProps> = ({onPrev, onNext}) => {
	const {
		control,
		handleSubmit,
		watch,
		trigger,
		getValues,
		setError,
		clearErrors,
		formState: {errors, isValid},
	} = useForm<FormData>({
		mode: "onChange",
		//defaultValues: form,
	});

	const {processApi} = useApi();
	const emailDomainList: EmailDomain[] = useRecoilValue(emailDomainState);

	const [hasEmailCheck, setHasEmailCheck] = useState(false);
	const [showToast] = useIonToast();
	const onSubmit: SubmitHandler<FormData> = data => {
		if (!hasEmailCheck) {
			showToast({
				/*header: 'Use this lightsaber?',*/
				message: "이메일 중복체크를 실행해주세요.",
				duration: 600,
			});
		} else {
			onNext({username: getEmail(), password: data.password});
		}
	};

	useEffect(() => {}, []);

	const [emailEnd, setEmailEnd] = useState<string>("");
	const password: any = useRef();
	password.current = watch("password");

	const getEmail = () => {
		const data = getValues(["username", "emailEnd1"]);
		return `${data[0]}@${emailEnd !== "0" ? emailEnd : data[1]}`;
	};

	const checkEmail = async () => {
		const email = getEmail();
		const result = await processApi<any>("check-username", {username: email});
		return result;
	};

	const onEmailCheck = async () => {
		const trgCheckEmail = await trigger(["username", "emailEnd1", "emailEnd2"]);
		if (!trgCheckEmail) {
			return false;
		}
		const result = await checkEmail();
		if (result) {
			setError("username", {
				type: "duplicate-error",
				message: "이미 가입한 이메일입니다.",
			});
		} else {
			clearErrors("username");
			showToast({
				/*header: 'Use this lightsaber?',*/
				message: "사용가능한 이메일입니다.",
				duration: 600,
			});
			setHasEmailCheck(true);
		}
		return result;
	};

	const domain: any = useRef();
	domain.current = watch("emailEnd1");

	return (
		<IonPage className="pg-sign-up">
			<HeaderBase title="회원가입" backHref="/" onBackButton={onPrev} />
			<IonContent fullscreen>
				<div className="content-inner">
					<IonGrid className="ion-padding">
						<form onSubmit={handleSubmit(onSubmit)}>
							<IonRow>
								<IonCol>
									<IonList>
										{/* 이메일 ID */}
										<IonItem>
											<IonLabel position="stacked">이메일</IonLabel>
											<Controller
												name="username"
												control={control}
												rules={{
													validate(value) {
														return (
															1 + //@
																(value?.length ?? 0) +
																(emailEnd === "0" ? domain.current.length : emailEnd?.length ?? 0) <=
															40
														);
													},
													required: true,
													pattern: {
														value: /^[A-Z0-9._%+-]{1,40}$/i /*+@[A-Z0-9.-]+\.[A-Z] */,
														message: "이메일 아이디를 확인해주세요.",
													},
												}}
												render={({field}) => (
													<IonInput
														{...field}
														onIonChange={field.onChange}
														placeholder="이메일을 입력해주세요."
														className={errors.username === undefined ? "input-email" : "error input-email"}
													/>
												)}
											/>
											<span slot="end" className="email">
												@
											</span>
										</IonItem>

										{/* 이메일 직접 입력 */}
										{emailEnd === "0" ? (
											<IonItem className="item-email-end">
												<IonLabel position="stacked"></IonLabel>
												<Controller
													name="emailEnd1"
													control={control}
													rules={{
														pattern: {
															value: /^[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
															message: "이메일 형식을 확인해주세요.",
														},
													}}
													render={({field}) => (
														<IonInput
															{...field}
															id="emailEndInput"
															maxlength={40}
															onIonChange={field.onChange}
															placeholder="이메일 형식을 입력해주세요."
															className={errors.emailEnd1 === undefined ? "" : "error"}
														/>
													)}
												/>
											</IonItem>
										) : (
											<></>
										)}

										{/* 이메일 선택 검증 컨트롤러 */}
										<IonItem style={{display: "none"}}>
											<Controller
												name="emailEnd2"
												control={control}
												rules={{
													required: true,
												}}
												render={({field}) => (
													<IonInput {...field} value={emailEnd} onIonChange={field.onChange} />
												)}
											/>
										</IonItem>

										{/* 이메일 도메인 선택 */}
										<IonItem className="select-email">
											<IonSelect
												value={emailEnd}
												interface="action-sheet"
												placeholder="선택"
												cancelText="취소"
												onIonChange={e => setEmailEnd(e.detail.value)}
												className={errors.emailEnd2 === undefined ? "" : emailEnd === "0" ? "" : "error"}
											>
												<IonLabel position="stacked">이메일 선택</IonLabel>
												{emailDomainList?.map((domain: any, index) => (
													<IonSelectOption key={index} value={domain.id}>
														{domain.domain}
													</IonSelectOption>
												))}
											</IonSelect>

											<span className="chck" onClick={onEmailCheck} slot="end">
												중복확인
											</span>
										</IonItem>

										{errors.username !== undefined ? (
											<ErrorMessage
												errors={errors}
												message="이메일 형식을 확인해주세요."
												name="username"
												as={<div className="error-message" />}
											/>
										) : (
											<div>
												<ErrorMessage
													errors={errors}
													name="emailEnd1"
													message="이메일을 입력해주세요."
													as={<div className="error-message" />}
												/>
												<ErrorMessage
													errors={errors}
													name="emailEnd2"
													message="이메일을 선택해주세요."
													as={<div className="error-message" />}
												/>
											</div>
										)}
									</IonList>
									<IonList>
										<IonItem>
											<IonLabel position="stacked">비밀번호</IonLabel>
											<Controller
												name="password"
												control={control}
												rules={{
													required: true,
													pattern: {
														value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$/,
														message: "영문, 숫자, 기호를 모두 포함 6자리 이상 입력해주세요.",
													},
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={40}
														type="password"
														onIonChange={field.onChange}
														placeholder="비밀번호를 입력해주세요."
														className={errors.password === undefined ? "" : "error"}
													/>
												)}
											/>
										</IonItem>
										<ErrorMessage errors={errors} name="password" as={<div className="error-message" />} />
										<IonItem>
											<IonLabel position="stacked">비밀번호 확인</IonLabel>
											<Controller
												name="passwordConfirm"
												control={control}
												rules={{
													required: true,
													validate: value => value === password.current,
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={40}
														type="password"
														onIonChange={field.onChange}
														placeholder="비밀번호를 한번 더 입력해주세요."
														className={errors.passwordConfirm === undefined ? "" : "error"}
													/>
												)}
											/>
										</IonItem>
										<ErrorMessage
											errors={errors}
											name="passwordConfirm"
											message="비밀번호가 일치하지 않습니다."
											as={<div className="error-message" />}
										/>
									</IonList>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton
										disabled={!isValid}
										className=""
										expand="full"
										type="submit"
										mode="md"
										shape="round"
									>
										다음
									</IonButton>
								</IonCol>
							</IonRow>
						</form>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SignUp;
