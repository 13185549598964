import {useEffect} from "react";
import {RouteComponentProps} from "react-router";
import {IonContent, IonPage, useIonAlert} from "@ionic/react";
import {useApi} from "../../hooks/api";
import {HeaderBase} from "../../components";
import {FileAttacher} from "../../components/presentational";
import {useRecoilState, useSetRecoilState} from "recoil";
import {simulationImgListState, simulationImgState} from "../../store/atoms";

const SimulationPage: React.FC<RouteComponentProps> = ({history}) => {
	const {analysisProcessApi} = useApi();
	const [showAlert] = useIonAlert();
	const userState = localStorage.getItem("accessToken");

	const [file, setFile] = useRecoilState(simulationImgState);
	const setFileList = useSetRecoilState(simulationImgListState);

	const handleChangeFile = (files: any) => {
		if (files) {
			uploadFile([{contentBase64: files, isMirrored: true, tag: "simulation"}]);
		}
	};

	const uploadFile = async (file: any) => {
		try {
			const {data} = await analysisProcessApi<any>("simulation", file);

			if (data) {
				setFileList(data);

				setTimeout(() => {
					history.push("/simulation/result");
				}, 500);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		setFile([]);
	}, []);

	useEffect(() => {
		if (userState) return;

		showAlert({
			message: "로그인이 필요한 서비스입니다.</br>SDOC 회원이라면 로그인해주세요.",
			buttons: [
				{
					text: "취소",
					handler: () => history.push("/t/home"),
				},
				{text: "로그인", cssClass: "primary", handler: d => history.push("/login")},
			],
			cssClass: "alert-css2",
		});
	}, []);

	return (
		<IonPage className="simulation-page">
			<HeaderBase title="성형 후 시뮬레이션" />
			<IonContent>
				<div className="content-inner simulation-contents">
					<div className="simulation-contents-img">
						<img src="assets/img/simulation-info_img.png" alt="simulation" />
					</div>
					<div className="simulation-contents-upload">
						<h2>성형 후 내 모습이 궁금한가요?</h2>
						<h3>얼굴 사진을 선택해주세요.</h3>
						<div className="btn-bottom btn-wrap">
							<FileAttacher
								files={file}
								meta={{
									fileCount: 1,
									accept: ".jpg, .jpeg, .png",
									acceptCaption: ".jpg, .jpeg, .png",
								}}
								onChangeFile={handleChangeFile}
							/>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SimulationPage;
