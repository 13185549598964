/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useCallback} from "react";
import {useHistory} from "react-router";
import Verification from "./Verification";
import FindPasswordInput from "./FindPasswordInput";
import MyInfoPW from "../myPage/MyInfoPW";
import {IonButton, IonContent, IonPage, IonGrid, IonRow, IonCol, useIonAlert} from "@ionic/react";
import HeaderBase from "../../components/HeaderBase";

interface FormData {
	email?: string;
	token?: string;
}
const FindPassword: React.FC = () => {
	const [email, setEmail] = useState<string>();
	const [certToken, setCertToken] = useState<string>();
	const [page, setPage] = useState<number>(1);
	const history = useHistory();
	const [showAlert, hideAlert] = useIonAlert();
	const [error, setError] = useState<string>();

	const goNext = useCallback(() => {
		setPage(prev => prev + 1);
	}, []);

	const saveEmail = (email: any) => {
		setEmail(email);
		goNext();
	};

	const saveKcpCert = useCallback((payload: any) => {
		if (payload.error) {
			setError(payload.error)
		}
		else if (payload.certToken) {
			setCertToken(payload.certToken);
			goNext();
		} 
		// else {
		// 	showAlert({
		// 		cssClass: "alert-css2",
		// 		message: "입력하신 정보와 일치하는</br>회원이 없습니다.",
		// 		buttons: [{text: "확인", cssClass: "primary", handler: d => history.replace("/login/email")}],
		// 	});
		// }
	}, []);

	if(error === "notExist") {
		return (
			<IonPage className="pg-sign-up pg-vrf">
				<HeaderBase title="비밀번호 찾기" backHref="" />
				<IonContent fullscreen>
					<div className="content-inner">
						<IonGrid className="ion-padding pg-find-email">
							<IonRow>
								<IonCol>
									<div className="content-sub">서비스에 가입된 내역이 없습니다. 회원가입 해주세요<br /><br /></div>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton
										className=""
										expand="full"
										onClick={() => history.replace("/login")}
										mode="md"
										shape="round"
									>
										회원가입
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</IonContent>
			</IonPage>
		)
	}
	else
		return (
			<>
				{page === 1 && <FindPasswordInput onNext={saveEmail} />}
				{page === 2 && (
					<Verification
						action={"find-password"}
						token={email}
						headerTitle={"비밀번호 찾기"}
						backHref={"/"}
						onNext={saveKcpCert}
						onError={() => history.replace("/login/email")}
						content={"본인 인증을 통해\n비밀번호를 찾으실 수 있습니다."}
					/>
				)}
				{page === 3 && <MyInfoPW certToken={certToken} onNext={() => history.replace("/login/email")} />}
			</>
		);
};

export default FindPassword;
