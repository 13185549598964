/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
	IonActionSheet,
	IonAvatar,
	IonButton,
	IonCheckbox,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonSegment,
	IonSegmentButton,
	IonModal,
	useIonAlert,
	IonBackdrop,
} from "@ionic/react";
import {IonContent, IonPage, useIonToast} from "@ionic/react";
import {useHistory} from "react-router";
import {useRecoilState} from "recoil";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {useAuth} from "../../hooks/auth";
import HeaderBase from "../../components/HeaderBase";
import {infoText, orderValue} from "../configs";
import {useApi} from "../../hooks/api";
import {
	categoryListState,
	compareLogState,
	guidePopupState,
	infoCategoryIdState,
	logPageIndexState,
	logTypeState,
	totalCountGroupState,
} from "../../store/atoms";
import {Maybe} from "../../components";
import {BannerType, CategoryDto} from "../../store/types";
import {Progress} from "../../components/presentational";

const SkinLogPage: React.FC = () => {
	const history = useHistory();

	const {user} = useAuth();
	const {analysisProcessApi, processApi} = useApi();
	const [showAlert] = useIonAlert();
	const [showToast] = useIonToast();

	const userState = localStorage.getItem("accessToken");
	const pageSize = 5;

	const [logPageId, setLogPageId] = useRecoilState(logPageIndexState);
	const [totalCount, setTotalCount] = useRecoilState(totalCountGroupState);
	const [categoryId, setCategoryId] = useRecoilState(infoCategoryIdState);
	const [categoryList, setCategoryList] = useRecoilState(categoryListState);
	const [compareAnalysisData, setCompareAnalysisData] = useRecoilState(compareLogState);
	const [guideState, setGuideState] = useRecoilState(guidePopupState);
	const [analysisMenuValue, setAnalysisMenuValue] = useRecoilState(logTypeState);

	const [data, setData] = useState<any>([]);
	const [listData, setListData] = useState<any>([]);
	const [selectedDiseaseData, setselectedDiseaseData] = useState<any>([]);
	const [compareResultData, setCompareResultData] = useState<any>();

	const [isChecked, setIsChecked] = useState<boolean>(false);
	const [compareAnalysisMode, setCompareAnalysisMode] = useState<boolean>(false);

	const [orderTypeValue, setOrderTypeValue] = useState<string>("desc");
	const [categoryValue, setCategoryValue] = useState<any>();
	const [filterValue, setFilterValue] = useState<any>();

	const [showOrderActionSheet, setShowOrderActionSheet] = useState<boolean>(false);
	const [showDiseaseActionSheet, setShowDiseaseActionSheet] = useState<boolean>(false);
	const [showGuideActionSheet, setShowGuideActionSheet] = useState<boolean>(false);

	const [loading, setLoading] = useState(false);
	const [percentage, setPercentage] = useState(0);
	const [adLoading, setAdLoading] = useState(false);

	const [adBannerList, setAdBannerList] = useState<BannerType[] | any>();
	const [bannerAdType] = useState<number>(2);
	const [bannerSectionType] = useState<number>(2);
	const [progressTime, setProgressTime] = useState<number>();
	const [resultViewTime, setResultViewTime] = useState<number>(100);
	const [adTime, setAdTime] = useState<any>();
	const [adLoadingComplete, setAdLoadingComplete] = useState<boolean>(false);

	const setSpeedForSlide = (num: any) => {
		return num * 1000;
	};

	const adBannerSliderOPtion = {
		dots: false,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: 0,
		infinite: true,
		pauseOnHover: false,
		autoplay: true,
		autoplaySpeed: adTime,
		speed: 1000,
		beforeChange: function (slide: any, currentSlide: any) {
			setAdTime(adBannerList[currentSlide].minimumTime * 1000);

			if (adBannerList.length === 1) {
				if (adBannerList[0].minimumTime > 15) {
					setProgressTime(adBannerList[0].minimumTime * 1000);
					setResultViewTime(adBannerList[0].minimumTime * 10);
				} else {
					setProgressTime(15000);
				}
			} else if (adBannerList.length > 1) {
				if (adBannerList[1].minimumTime > 15) {
					setProgressTime(adBannerList[1].minimumTime * 1000);
					setResultViewTime(adBannerList[1].minimumTime * 10);
				} else {
					setProgressTime(15000);
				}
			}
		},
		afterChange: function (currentSlide: any) {
			setAdTime(setSpeedForSlide(adBannerList[currentSlide].minimumTime));
		},
	};

	const fetchCategories = useCallback(async () => {
		let data: any[] | null; // :CategoryDto[] = []
		try {
			data = await processApi<CategoryDto[]>("category");
		} catch (error) {
			data = null;
		}

		let categories = [{key: "-1", name: "전체"}];
		if (data !== null) {
			categories.push(...data);
		}

		setCategoryList(categories);
		setCategoryId(categories[0].key);
	}, []);

	const getData = useCallback(async (category: any, orderType: any, analysisType: any, pageIndex: number) => {
		let params: any = {
			orderingType: orderType,
			snapshotType: analysisType,
			page: pageIndex,
			pageCapacity: pageSize,
		};

		if (category !== "질환별" && category !== "전체") {
			params["category"] = category;
		}
		const {data} = await analysisProcessApi<any>("log-v2", params);

		setData((prev: any) => {
			if (pageIndex === 1) return data.logItemGroupList;
			else return [...prev, ...data.logItemGroupList];
		});
		setListData(data);

		setFilterValue(data.currentDiseaseFilter);
		setLogPageId(prev => (prev = pageIndex + 1));
		setTotalCount(data.totalCountOfItemGroup);
	}, []);

	const getBannerList = async () => {
		let params = {
			adType: bannerAdType,
			sectionType: bannerSectionType,
		};

		try {
			const data = await processApi<any>("ad", params);
			setAdBannerList(data.detailList);
		} catch (e) {
			console.log(e);
		}
	};

	const handleChangeCheckedItem = (e: any, index: any) => {
		const value = e.detail.value;
		const dataNum = 2;

		if (e.detail.checked) {
			const selectedDiseaseList = selectedDiseaseData;
			selectedDiseaseList.push(value);
			setselectedDiseaseData(selectedDiseaseList);

			if (
				selectedDiseaseData?.length === dataNum &&
				selectedDiseaseData[0]?.predictedDiseaseNameList[0].text ===
					selectedDiseaseData[1]?.predictedDiseaseNameList[0].text
			) {
				setIsChecked(true);
			} else {
				setIsChecked(false);
			}

			if (
				selectedDiseaseData?.length === dataNum &&
				selectedDiseaseData[0]?.predictedDiseaseNameList[0].text !==
					selectedDiseaseData[1]?.predictedDiseaseNameList[0].text
			) {
				showToast({
					message: "첫번째 질환과 동일한 질환만 선택 가능합니다.",
					duration: 1000,
				});
			}

			if (selectedDiseaseData.length > dataNum) {
				showToast({
					message: "비교할 기록은 2개만 선택 가능합니다.",
					duration: 1000,
				});
			}
		} else {
			const selectedItem = [...selectedDiseaseData].filter(v => v.analysisKey !== index);
			setselectedDiseaseData(selectedItem);

			if (
				selectedItem?.length === dataNum &&
				selectedItem[0]?.predictedDiseaseNameList[0].text ===
					selectedItem[1]?.predictedDiseaseNameList[0].text
			) {
				setIsChecked(true);
			} else {
				setIsChecked(false);
			}
		}
	};

	const createCompareData = useCallback(async () => {
		const compareData = selectedDiseaseData.map((item: any) => item.analysisKey);
		setCompareAnalysisData(compareData);

		try {
			if (compareAnalysisData) {
				if (adBannerList) {
					setAdLoading(true);
				} else {
					setLoading(true);
				}

				const result = await analysisProcessApi<any>("log-comparison", {
					analysisKeyArr: compareData,
				});

				if (result) {
					setCompareResultData(result);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [selectedDiseaseData, adBannerList]);

	const handleMoveToCompareResult = () => {
		setTimeout(() => {
			setAdLoading(false);
			history.push(`/skin-change/result/${analysisMenuValue}`);
		}, 500);
	};

	const handleMoveToInfo = (key: any) => {
		history.push({pathname: "/skin-analysis/result", state: {key: key}});
	};

	const handleCompareAnalysis = () => {
		setCompareAnalysisMode(prev => !prev);
		setselectedDiseaseData([]);
		setIsChecked(false);

		if (!compareAnalysisMode) {
			showToast({
				message: "비교할 기록을 2개 선택해 주세요.",
				duration: 500,
			});
		}
	};

	const handleAnalysisTypeTab = (e: any) => {
		setAnalysisMenuValue(e.detail.value ?? "");
		setCompareAnalysisMode(false);
		setselectedDiseaseData([]);
		setOrderTypeValue("desc");
		setCategoryValue(null);
		setIsChecked(false);
	};

	const handleCloseGuidePopup = () => {
		setShowGuideActionSheet(false);
		setGuideState(false);
	};

	useEffect(() => {
		fetchCategories();
		getBannerList();
	}, []);

	useMemo(() => {
		getData(categoryValue, orderTypeValue, analysisMenuValue, 1);
	}, [categoryValue, orderTypeValue, analysisMenuValue]);

	useEffect(() => {
		if (userState) return;

		showAlert({
			message: "로그인이 필요한 서비스입니다.</br>SDOC 회원이라면 로그인해주세요.",
			buttons: [
				{
					text: "취소",
					handler: () => history.push("/t/home"),
				},
				{text: "로그인", cssClass: "primary", handler: d => history.push("/login")},
			],
			cssClass: "alert-css2",
		});
	}, []);

	useEffect(() => {
		if (!user) {
			setShowGuideActionSheet(false);
		} else {
			setShowGuideActionSheet(true);
		}

		if (guideState === false) {
			setShowGuideActionSheet(false);
			setGuideState(false);
		}
	}, []);

	useEffect(() => {
		if (adLoading && resultViewTime) {
			setTimeout(() => {
				if (percentage < 100) {
					setPercentage(percentage + 1);
				}
			}, resultViewTime);
		}
	}, [adLoading, percentage]);

	useEffect(() => {
		if (adLoading && progressTime) {
			setTimeout(() => {
				setAdLoadingComplete(true);
			}, progressTime);
		}
	}, [adLoading, percentage, progressTime]);

	useEffect(() => {
		if (loading) {
			setTimeout(() => {
				if (percentage < 100) {
					setPercentage(percentage + 1);
				}
			}, 120);
		}
	}, [loading, percentage]);

	useEffect(() => {
		if (compareResultData && loading) {
			setTimeout(() => {
				setLoading(false);
				history.push(`/skin-change/result/${analysisMenuValue}`);
			}, 15000);
		}
	}, [loading, compareResultData]);

	return (
		<IonPage className="history-page">
			<HeaderBase title="내 피부 변화" />
			<div className="menu-wrap">
				<IonSegment
					className="history-page__menu"
					value={analysisMenuValue}
					mode="md"
					onIonChange={e => handleAnalysisTypeTab(e)}
				>
					<IonSegmentButton value="face">얼굴</IonSegmentButton>
					<IonSegmentButton value="body">신체</IonSegmentButton>
				</IonSegment>
			</div>
			<IonContent fullscreen>
				<div className={`${compareAnalysisMode ? "content-inner padding" : "content-inner"}`}>
					<div className="history-page__wrap">
						<div className="select-contents ion-padding">
							<div className="select-contents__inner">
								<div className="select-contents__inner--compare">
									<Maybe
										test={data}
										children={
											<IonItem>
												<IonButton
													onClick={handleCompareAnalysis}
													className={`${compareAnalysisMode ? "compare" : ""}`}
												>
													피부 비교 분석
												</IonButton>
											</IonItem>
										}
									/>
								</div>
								<div className="select-contents__inner--filter">
									<IonItem
										onClick={() => {
											setShowOrderActionSheet(true);
										}}
									>
										<IonLabel>{orderTypeValue === "desc" ? "최신순" : "과거순"}</IonLabel>
										<img slot="end" src="assets/icon/icons_arrow_down.png" alt="order" className="icon" />
									</IonItem>
									<IonItem
										onClick={() => {
											setShowDiseaseActionSheet(true);
										}}
									>
										<IonLabel>{categoryValue ? categoryValue : "질환별"}</IonLabel>
										<img slot="end" src="assets/icon/icons_arrow_down.png" alt="order" className="icon" />
									</IonItem>
								</div>
							</div>
						</div>
						<Maybe test={!data}>
							<div className="none">
								<h2>기록이 없습니다.</h2>
								<h3>내 피부 분석 기록이 없습니다.</h3>
							</div>
						</Maybe>

						<div className="list-contents">
							<Maybe
								test={listData.currentDiseaseFilter === filterValue}
								children={
									<>
										{data?.map((list: any, index: any) => (
											<IonList key={index}>
												<p className="date">{list.datePerDayGroup}</p>
												<div className="list-inner">
													{list.logItemList.map((item: any, index: any) => (
														<IonItem className="list-item" key={index}>
															{compareAnalysisMode && (
																<IonCheckbox
																	slot="start"
																	value={item}
																	onIonChange={e => handleChangeCheckedItem(e, item.analysisKey)}
																/>
															)}
															<div
																className="list-item__inner"
																onClick={() => handleMoveToInfo(item.analysisKey)}
															>
																<IonAvatar slot="start">
																	<img src={item.imageUrl} alt="" />
																</IonAvatar>
																<IonLabel>
																	<h3>{item.appliedDate}</h3>
																	<div className="item-text">
																		{item.predictedDiseaseNameList?.map((disease: any, index: any) => (
																			<h2 key={index}>
																				{`${
																					item.predictedDiseaseNameList?.length > 1 && index !== 0 ? ", " : ""
																				}`}
																				{disease.text}
																			</h2>
																		))}
																	</div>
																</IonLabel>
																{!compareAnalysisMode && (
																	<IonIcon slot="end" icon="assets/icon/icon_arrow_next.svg" />
																)}
															</div>
														</IonItem>
													))}
												</div>
											</IonList>
										))}
									</>
								}
							/>
							{data?.length < totalCount && data?.length >= pageSize ? (
								<IonList className="list-more">
									<IonItem
										className="btn-more"
										onClick={() => getData(categoryValue, orderTypeValue, analysisMenuValue, logPageId)}
									>
										<p>
											더보기
											<em />
										</p>
									</IonItem>
								</IonList>
							) : (
								<></>
							)}
						</div>

						<Maybe
							test={compareAnalysisMode}
							children={
								<div className="bottom-contents">
									<div className="btn-bottom">
										<button
											className={`button ${isChecked ? "active" : ""}`}
											onClick={isChecked ? () => createCompareData() : undefined}
										>
											내 피부 변화 분석하기
										</button>
									</div>
								</div>
							}
						/>

						<Maybe
							test={showGuideActionSheet}
							children={
								<IonActionSheet
									isOpen={showGuideActionSheet}
									onDidDismiss={handleCloseGuidePopup}
									cssClass="guide-change"
									header="피부 비교 분석으로&#10;내 피부의 변화를 확인해 보세요!"
									buttons={[
										{
											icon: "assets/icon/icons_close_black.png",
											cssClass: "close",
										},
									]}
								/>
							}
						/>

						<IonActionSheet
							isOpen={showOrderActionSheet}
							onDidDismiss={() => setShowOrderActionSheet(false)}
							buttons={orderValue.map(item => ({
								text: `${item.title}`,
								value: `${item.value}`,
								handler: () => {
									setOrderTypeValue(item.value);
									setselectedDiseaseData([]);
									setCompareAnalysisMode(false);
								},
							}))}
						/>

						<IonActionSheet
							isOpen={showDiseaseActionSheet}
							onDidDismiss={() => setShowDiseaseActionSheet(false)}
							buttons={categoryList.map(item => ({
								text: `${item.name}`,
								value: `${item.name}`,
								handler: () => {
									setCategoryValue(item.name);
									setselectedDiseaseData([]);
									setCompareAnalysisMode(false);
								},
							}))}
						/>
					</div>
				</div>
			</IonContent>

			<IonModal isOpen={loading} animated={false} className="progress-modal">
				<Progress
					title={user?.realName}
					subTitle={infoText.subTitle}
					info={infoText.info}
					percentage={percentage}
				/>
			</IonModal>

			{adLoading && (
				<div className="progress-modal">
					<div className="ad">
						<i className="icon-ad" />
					</div>
					<div className="ad-contents-wrap">
						<div className="ad-contents-inner">
							<div className="ad-contents">
								<Slider {...adBannerSliderOPtion}>
									{adBannerList?.map((item: any, index: any) => (
										<div key={index}>
											{item.fileType === "movie" ? (
												<div className="ad-video">
													<video
														autoPlay
														muted
														playsInline
														loop
														width="100%"
														height="100%"
														data-keepplaying={false}
													>
														<source src={item.fileUrl} />
													</video>
												</div>
											) : (
												<img
													src={item.fileUrl}
													onClick={() => (item.targetUrl ? window.open(item.targetUrl) : undefined)}
													alt="ad"
												/>
											)}
										</div>
									))}
								</Slider>
							</div>
						</div>

						<Progress
							title={user?.realName}
							subTitle={infoText.subTitle}
							info={infoText.info}
							percentage={percentage}
							adSlideList={adBannerList}
							adMode
							progressComplete={adLoadingComplete}
							handleClickResult={handleMoveToCompareResult}
						/>
					</div>
				</div>
			)}
		</IonPage>
	);
};

export default SkinLogPage;
