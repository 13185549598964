/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback, useState} from "react";
import {
	IonPage,
	IonItem,
	IonLabel,
	IonContent,
	IonAccordion,
	IonAccordionGroup,
	IonSegment,
	IonSegmentButton,
	IonGrid,
	IonRow,
	IonCol,
	IonFooter,
} from "@ionic/react";
import {useRecoilState} from "recoil";
import {faqCategoryListState, faqListState, faqSingleState} from "../../store/atoms";
import HeaderBase from "../../components/HeaderBase";
import {CategoryDto, FaqDto} from "../../store/types";
import {useApi} from "../../hooks/api";
import shortid from "shortid";

const FrequentlyAskedQuestion: React.FC = () => {
	const {processApi} = useApi();

	const [id, setId] = useState<number>(-1);
	const [list, setList] = useRecoilState(faqListState);
	const [faqItem, setFaqItem] = useRecoilState(faqSingleState);

	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [isLoadedListData, setIsLoadedListData] = useState<boolean>(false);
	const [categoryKey, setCategoryKey] = useState<string>();
	const [categoryList, setCategoryList] = useRecoilState(faqCategoryListState);

	const fetchCategories = useCallback(async () => {
		const list = await processApi<CategoryDto[]>("faq-category");
		const categories = [{key: "-1", name: "전체"}, ...list];

		setCategoryList(categories);
		setCategoryKey(categories[0].key ?? "");
	}, []);

	const fetchList = useCallback(async categoryKey => {
		setIsLoadedListData(false);
		let params: any = {};
		if (categoryKey !== "-1") {
			params["category"] = categoryKey;
		}

		let list = [];
		try {
			list = await processApi<FaqDto[]>("faq", params);
			setList(list);
		} catch (error) {
			setList([]);
		}
		setIsLoadedListData(true);
	}, []);

	const fetchData = useCallback(async (id: number) => {
		const data = await processApi<any>("faq-item", {id: id});
		setFaqItem(data);
	}, []);

	useEffect(() => {
		fetchCategories();
		setIsLoaded(true);
	}, []);

	useEffect(() => {
		if (isLoaded) {
			setId(-1);
			setFaqItem({id: -1});
			fetchList(categoryKey);
		}
	}, [categoryKey]);

	useEffect(() => {
		if (id !== -1) {
			fetchData(id);
		}
	}, [id]);

	return (
		<IonPage className="pg-faq">
			<HeaderBase title="자주 묻는 질문" backHref="/"></HeaderBase>
			<IonSegment
				id="segment"
				className="menu"
				scrollable
				value={categoryKey}
				mode="md"
				onIonChange={event => setCategoryKey(event.detail.value ?? "")}
			>
				{categoryList.map((x, index) => (
					<IonSegmentButton id={`segmentButton${x.id}`} key={index} value={x.key ?? ""} mode="md">
						{x.name}
					</IonSegmentButton>
				))}
			</IonSegment>
			<IonContent>
				<div className="content-inner">
					{isLoadedListData && (list?.length ?? 0) === 0 ? (
						<div className="no-result">조회 결과가 없습니다.</div>
					) : (
						<></>
					)}
					<IonAccordionGroup value={id.toString()}>
						{list.map((item: any, index) => (
							<IonAccordion key={index}>
								<IonItem slot="header" onClick={() => setId(Number(item.id))} className="header">
									<IonLabel className="ion-padding">
										<p>{item.category}</p>
										<h2>{item.title}</h2>
									</IonLabel>
								</IonItem>

								<IonItem slot="content">
									<div className="faq-content">
										{faqItem?.content?.split("\n").map(item => {
											return (
												<p className="ion-padding" key={shortid.generate()}>
													{item}
												</p>
											);
										})}
									</div>
								</IonItem>
							</IonAccordion>
						))}
					</IonAccordionGroup>
				</div>
			</IonContent>
			<IonFooter>
				<IonGrid className="footer ion-padding">
					<IonRow>
						<IonCol>
							<p>
								혹시 이용하시는데 불편한 사항이 있으신가요?
								<br />
								이곳으로 문의해주세요.
								<br />
								<span onClick={() => window.open("mailto:aitoyou.cs@gmail.com")}>
									고객센터 : aitoyou.cs@gmail.com
								</span>
							</p>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonFooter>
		</IonPage>
	);
};

export default FrequentlyAskedQuestion;
