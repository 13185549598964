import {IonCheckbox, IonLabel} from "@ionic/react";
import styled from "styled-components";
import "@ionic/react/css/ionic-swiper.css";

const CheckboxContainer = styled.div`
	display: flex;
`;

interface CheckboxProps {
	checked?: boolean;
	onChange?: any;
	value?: any;
	disabled?: boolean;
	className?: string;
	text?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({checked, onChange, value, disabled, className, text}) => {
	return (
		<CheckboxContainer>
			<div className={className}>
				<IonCheckbox checked={checked} onIonChange={onChange} disabled={disabled} />
				<IonLabel>
					<span>{text}</span>
				</IonLabel>
			</div>
		</CheckboxContainer>
	);
};

export default Checkbox;
