import moment from "moment";
import "moment/locale/ko";

export const TIMEZONE_OFFSET = moment().utcOffset();
export const DATE_FORMAT = "YYYY.MM.DD";
export const DATETIME_FORMAT = "YYYY.MM.DD A HH:mm";

export const parseUTCToLocalDatetime = (utcTime: any, format: any): string => {
	if (!utcTime) {
		return "-";
	}

	if (format) {
		return moment(new Date(utcTime)).utcOffset(TIMEZONE_OFFSET.toString()).format(format).toString();
	}
	return moment(new Date(utcTime)).utcOffset(TIMEZONE_OFFSET.toString()).toString();
};

export const dateFormat = (date: any, format: any): string => moment(date).format(format);
