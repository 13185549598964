/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect} from "react";
import {RouteComponentProps} from "react-router-dom";
import {withRouter} from "react-router";
import {IonRow, IonPage, IonButton, IonContent} from "@ionic/react";
import {useRecoilState, useSetRecoilState} from "recoil";
import {ThumbnailSlider} from "../../../../components/presentational";
import {
	analysisFaceCenter,
	analysisFaceLeft,
	analysisFacePhotoList,
	analysisFaceRight,
	analysisGuidePopupState,
	analysisKey,
	centerFaceCamMode,
	leftFaceCamMode,
	rightFaceCamMode,
	faceLocation,
	takeCamMode,
} from "../../../../store/atoms";
import {useApi} from "../../../../hooks/api";
import {camModeAll, camModeOne, faceModeUser} from "../../../configs";

const UploadResultPage: React.FC<RouteComponentProps> = ({history}) => {
	const {analysisProcessApi} = useApi();

	const [photoList, setPhotoList] = useRecoilState(analysisFacePhotoList);
	const [analysisKeyValue, setAnalysisKeyValue] = useRecoilState(analysisKey);
	const [faceLocationValue, setFaceLocationValue] = useRecoilState(faceLocation);

	const setCenterFace = useSetRecoilState(analysisFaceCenter);
	const setLeftFace = useSetRecoilState(analysisFaceLeft);
	const setRightFace = useSetRecoilState(analysisFaceRight);

	const setCenterFacingMode = useSetRecoilState(centerFaceCamMode);
	const setLeftFacingMode = useSetRecoilState(leftFaceCamMode);
	const setRightFacingMode = useSetRecoilState(rightFaceCamMode);

	const setCamMode = useSetRecoilState(takeCamMode);

	const setShowGuidePopup = useSetRecoilState(analysisGuidePopupState);

	const handleRetakeAllPhoto = () => {
		setCenterFace(null);
		setLeftFace(null);
		setRightFace(null);

		setPhotoList([]);
		setFaceLocationValue("center");

		setCamMode(camModeAll);
		setCenterFacingMode(faceModeUser);
		setLeftFacingMode(faceModeUser);
		setRightFacingMode(faceModeUser);

		setTimeout(() => {
			history.push("/skin-analysis/upload/face");
		}, 100);
	};

	const handleRetakePhoto = () => {
		switch (faceLocationValue) {
			case "center":
				setCenterFace(null);
				setCenterFacingMode(faceModeUser);
				break;
			case "left":
				setLeftFace(null);
				setLeftFacingMode(faceModeUser);
				break;
			case "right":
				setRightFace(null);
				setRightFacingMode(faceModeUser);
				break;
		}

		setCamMode(camModeOne);

		setTimeout(() => {
			history.push("/skin-analysis/upload/face");
		}, 100);
	};

	const savePhoto = useCallback(async () => {
		try {
			const {data} = await analysisProcessApi<any>("attach-file", photoList);
			localStorage.setItem("analysisKey", data.analysisKey);
			setAnalysisKeyValue(data.analysisKey);
			history.push("/skin-analysis/question");
		} catch (error) {
			console.log(error);
		}
	}, [photoList, analysisKeyValue]);

	useEffect(() => {
		setShowGuidePopup(false);
	}, []);

	const renderPhotoMode = () => {
		return (
			<div className="analysis-upload__photo">
				<ThumbnailSlider slideImg={photoList} />
				<p className="analysis-upload__photo--info">
					이 사진들로 분석을 진행하시겠습니까?
					<br />
					원하시면 확인 버튼을 눌러주세요.
				</p>
				<div className="btn-bottom__single btn-wrap">
					<IonRow>
						<IonButton expand="full" shape="round" onClick={savePhoto}>
							확인
						</IonButton>
					</IonRow>
				</div>
				<IonRow className="btn-bottom__double analysis-upload__photo--button">
					<button className="btn btn-refresh" onClick={handleRetakeAllPhoto}>
						모두 다시 찍기
					</button>
					<button className="btn btn-retake" onClick={handleRetakePhoto}>
						다시 찍기
					</button>
				</IonRow>
			</div>
		);
	};

	return (
		<IonPage className="analysis-upload">
			<IonContent fullscreen>{renderPhotoMode()}</IonContent>
		</IonPage>
	);
};

export default withRouter(UploadResultPage);
