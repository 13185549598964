import Webcam from "react-webcam";
import "./Webcam.scss";

interface WebCamState {
	camRef?: any;
	photo?: any;
	handleTakePhoto?: any;
	objectFit?: any;
	videoConstraints?: any;
	mirrorMode?: boolean;
}

export const WebCam = ({camRef, photo, objectFit, videoConstraints, mirrorMode}: WebCamState) => {
	return (
		<>
			{photo ? (
				<div className="photo">
					<img src={photo} alt="" width="100%" height="calc(100% - 90px)" />
				</div>
			) : (
				<Webcam
					audio={false}
					ref={camRef}
					width="100%"
					height="calc(100% - 90px)"
					mirrored={mirrorMode}
					screenshotFormat="image/jpeg"
					forceScreenshotSourceSize
					style={{objectFit: objectFit}}
					videoConstraints={videoConstraints}
				/>
			)}
		</>
	);
};

export default WebCam;
