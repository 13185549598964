/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from "react";
import {
	IonPage,
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonGrid,
	IonCol,
	IonRow,
	IonButton,
	useIonToast,
} from "@ionic/react";

import HeaderBase from "../../components/HeaderBase";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {useApi} from "../../hooks/api";
import {ErrorMessage} from "@hookform/error-message";
import moment from "moment";
import jwt_decode from "jwt-decode";

interface SignUpStep2Props {
	token?: string;
	onPrev: (data: any) => void;
	onNext: (form: FormData | undefined) => void;
}

interface FormData {
	realName?: string;
	birthDay?: string;
	gender?: string;
	nick?: string;
}

const SignUpStep2: React.FC<SignUpStep2Props> = ({token, onPrev, onNext}) => {
	const [hasNickCheck, setHasNickCheck] = useState(false);
	const [showToast] = useIonToast();

	const newForm = useMemo(() => {
		const decoded: any = jwt_decode(token ? token : "");
		return {
			realName: decoded.realName,
			birthDay: moment(decoded.birthDay, "YYYYMMDD").format("YYYY.MM.DD"),
			gender: decoded.gender === "MALE" ? "남자" : decoded.gender === "FEMALE" ? "여자" : "",
		};
	}, [token]);
	const {
		control,
		handleSubmit,
		getValues,
		setError,
		formState: {errors, isValid},
		reset,
	} = useForm<FormData>({
		mode: "onChange",
		defaultValues: newForm,
	});

	const {processApi} = useApi();

	const onSubmit: SubmitHandler<FormData> = async data => {
		const nickCheckResponse = await checkNickname();
		if (!hasNickCheck) {
			showToast({
				/*header: 'Use this lightsaber?',*/
				message: "닉네임 중복체크를 실행해주세요.",
				duration: 600,
			});
		} else if (nickCheckResponse) {
			setError("nick", {
				type: "duplicate-error",
				message: "이미 등록된 닉네임입니다.",
			});
		} else {
			onNext({nick: data.nick});
		}
	};

	const [gender, setGender] = useState<string>();

	useEffect(() => {
		reset(newForm);
	}, [reset, newForm]);

	useEffect(() => {
		const genderTemp = getValues("gender");
		if (genderTemp) {
			setGender(genderTemp);
		}
	}, []);

	const checkNickname = async () => {
		const nick = getValues("nick");
		const checkResponse = await processApi<any>("check-nick", {nick});
		return checkResponse;
	};

	const onNicknameCheck = async () => {
		const result = await checkNickname();
		if (result) {
			setError("nick", {
				type: "duplicate-error",
				message: "이미 등록된 닉네임입니다.",
			});
			setHasNickCheck(false);
		} else {
			showToast({
				/*header: 'Use this lightsaber?',*/
				message: "사용가능한 닉네임입니다.",
				duration: 600,
			});
			setHasNickCheck(true);
			//alert("사용가능한 닉네임입니다.");
		}
		return result;
	};

	return (
		<IonPage className="pg-sign-up">
			<HeaderBase title="회원가입" backHref="/" onBackButton={onPrev} />
			<IonContent fullscreen>
				<div className="content-inner">
					<IonGrid className="ion-padding">
						<form onSubmit={handleSubmit(onSubmit)}>
							<IonRow>
								<IonCol>
									<IonList>
										<IonItem>
											<IonLabel position="stacked">이름</IonLabel>
											<Controller
												name="realName"
												control={control}
												render={({field}) => <IonInput {...field} disabled />}
											/>
										</IonItem>
										<IonItem>
											<IonLabel position="stacked">생년월일</IonLabel>
											<Controller
												name="birthDay"
												control={control}
												render={({field}) => <IonInput {...field} disabled />}
											/>
										</IonItem>
										<IonItem>
											<IonLabel position="stacked">성별</IonLabel>
											<Controller
												name="gender"
												control={control}
												render={({field}) => <IonInput {...field} disabled />}
											/>
										</IonItem>
										<div className="nknm-box">
											<IonLabel position="stacked">닉네임</IonLabel>
											<Controller
												name="nick"
												control={control}
												rules={{
													required: true,
												}}
												render={({field}) => (
													<IonInput
														{...field}
														onIonChange={field.onChange}
														maxlength={10}
														placeholder="닉네임을 입력해주세요."
													/>
												)}
											/>
											{/* <IonInput value={input?.nick} onChange={onChangeNick} placeholder="닉네임" > </IonInput> */}

											<div className="chck nknm" onClick={onNicknameCheck} slot="end">
												중복확인
											</div>
										</div>
										<ErrorMessage errors={errors} name="nick" as={<div className="error-message" />} />
									</IonList>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton
										disabled={!isValid}
										className=""
										expand="full"
										type="submit"
										mode="md"
										shape="round"
									>
										다음
									</IonButton>
								</IonCol>
							</IonRow>
						</form>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SignUpStep2;
