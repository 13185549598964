import {useEffect, useRef} from "react";
import {MindARThree} from "mind-ar/dist/mindar-face-three.prod";
import * as THREE from "three";
import {useRecoilState} from "recoil";
import {tokenState} from "../../../store/atoms";
import {compareLogState} from "../../../store/atoms";

function ARCam() {
	const ANALYSIS_URL = process.env.REACT_APP_ANALYSIS_API_URL;

	const containerRef = useRef(null);
	const [compareAnalysisKey] = useRecoilState(compareLogState);
	const [token] = useRecoilState(tokenState);

	useEffect(() => {
		const mindarThree = new MindARThree({
			container: containerRef.current,
			maxDetectionRate: 120,
			frameRate: 120,
		});

		const {renderer, scene, camera} = mindarThree;

		const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 1);
		scene.add(light);

		const faceMesh = mindarThree.addFaceMesh();
		const textureLoader = new THREE.TextureLoader();
		const header = {
			authorization: `Bearer ${token.token}`,
		};
		fetch(
			`${ANALYSIS_URL}/v2/log/analysis/image/proxy?analysisKeyFirst=${encodeURIComponent(
				String(compareAnalysisKey[0]),
			)}&analysisKeySecond=${encodeURIComponent(String(compareAnalysisKey[1]))}&respType=file`,
			{
				method: "GET",
				headers: header,
			},
		)
			.then(response => response.blob())
			.then(blob => {
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					const base64data = reader.result;
					const texture = textureLoader.load(base64data);
					texture.wrapS = THREE.RepeatWrapping;
					texture.repeat.x = -1;

					faceMesh.material.map = texture;
					faceMesh.material.transparent = true;
					faceMesh.material.needsUpdate = true;
					scene.add(faceMesh);

					mindarThree.start();
					renderer.setAnimationLoop(() => {
						renderer.render(scene, camera);
					});
				};
			});
		return () => {
			renderer.setAnimationLoop(null);
			mindarThree.stop();
		};
	}, []);

	return (
		<div
			style={{
				width: "100vw",
				height: "100vh",
				position: "relative",
				overflow: "hidden",
				transform: "scaleX(-1)",
			}}
			ref={containerRef}
		/>
	);
}

export default ARCam;
