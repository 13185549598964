import {selector} from "recoil";
import {
	analysisPartState,
	recentClinicListState,
	recentInfoListState,
	recentVideoListState,
	recentWikiListState,
	searchPageTypeState,
} from "./atoms";
import {RadiusItem, RecentSearchItem} from "./types";

export const recentSearchSelector = selector({
	key: "recentSearchSelector",
	get: ({get}) => {
		const searchPageType = get(searchPageTypeState);

		let datas: RecentSearchItem[] = [];
		if (searchPageType === "clinic") {
			datas = get(recentClinicListState);
		} else if (searchPageType === "video") {
			datas = get(recentVideoListState);
		} else if (searchPageType === "wiki") {
			datas = get(recentWikiListState);
		} else if (searchPageType === "info") {
			datas = get(recentInfoListState);
		}

		return datas;
	},
});

export const radiusItemSelector = selector<RadiusItem[]>({
	key: "radiusItemSelector",
	get: () => {
		return [
			{name: "500m", value: 500},
			{name: "1km", value: 1000},
			{name: "1.5km", value: 1500},
			{name: "5km", value: 5000},
		];
	},
});
