/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { IonPage, IonContent, IonGrid, IonCol, IonRow, IonButton, IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import HeaderBase from "../../components/HeaderBase";
import { useHistory } from "react-router";

const SignUpComplete: React.FC = () => {
	const history = useHistory();

	const goHome = useCallback(() => {
		history.replace("/");
	}, []);

	return (
		<IonPage className="pg-vrf pg-sign-up-complete">
			<HeaderBase title="" />
			<IonContent fullscreen>
				<div className="content-inner">
					<IonGrid className="ion-padding">
						<IonRow>
							<IonCol>
								<IonIcon src={checkmarkCircleOutline} className="icon-complete"></IonIcon>
								<div className="content-main">SDOC 회원가입이 완료되었습니다.</div>
								<div className="content-sub">
									환영합니다!
									<br />
									다양한 SDOC의 서비스를 이용해보세요.
								</div>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonButton className="" expand="full" onClick={goHome} mode="md" shape="round">
									시작하기
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SignUpComplete;
