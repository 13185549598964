/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback, useState} from "react";
import {
	IonPage,
	IonItem,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonIcon,
	useIonViewDidEnter,
	isPlatform,
	IonToast,
} from "@ionic/react";
import HeaderBase from "../../components/HeaderBase";
import {useRecoilState, useSetRecoilState} from "recoil";
import {
	mapViewInfoAroundMe,
	mapViewInfoByMap,
	clinicAroundListState,
	clinicFilterState,
	clinicListState,
	clinicSearchTypeState,
	searchPageTypeState,
	isOpenClinicModalState,
	lastFetchLevelState,
	selectedClinicState,
} from "../../store/atoms";
import {useApi} from "../../hooks/api";
import {Clinic, ClinicSearchType} from "../../store/types";
import {refreshOutline} from "ionicons/icons";
import {useHistory, useLocation} from "react-router";
import Map from "../../components/Map";
import MapMarker from "../../components/MapMarker";
import useCurrentLocation, {geoLocationOptions} from "../../hooks/useCurrentPosition";
import CustomOverlayMap from "../../components/presentational/CustomOverlayMap";
import SelectedClinicItem from "../../components/SelectedClinicItem";

const baseMapLevel = isPlatform("desktop") ? 12 : 13;
const baseMapCenter = {lat: 36.058524445848755, lng: 127.66116398232356}; // 지도 중심
const seoulLocation = {lat: 37.5666805, lng: 126.9784147}; // 서울 시청

const depthLevel1 = 12;
const depthLevel2 = 9;
const depthLevel3 = 6;

const baseMapLevelMyAround = 6;

const FindHospitalPage: React.FC = () => {
	const history = useHistory();
	const location: any = useLocation();
	const hospitalSearchType = location.state?.hospitalSearchType;
	const {processApi} = useApi();

	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [isAlertToast, setIsAlertToast] = useState<boolean>(false);
	const [map, setMap] = useState<kakao.maps.Map>();
	const [selectedClinic, setSelectedClinic] = useRecoilState(selectedClinicState);

	const [clinicAroundList, setClinicAroundList] = useRecoilState(clinicAroundListState);
	const [clinicList, setClinicList] = useRecoilState(clinicListState);
	const [filter, setFilter] = useRecoilState(clinicFilterState);
	const [showRescanButton, setShowRescanButton] = useState(false);

	const setSearchPageType = useSetRecoilState(searchPageTypeState);
	const [clinicSearchType, setClinicSearchType] = useRecoilState(clinicSearchTypeState);

	const [isOpenToast, setIsOpenToast] = useState<boolean>(false);
	const [isOpenClinicModal, setIsOpenClinicModal] = useRecoilState(isOpenClinicModalState);

	const [beforeViewInfoAroundMe, setBeforeViewInfoAroundMe] = useRecoilState(mapViewInfoAroundMe);
	const [beforeViewInfoByMap, setBeforeViewInfoByMap] = useRecoilState(mapViewInfoByMap);

	// 브라우저 위치 hook
	const {location: currentLocation, error: currentError} = useCurrentLocation(geoLocationOptions);

	// 내 주변 찾기
	const fetchDataAroundMe = useCallback(
		async (
			currentLocation: {lat: number; lng: number} | undefined,
			radius: number,
			isSpeciallist: boolean,
		) => {
			try {
				let path = "clinic-all";
				let params: any = {radius: radius};

				if (currentLocation === undefined) {
					params["lat"] = seoulLocation.lat;
					params["lng"] = seoulLocation.lng;
					setIsOpenToast(true);
				} else {
					params["lat"] = currentLocation?.lat ?? 0;
					params["lng"] = currentLocation?.lng ?? 0;
				}

				if (isSpeciallist) {
					params["doctorType"] = "SPECIALIST";
				}

				const data = await processApi<any>(path, params);
				setClinicAroundList(data);
			} catch (error) {
				console.log(error);
			}
		},
		[],
	);

	// 지도로 찾기
	const [lastFetchLevel, setLastFetchLevel] = useRecoilState(lastFetchLevelState);

	const [listByMap, setListByMap] = useState<any[]>([]);
	async function fetchDataByMap(
		currentLevel: number,
		lastFetchLevel: number,
		location: kakao.maps.LatLng | undefined,
		bounds: kakao.maps.LatLngBounds | undefined,
		myLocation: any,
		isSpeciallist: boolean,
	) {
		let zoomLevel = depthLevel1;
		let params: any = {};
		if (location === undefined) return;

		// 14~11(12) > 10~8(9) > 7~1(6)
		if (currentLevel >= 11) {
			zoomLevel = depthLevel1;
			setIsOpenClinicModal(false);
		} else if (currentLevel <= 10 && currentLevel >= 8) {
			zoomLevel = depthLevel2;
			setIsOpenClinicModal(false);
		}
		if (currentLevel <= 7) {
			zoomLevel = depthLevel3;
			setIsOpenClinicModal(true);
		}

		if (lastFetchLevel === zoomLevel) {
			return;
		} else if (zoomLevel === depthLevel1) {
			const data = await processApi<any>("clinic-count", params);
			setListByMap(data);
		} else if (zoomLevel === depthLevel2) {
			var geocoder: any = new kakao.maps.services.Geocoder();

			const func = async (result: any, status: any) => {
				for (var i = 0; i < result.length; i++) {
					if (result[i].region_type === "H") {
						params["sido"] = result[i].region_1depth_name;
						const data = await processApi<any>("clinic-count", params);
						setListByMap(data);
						break;
					}
				}
			};

			geocoder.coord2RegionCode(location.getLng(), location.getLat(), func);
		} else if (zoomLevel === depthLevel3) {
			let leftTop, rightBottom;
			if (bounds) {
				const northEast = bounds.getNorthEast();
				const southWest = bounds.getSouthWest();

				leftTop = new kakao.maps.LatLng(northEast.getLat(), southWest.getLng());
				rightBottom = new kakao.maps.LatLng(southWest.getLat(), northEast.getLng());
			} else {
				leftTop = new kakao.maps.LatLng(0, 0);
				rightBottom = new kakao.maps.LatLng(0, 0);
			}

			params[
				"range"
			] = `${leftTop.getLng()};${leftTop.getLat()};${rightBottom.getLng()};${rightBottom.getLat()}`;

			if (myLocation === undefined) {
				params["lat"] = seoulLocation.lat;
				params["lng"] = seoulLocation.lng;
			} else {
				params["lat"] = myLocation.lat;
				params["lng"] = myLocation.lng;
			}

			if (isSpeciallist) params["doctorType"] = "SPECIALIST";

			const data = await processApi<any>("clinic-all", params);
			setListByMap(data);
			setClinicList(data);
		}

		setLastFetchLevel(zoomLevel);
	}

	useEffect(() => {
		let location;
		if (beforeViewInfoByMap?.lat !== undefined && beforeViewInfoByMap?.lng !== undefined)
			location = new kakao.maps.LatLng(beforeViewInfoByMap?.lat, beforeViewInfoByMap?.lng);
		else location = map?.getCenter();

		fetchDataByMap(
			beforeViewInfoByMap?.level ?? 13,
			lastFetchLevel,
			location,
			map?.getBounds(),
			currentLocation,
			filter.onlySpecilist,
		);
	}, [beforeViewInfoByMap?.level]);

	useEffect(() => {
		if (clinicSearchType === "Around") {
			setIsAlertToast(false);
			setIsOpenClinicModal(true);
		} else {
			fetchDataByMap(
				beforeViewInfoByMap?.level ?? 13,
				lastFetchLevel,
				map?.getCenter(),
				map?.getBounds(),
				currentLocation,
				filter.onlySpecilist,
			);
		}
	}, [clinicSearchType]);

	// 검색 조건 변경 시
	useEffect(() => {
		if (!isLoaded) return;

		if (clinicSearchType === "Around")
			fetchDataAroundMe(currentLocation, filter.radius, filter.onlySpecilist);
		else if (clinicSearchType === "Map")
			fetchDataByMap(
				beforeViewInfoByMap?.level ?? 13,
				0,
				map?.getCenter(),
				map?.getBounds(),
				currentLocation,
				filter.onlySpecilist,
			);
		map?.relayout();
	}, [filter, clinicSearchType, currentLocation]);

	useIonViewDidEnter(() => {
		// 브라우저 지도 위치 사용할 수 없을때 처리
		navigator.geolocation.getCurrentPosition(
			() => {},
			() => {
				fetchDataAroundMe(currentLocation, filter.radius, filter.onlySpecilist);
			},
		);
	});

	useEffect(() => {
		map?.relayout();
	}, [selectedClinic]);

	// 초기화
	useEffect(() => {
		setSearchPageType("clinic");
		if (map !== undefined)
			fetchDataByMap(
				beforeViewInfoByMap?.level ?? 13,
				lastFetchLevel,
				map.getCenter(),
				map.getBounds(),
				currentLocation,
				filter.onlySpecilist,
			);
		setIsLoaded(true);
		setClinicSearchType(clinicSearchType);
		let beforeViewInfo = clinicSearchType === "Around" ? beforeViewInfoAroundMe : beforeViewInfoByMap;
		if (beforeViewInfo !== undefined) {
			const beforeLevel = beforeViewInfo.level;
			const beforeLat = beforeViewInfo.lat;
			const beforeLng = beforeViewInfo.lng;
			const clinicItem = beforeViewInfo.clinicItem;

			if (clinicItem) setSelectedClinic(clinicItem);
		}

		// 가까운 피부과 추천
		if (hospitalSearchType) {
			setClinicSearchType("Around");
		}
	}, []);

	const updateViewInfo = (map: kakao.maps.Map) => {
		let setter = clinicSearchType === "Around" ? setBeforeViewInfoAroundMe : setBeforeViewInfoByMap;
		setter(prev => {
			return {
				level: map.getLevel(),
				lat: map.getCenter().getLat(),
				lng: map.getCenter().getLng(),
				clinicItem: prev?.clinicItem,
			};
		});
	};

	return (
		<IonPage className="pg-find-clinic">
			<HeaderBase title="병원 찾기" backHref="" enableSearch={true}></HeaderBase>
			<IonSegment
				scrollable
				value={clinicSearchType}
				mode="md"
				onIonChange={event => {
					setClinicSearchType(event.detail.value as ClinicSearchType);
				}}
			>
				<IonSegmentButton value="Map">지도로 찾기</IonSegmentButton>
				<IonSegmentButton value="Around">내 주변 찾기</IonSegmentButton>
			</IonSegment>

			<Map
				className={`map_wrap`}
				id="map"
				style={{
					width: "100%",
					height: "100%",
				}}
				center={{
					lat: 0,
					lng: 0,
				}}
				zoomable={clinicSearchType !== "Around"}
				onZoomChanged={updateViewInfo}
				onCenterChanged={updateViewInfo}
				onDragEnd={map => {
					if (clinicSearchType === "Map") {
						setShowRescanButton(true);
						fetchDataByMap(
							map.getLevel(),
							0,
							map.getCenter(),
							map.getBounds(),
							currentLocation,
							filter.onlySpecilist,
						);
					}
				}}
				onCreate={target => {
					let location: kakao.maps.LatLng;
					let level: number;
					let clinicItem: any = undefined;

					if (map === undefined) {
						let beforeViewInfo = clinicSearchType === "Around" ? beforeViewInfoAroundMe : beforeViewInfoByMap;

						if (beforeViewInfo !== undefined) {
							location = new kakao.maps.LatLng(beforeViewInfo.lat, beforeViewInfo.lng);
							level = beforeViewInfo.level;
							clinicItem = beforeViewInfo.clinicItem;
						} else if (clinicSearchType === "Around") {
							// 내 주변 기본 설정
							level = baseMapLevelMyAround;
							if (currentLocation !== undefined) {
								location = new kakao.maps.LatLng(currentLocation.lat, currentLocation.lng); // 현 위치
							} else {
								location = new kakao.maps.LatLng(seoulLocation.lat, seoulLocation.lng); // 현재 위치 없으면 서울시청
							}
						} else {
							// 지도로 찾기 기본 설정
							level = 13;
							location = new kakao.maps.LatLng(baseMapCenter.lat, baseMapCenter.lng);
						}

						target.setCenter(location);
						target.setLevel(level);

						const currentSetter =
							clinicSearchType === "Around" ? setBeforeViewInfoAroundMe : setBeforeViewInfoByMap;
						currentSetter({
							level: target.getLevel(),
							lat: target.getCenter().getLat(),
							lng: target.getCenter().getLng(),
							clinicItem: clinicItem,
						});

						setMap(target);
					} else {
						let beforeViewInfo = clinicSearchType === "Around" ? beforeViewInfoAroundMe : beforeViewInfoByMap;
						if (beforeViewInfo === undefined) {
							if (clinicSearchType === "Around") {
								target.setLevel(baseMapLevelMyAround);
								//setSelectedClinic(undefined);

								if (currentLocation !== undefined) {
									target.setCenter(new kakao.maps.LatLng(currentLocation.lat, currentLocation.lng));
								} else {
									target.setCenter(new kakao.maps.LatLng(seoulLocation.lat, seoulLocation.lng));
								}
								setBeforeViewInfoAroundMe({
									level: map.getLevel(),
									lat: map.getCenter().getLat(),
									lng: map.getCenter().getLng(),
									clinicItem: selectedClinic,
								});
							} else {
								target.setLevel(13);
								//setSelectedClinic(undefined);
								target.setCenter(new kakao.maps.LatLng(baseMapCenter.lat, baseMapCenter.lng));
								setBeforeViewInfoByMap({
									level: map.getLevel(),
									lat: map.getCenter().getLat(),
									lng: map.getCenter().getLng(),
									clinicItem: selectedClinic,
								});
							}
						} else {
							const beforeLevel = beforeViewInfo.level;
							const beforeLat = beforeViewInfo.lat;
							const beforeLng = beforeViewInfo.lng;
							const clinicItem = beforeViewInfo.clinicItem;

							target.setLevel(beforeLevel);
							target.setCenter(new kakao.maps.LatLng(beforeLat, beforeLng));

							// console.log("setSelectedClinic1", clinicItem?.name);
							// if (clinicItem) setSelectedClinic(clinicItem);
						}
					}

					target.relayout();
				}}
				onClick={() => {
					let setter = clinicSearchType === "Around" ? setBeforeViewInfoAroundMe : setBeforeViewInfoByMap;

					if (map !== undefined) {
						setter({
							level: map.getLevel(),
							lat: map.getCenter().getLat(),
							lng: map.getCenter().getLng(),
							clinicItem: undefined,
						});
					}
					setSelectedClinic(undefined);
				}}
			>
				{/* 지도로 찾기 */}
				{clinicSearchType === "Map" && lastFetchLevel > depthLevel3 ? (
					listByMap.map((item, index) => {
						if (item !== undefined) {
							return (
								<CustomOverlayMap
									key={`${item.lat}-${item.lng}-${index}`}
									position={{lat: item.lat, lng: item.lng}}
								>
									<div
										className="map-marker"
										onClick={() => {
											const level = map?.getLevel() ?? depthLevel1;
											let nextZoomLevel = depthLevel1;

											if (level >= 11) {
												nextZoomLevel = depthLevel2;
											} else if (level <= 10 && level >= 8) {
												nextZoomLevel = depthLevel3;
											} else {
												return;
											}

											map?.setLevel(nextZoomLevel);
											map?.setCenter(new kakao.maps.LatLng(item.lat, item.lng));
										}}
									>
										<div className="sub-title">{`${item.count}`}</div>
										<div className="title">{`${item.sgg ? item.sgg : item.sido}`}</div>
									</div>
								</CustomOverlayMap>
							);
						}
						return null;
					})
				) : (
					<></>
				)}

				{/* 병원 마커 */}
				{(clinicSearchType === "Around"
					? clinicAroundList
					: clinicSearchType === "Map" && lastFetchLevel === depthLevel3
					? clinicList
					: []
				)?.map((item, index) => (
					<MapMarker
						key={item.id}
						position={{
							lat: item.lat ?? 0,
							lng: item.lng ?? 0,
						}}
						zIndex={selectedClinic?.id === item.id ? 9999 : index}
						// 마커 이미지
						image={{
							src:
								selectedClinic?.id === item.id
									? "https://t1.daumcdn.net/localimg/localimages/07/2018/mw/m640/marker_place.png"
									: "https://t1.daumcdn.net/localimg/localimages/07/2018/mw/m640/marker_place_off.png",
							size: {
								width: 31,
								height: 44,
							},
						}}
						// 마커 클릭 이벤트
						onClick={marker => {
							setSelectedClinic(item);

							let setter = clinicSearchType === "Around" ? setBeforeViewInfoAroundMe : setBeforeViewInfoByMap;

							if (map !== undefined) {
								setter({
									level: map.getLevel(),
									lat: map.getCenter().getLat(),
									lng: map.getCenter().getLng(),
									clinicItem: item,
								});
							}

							// 마커 클릭했을때 레벨 조정
							const level = map?.getLevel() ?? baseMapLevel;
							if (level > 7) {
								map?.setLevel(7);
							}
							// 마커 중앙으로 지도 설정
							map?.setCenter(
								new kakao.maps.LatLng(marker.getPosition().getLat(), marker.getPosition().getLng()),
							);
						}}
					/>
				))}

				{/* 내 위치 마커 */}
				<MapMarker
					position={{
						lat: currentLocation?.lat ?? seoulLocation.lat,
						lng: currentLocation?.lng ?? seoulLocation.lng,
					}}
					image={{
						src: "https://t1.daumcdn.net/localimg/localimages/07/2018/mw/m640/ico_marker.png",
						size: {width: 30, height: 30},
					}}
				></MapMarker>
			</Map>

			{/* 선택한 병원 정보 */}
			{selectedClinic ? (
				<SelectedClinicItem
					key={`clinic_${selectedClinic.id}`}
					clinic={selectedClinic}
					setSelectedClinic={setSelectedClinic}
				/>
			) : (
				<></>
			)}

			{/* 여기서 재검색 => 보류(사용하지 않음) */}
			{showRescanButton && false ? (
				<IonItem
					className="rescan"
					style={clinicSearchType === "Around" ? {display: "none"} : {display: "block"}}
					onClick={() => {
						setShowRescanButton(false);
						if (map !== undefined)
							fetchDataByMap(
								map.getLevel(),
								0,
								map.getCenter(),
								map.getBounds(),
								currentLocation,
								filter.onlySpecilist,
							);
					}}
				>
					<IonIcon icon={refreshOutline} className="icon"></IonIcon>
					<IonLabel>여기서 재검색</IonLabel>
				</IonItem>
			) : (
				<></>
			)}

			{/* 현 위치 */}
			{clinicSearchType === "Around" ? (
				<div
					className="current-location"
					onClick={() => {
						setIsAlertToast(false);
						if (currentLocation === undefined) {
							setIsOpenToast(true);
							map?.setCenter(new kakao.maps.LatLng(seoulLocation.lat, seoulLocation.lng));
						} else {
							map?.setCenter(new kakao.maps.LatLng(currentLocation.lat, currentLocation.lng));
						}
					}}
				>
					<img src="assets/icon/icons_my_location.png" alt="my location" className="icon" />
				</div>
			) : (
				<></>
			)}

			<IonToast
				isOpen={
					window.location.pathname === "/t/hospital" &&
					clinicSearchType === "Around" &&
					isOpenToast &&
					!isAlertToast
				}
				onDidDismiss={() => {
					setIsOpenToast(false);
					setIsAlertToast(true);
				}}
				message="내 위치 정보를 확인할 수 없습니다."
				position="bottom"
				duration={600}
			/>
		</IonPage>
	);
};

export default FindHospitalPage;
