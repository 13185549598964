import {IonPage} from "@ionic/react";
import {HeaderBase} from "../../../components";
import {ARCam} from "../../../components/presentational";

const ARresultPage: React.FC = () => {
	return (
		<IonPage>
			<HeaderBase title="AR" backMode />
			<ARCam />
		</IonPage>
	);
};

export default ARresultPage;
