import {
	IonButton,
	IonCard,
	IonHeader,
	IonItem,
	IonLabel,
	IonList,
	IonModal,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonSlide,
	IonSlides,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import shortid from "shortid";
import {Maybe} from "../..";
import {bodyInfo, bodyTitle, faceInfo, faceTitle} from "../../../pages/skinAnalysis/config";
import "./Modal.scss";

interface ModalProps {
	title?: string;
	isClose?: any;
	sliderContents?: any[];
	header?: boolean;
	closeMode?: boolean;
	slideMode?: boolean;
	btnText?: string;
	isOpen?: boolean;
	info?: boolean;
	img?: boolean;
	btn?: boolean;
	select?: boolean;
	handleClickFace?: () => void;
	handleClickBody?: () => void;
	selectBtnText?: string;
	selectComplete?: any;
	subTitle?: string;
	subInfo?: string;
	className?: string;
	onClickTakePhoto?: any;
	isCloseComplete?: () => void;
	btnClassName?: string;
}

const Modal: React.FC<ModalProps> = ({
	title,
	isClose,
	sliderContents,
	header,
	closeMode,
	slideMode,
	btnText,
	isOpen,
	info,
	img,
	btn,
	select,
	handleClickFace,
	handleClickBody,
	selectBtnText,
	selectComplete,
	subTitle,
	subInfo,
	className,
	onClickTakePhoto,
	isCloseComplete,
	btnClassName,
}) => {
	const option = {
		slidesPerView: 1,
		centeredSlides: true,
		loop: true,
	};

	return (
		<IonModal isOpen={isOpen} className={`modal ${className}`} onDidDismiss={isClose} backdropDismiss>
			<IonPage>
				<div>
					<Maybe test={header}>
						<IonHeader translucent>
							<IonToolbar>{title && <IonTitle>{title}</IonTitle>}</IonToolbar>
						</IonHeader>
					</Maybe>

					<Maybe test={closeMode}>
						<button onClick={isCloseComplete} className="btn-close">
							<img src="assets/icon/icons_close_black.png" alt="close" className="icon" />
						</button>
					</Maybe>

					<Maybe test={info}>
						<div className="modal-contents">
							<div className="info">
								<h2>{subTitle}</h2>
								<p>{subInfo}</p>
							</div>
							{img && (
								<div className="img">
									{/** 임시 이미지 */}
									<img src="https://via.placeholder.com/320x280" alt="" />
								</div>
							)}
							{btn && (
								<div className="btn">
									<IonButton onClick={onClickTakePhoto}>{btnText}</IonButton>
								</div>
							)}

							{/** 얼굴/신체 선택 */}
							{select && (
								<div className="select">
									<IonList>
										<IonRadioGroup>
											<IonItem className="select-item">
												<IonLabel>
													<div className="img">
														<div className="face" />
													</div>
													<div className="text">
														<h2>{faceTitle}</h2>
														<h3>{faceInfo}</h3>
													</div>
													<div className="radio" onClick={handleClickFace}>
														<IonRadio slot="end" value="face" />
													</div>
												</IonLabel>
											</IonItem>
											<IonItem className="select-item">
												<IonLabel>
													<div className="img">
														<div className="body" />
													</div>
													<div className="text">
														<h2>{bodyTitle}</h2>
														<h3>{bodyInfo}</h3>
													</div>
													<div className="radio" onClick={handleClickBody}>
														<IonRadio value="body" />
													</div>
												</IonLabel>
											</IonItem>
										</IonRadioGroup>
									</IonList>
									<div className="btn">
										<IonButton onClick={selectComplete} className={btnClassName}>
											{selectBtnText}
										</IonButton>
									</div>
								</div>
							)}
						</div>
					</Maybe>

					{/** slide */}
					<Maybe test={slideMode}>
						<IonSlides pager={true} options={option}>
							{sliderContents?.map(item => (
								<IonSlide key={item.id}>
									<IonCard>
										<div className="modal-img">
											<img src={item.img} alt="" />
										</div>
										<div className="modal-info">
											{item.info.split("\n").map((text: any) => (
												<p key={shortid.generate()}>
													{text}
													<br />
												</p>
											))}
										</div>
									</IonCard>
								</IonSlide>
							))}
						</IonSlides>
					</Maybe>
				</div>
			</IonPage>
		</IonModal>
	);
};

export default Modal;
