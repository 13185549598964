/* eslint-disable array-callback-return */
import {useRef} from "react";
import "./FileAttacher.scss";

type FileAttacherProps = {
	files?: any;
	attachedFiles?: Array<any>;
	meta: {
		fileCount?: any;
		fileSize?: any;
		accept?: string;
		acceptCaption?: string;
	};
	onChangeFile: (file: any) => void;
};

const FileAttacher: React.FC<FileAttacherProps> = ({
	files,
	attachedFiles,
	meta,
	onChangeFile,
}) => {
	const fileRef = useRef<any>();

	const handleAttach = () => {
		fileRef.current.click();
	};

	const handleChangeFile = (e: any) => {
		const reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);

		reader.onload = () => {
			onChangeFile(reader.result);
		};
	};

	return (
		<div className="file-upload">
			<input type="file" accept={meta.accept} ref={fileRef} onChange={handleChangeFile} hidden />

			<div className="file-upload-area">
				<button className="button" onClick={handleAttach}>
					사진 업로드
				</button>
			</div>
		</div>
	);
};

export default FileAttacher;
