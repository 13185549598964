/* eslint-disable react-hooks/exhaustive-deps */
import {IonCol, IonContent, IonFooter, IonGrid, IonItem, IonPage, IonRow} from "@ionic/react";
import {useCallback, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {HeaderBase} from "../../../components";
import {Progress} from "../../../components/presentational";
import {useApi} from "../../../hooks/api";
import {useAuth} from "../../../hooks/auth";
import {BannerType} from "../../../store/types";
import {infoText} from "../../configs";

const QuestionResultPage: React.FC<RouteComponentProps> = ({history}) => {
	const {analysisProcessApi, processApi} = useApi();
	const {user} = useAuth();

	const keyValue = localStorage.getItem("analysisKey");
	const [data, setData] = useState<any>();
	const [loading, setLoading] = useState(false);
	const [percentage, setPercentage] = useState(0);
	const [adLoading, setAdLoading] = useState(false);

	const [adBannerList, setAdBannerList] = useState<BannerType[] | any>();
	const [bannerAdType] = useState<number>(2);
	const [bannerSectionType] = useState<number>(1);
	const [progressTime, setProgressTime] = useState<number>();
	const [resultViewTime, setResultViewTime] = useState<number>(100);
	const [adTime, setAdTime] = useState<any>();
	const [adLoadingComplete, setAdLoadingComplete] = useState<boolean>(false);

	const setSpeedForSlide = (num: any) => {
		return num * 1000;
	};

	const adBannerSliderOPtion = {
		dots: false,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		centerMode: true,
		centerPadding: "0",
		pauseOnHover: false,
		initialSlide: 0,
		autoplay: true,
		autoplaySpeed: adTime,
		speed: 1000,
		beforeChange: function (slide: any, currentSlide: any) {
			setAdTime(adBannerList[currentSlide].minimumTime * 1000);

			if (adBannerList.length === 1) {
				if (adBannerList[0].minimumTime > 15) {
					setProgressTime(adBannerList[0].minimumTime * 1000);
					setResultViewTime(adBannerList[0].minimumTime * 10);
				} else {
					setProgressTime(15000);
				}
			} else if (adBannerList.length > 1) {
				if (adBannerList[1].minimumTime > 15) {
					setProgressTime(adBannerList[1].minimumTime * 1000);
					setResultViewTime(adBannerList[1].minimumTime * 10);
				} else {
					setProgressTime(15000);
				}
			}
		},
		afterChange: function (currentSlide: any) {
			setAdTime(setSpeedForSlide(adBannerList[currentSlide].minimumTime));
		},
	};

	const getData = useCallback(async () => {
		try {
			const {data} = await analysisProcessApi<any>("analysis-result", {
				analysisKey: keyValue,
			});

			setData(data.resultPayload.moonjinSubmitLabelPageDtos);
		} catch (error) {
			console.log(error);
		}
	}, []);

	const getdBannerList = async () => {
		let params = {
			adType: bannerAdType,
			sectionType: bannerSectionType,
		};

		try {
			const data = await processApi<any>("ad", params);
			setAdBannerList(data.detailList);
		} catch (e) {
			console.log(e);
		}
	};

	const handleClickReset = async () => {
		await analysisProcessApi<any>("reset", {
			analysisKey: "?analysisKey=" + encodeURIComponent(String(keyValue)),
		});
		history.push("/skin-analysis/question");
	};

	const handleClickConfirm = useCallback(() => {
		if (adBannerList) {
			setAdLoading(true);
		} else {
			setLoading(true);
		}
	}, [adBannerList]);

	const handleMoveToResult = () => {
		setTimeout(() => {
			setAdLoading(false);
			history.push("/skin-analysis/result");
		}, 500);
	};

	useEffect(() => {
		getData();
		getdBannerList();
	}, []);

	useEffect(() => {
		if (adLoading && resultViewTime) {
			setTimeout(() => {
				if (percentage < 100) {
					setPercentage(percentage + 1);
				}
			}, resultViewTime);
		}
	}, [adLoading, percentage]);

	useEffect(() => {
		if (adLoading && progressTime) {
			setTimeout(() => {
				setAdLoadingComplete(true);
			}, progressTime);
		}
	}, [adLoading, percentage, progressTime]);

	useEffect(() => {
		if (loading) {
			setTimeout(() => {
				if (percentage < 100) {
					setPercentage(percentage + 1);
				}
			}, 10);
		}
	}, [loading, percentage]);

	useEffect(() => {
		if (data && loading) {
			setTimeout(() => {
				setLoading(false);
				history.push("/skin-analysis/result");
			}, 3000);
		}
	}, [loading, data]);

	return (
		<IonPage>
			<HeaderBase title="내 피부 문진 최종 요약" />
			<IonContent>
				<div className="content-inner question-contents__summary">
					<div className="contents">
						<div className="info">
							<p>아래 내 피부 문진 분석 체크한 내용이 정확한지 다시 한번 확인해 주세요.</p>
						</div>
						<IonGrid>
							{data?.map((item: any, index: any) => (
								<IonRow className="question-contents__summary--list" key={index}>
									<IonCol>
										<IonItem>
											<div className="summary-text">
												<div className="summary-text__title">
													<p className="question">{item.subTitle}</p>
												</div>
												<div className="summary-text__info">
													{item.moonjinSubmitLabelDetailDtoList?.map((detail: any, index: any) => (
														<p className="answer" key={index}>
															{detail.sumNote}
														</p>
													))}
												</div>
											</div>
										</IonItem>
									</IonCol>
								</IonRow>
							))}
						</IonGrid>
					</div>
				</div>
			</IonContent>

			<IonFooter className="content-footer">
				<div className="btn-bottom__double btn-wrap">
					<IonRow className="ion-justify-content-between">
						<button className="btn-restart" onClick={handleClickReset}>
							다시 하기
						</button>
						<button className="btn-confirm" onClick={handleClickConfirm}>
							확인
						</button>
					</IonRow>
				</div>
			</IonFooter>

			{loading && (
				<Progress
					title={user?.realName}
					subTitle={infoText.subTitle}
					info={infoText.info}
					percentage={percentage}
				/>
			)}

			{adLoading && (
				<div className="progress-modal">
					<div className="ad">
						<i className="icon-ad" />
					</div>
					<div className="ad-contents-wrap">
						<div className="ad-contents-inner">
							<div className="ad-contents">
								<Slider {...adBannerSliderOPtion}>
									{adBannerList?.map((item: any, index: any) => (
										<div key={index}>
											{item.fileType === "movie" ? (
												<div className="ad-video">
													<video
														autoPlay
														muted
														playsInline
														loop
														width="100%"
														height="100%"
														data-keepplaying={false}
													>
														<source src={item.fileUrl} />
													</video>
													{/* <iframe
													src={`${item.fileUrl}?controls=0${
														adVideoAutoPlay ? "?autoplay=1&mute=1&loop=1" : ""
													}`}
													width="100%"
													height="100%"
													title="ad"
													allow={`fullscreen;${adVideoAutoPlay ? "autoplay;" : ""}`}
													frameBorder="0"
												/> */}
												</div>
											) : (
												<img
													src={item.fileUrl}
													onClick={() => (item.targetUrl ? window.open(item.targetUrl) : undefined)}
													alt="ad"
												/>
											)}
										</div>
									))}
								</Slider>
							</div>
						</div>

						<Progress
							title={user?.realName}
							subTitle={infoText.subTitle}
							info={infoText.info}
							percentage={percentage}
							adSlideList={adBannerList}
							adMode
							progressComplete={adLoadingComplete}
							handleClickResult={handleMoveToResult}
						/>
					</div>
				</div>
			)}
		</IonPage>
	);
};

export default QuestionResultPage;
