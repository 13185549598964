/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import {useCallback, useEffect, useState} from "react";
import {useHistory, useParams, withRouter} from "react-router";
import {IonButton, IonFooter} from "@ionic/react";
import {useApi} from "../../../../hooks/api";
import {Type1, Type2, Type3, Type997, Type4, Type5, Type6, Type7} from "./list";
import {SwitchCase} from "../../../../components";

const DetailTypePage: React.FC = () => {
	const params: any = useParams();
	const history = useHistory();
	const analysisPartValue = localStorage.getItem("analysisPartValue");

	const {analysisProcessApi} = useApi();

	const [pageId, setPageId] = useState<any>(Number(params.id));
	const [nextPageId, setNextPageId] = useState<number>();
	const [questionNum, setQuestionNum] = useState<number>(1);

	const [data, setData] = useState<any>();
	const [updateIdData, setUpdateIdData] = useState<any>();
	const [isLastPage, setIsLastPage] = useState<boolean>(false);
	const [isSelected, setIsSelected] = useState<boolean>(false);
	const [type, setType] = useState<string>();

	const getData = useCallback(async (id: any) => {
		try {
			const {data} = await analysisProcessApi<any>("detail-item", {
				id: id === 0 ? "0" : id,
				analysisKey: localStorage.getItem("analysisKey"),
			});

			setData(data);
			setType(data.moonjinLabelPageDto.type);
		} catch (error) {
			console.log(error);
		}
	}, []);

	const updateData = async () => {
		if (isSelected && pageId !== 16) {
			setQuestionNum(questionNum + 1);
		}

		if (updateIdData && isSelected) {
			try {
				const {data} = await analysisProcessApi<any>("detail-item-put", {
					id: pageId,
					analysisKey: localStorage.getItem("analysisKey"),
					detailIdList: updateIdData,
				});

				if (data) {
					setPageId(data.nextPageId);
					setNextPageId(data.nextPageId);

					if (data.nextPageId !== 0) {
						getData(data.nextPageId);
					}

					if (pageId === 15) {
						setIsLastPage(true);
					} else {
						setIsLastPage(false);
					}
				}
			} catch (e) {
				console.log(e);
			}
		}
	};

	const handleClickNextPage = () => {
		updateData();

		if (pageId && !isLastPage && isSelected) {
			history.push(`/skin-analysis/question/detail/list/${pageId}`);
		} else if (isLastPage && isSelected) {
			history.push("/skin-analysis/question/result");
		}
	};

	const handleClickPrevPage = () => {
		setIsLastPage(false);

		if (params.id === 0 || params.id === "0") {
			setPageId("0");
			getData("0");
		} else if (pageId === 11) {
			setPageId(9);
			getData(9);
		} else {
			setPageId(pageId - 1);
			getData(pageId - 1);

			history.goBack();
		}

		setQuestionNum(questionNum - 1);
	};

	useEffect(() => {
		if (pageId === 0) {
			setPageId("0");
			getData("0");
		} else {
			getData(pageId);
		}
	}, []);

	useEffect(() => {
		setUpdateIdData(updateIdData);
	}, [updateIdData]);

	const renderPage = () => {
		return (
			<>
				{type === "1" && (
					<Type1 id={questionNum} data={data} updateIdData={setUpdateIdData} isSelected={setIsSelected} />
				)}
				{type === "2" && (
					<Type2 id={questionNum} data={data} updateIdData={setUpdateIdData} isSelected={setIsSelected} />
				)}
				{type === "3" && (
					<Type3 id={questionNum} data={data} updateIdData={setUpdateIdData} isSelected={setIsSelected} />
				)}
				{type === "4" && (
					<Type4 id={questionNum} data={data} updateIdData={setUpdateIdData} isSelected={setIsSelected} />
				)}
				{type === "5" && (
					<Type5 id={questionNum} data={data} updateIdData={setUpdateIdData} isSelected={setIsSelected} />
				)}
				{type === "6" && (
					<Type6 id={questionNum} data={data} updateIdData={setUpdateIdData} isSelected={setIsSelected} />
				)}
				{type === "7" && (
					<Type7 id={questionNum} data={data} updateIdData={setUpdateIdData} isSelected={setIsSelected} />
				)}
				{type === "997" && (
					<Type997 id={questionNum} data={data} updateIdData={setUpdateIdData} isSelected={setIsSelected} />
				)}
			</>
		);
	};

	return (
		<>
			{renderPage()}

			<IonFooter className="floating-menu">
				<div
					className={`btn-wrap ${
						(analysisPartValue === "body" && pageId === "0") || (analysisPartValue === "face" && pageId === 1)
							? "align-right"
							: ""
					}`}
				>
					{((analysisPartValue === "body" && pageId !== "0") ||
						(analysisPartValue === "face" && pageId !== 1)) && (
						<IonButton onClick={handleClickPrevPage} className="btn btn-prev">
							<i className="icon-prev" />
						</IonButton>
					)}
					<SwitchCase
						tests={[
							{
								test: isLastPage,
								component: (
									<IonButton
										onClick={handleClickNextPage}
										className={`btn btn-complete ${isSelected ? "selected" : ""}`}
									>
										완료
									</IonButton>
								),
							},
							{
								test: !isLastPage,
								component: (
									<IonButton
										className={`btn btn-next${isSelected ? " btn-next-active" : ""}`}
										onClick={handleClickNextPage}
									>
										<i className="icon-next" />
									</IonButton>
								),
							},
						]}
					/>
				</div>
			</IonFooter>
		</>
	);
};

export default withRouter(DetailTypePage);
