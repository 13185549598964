/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from "react";
import {
	IonPage,
	IonContent,
	IonGrid,
	IonCol,
	IonRow,
	IonButton,
	useIonToast,
	useIonAlert,
} from "@ionic/react";

import HeaderBase from "../../components/HeaderBase";
import {useHistory} from "react-router";
import useMessage from "../../hooks/message";
import {detect} from "detect-browser";

const BASE_URL = process.env.REACT_APP_API_URL;

interface VerificationProps {
	action: string;
	token?: string;
	onNext: (data: {}) => void;
	onError: () => void;
	headerTitle?: string;
	backHref?: string;
	content?: string;
}

const Verification: React.FC<VerificationProps> = ({
	action,
	token,
	onNext,
	onError,
	headerTitle = "본인인증",
	backHref = "/",
	content,
}) => {
	const history = useHistory();
	const [present] = useIonToast();
	const [showAlert] = useIonAlert();

	let temp = content || "SDOC 서비스 이용을 위해\n본인 인증이 필요합니다.";
	const contentList = temp.split("\n");

	const openCert = useCallback(() => {
		let url = `${BASE_URL}/cert?action=${action}`;
		if (token) {
			url = `${url}&token=${token}`;
		}

		const browser = detect();
		const width = 410;
		const height = 500;
		let leftpos = window.screen.width / 2 - width / 2;
		let toppos = window.screen.height / 2 - height / 2;
		const popup = window.open(
			url,
			"auth_popup",
			`width=${width}, height=${height}, toolbar=no,status=no,statusbar=no,menubar=no,scrollbars=no,resizable=no,left=${leftpos}, top=${toppos}`,
		);
		popup?.focus();
		// if(browser?.os !== 'Android OS' && browser?.os !== 'iOS') {
		// 	const width  = 410;
		// 	const height = 500;
		// 	let leftpos = window.screen.width  / 2 - ( width  / 2 );
		// 	let toppos  = window.screen.height / 2 - ( height / 2 );
		// 	const popup = window.open(url, "auth_popup",  `width=${width}, height=${height}, toolbar=no,status=no,statusbar=no,menubar=no,scrollbars=no,resizable=no,left=${leftpos}, top=${toppos}`);
		// 	popup?.focus();
		// } else {
		// 	const popup = window.open(url, "_blank");
		// 	popup?.focus();
		// }
	}, []);

	const handleAlreadySignUp = (route: string) => {
		const message = `${route}로 가입된 내역이 있습니다.`;
		switch (action) {
			case "social-login":
			case "reg":
				showAlert({
					message,
					buttons: [{text: "확인", cssClass: "primary", handler: d => history.replace("/login")}],
					cssClass: "alert-css2",
				});
				break;
			case "find-email":
			case "find-password":
				if (route !== "EMAIL") {
					showAlert({
						message,
						buttons: [{text: "확인", cssClass: "primary", handler: d => history.replace("/login")}],
						cssClass: "alert-css2",
					});
				}
				break;
		}
	};

	useMessage("kcp-cert", (send: any, payload: any) => {
		if (payload.res_cd === "0000") {
			if (payload.errorRoute) {
				handleAlreadySignUp(payload.errorRoute);
			} else {
				let data = {...payload};
				delete data.res_cd;
				delete data.res_msg;
				onNext(data);
			}
		} else {
			const msg = payload.res_msg || "본인인증 처리중에 문제가 발생하였습니다. 잠시 후 다시 확인해주세요.";
			present(msg, 3000);
			onError();
		}
	});

	return (
		<IonPage className="pg-vrf">
			<HeaderBase title={headerTitle} backHref={backHref} enableSearch={false} />
			<IonContent fullscreen>
				<div className="content-inner">
					<IonGrid className="ion-padding">
						<IonRow>
							<IonCol>
								<div className="content-main">본인 인증을 해주세요</div>
								<div className="content-sub">
									{contentList.map((line, i) => {
										return (
											<span key={i}>
												{line}
												<br />
											</span>
										);
									})}
								</div>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonButton className="" expand="full" onClick={openCert} mode="md" shape="round">
									인증하기
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Verification;
