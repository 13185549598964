/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback} from "react";
import {IonPage, IonContent} from "@ionic/react";
import {useRecoilState} from "recoil";
import {termsSingleState} from "../../store/atoms";
import HeaderBase from "../../components/HeaderBase";
import {useApi} from "../../hooks/api";
import {useParams} from "react-router";
import shortid from "shortid";

const ViewTermsDetail: React.FC = () => {
	const params: any = useParams();
	const {processApi} = useApi();

	const [terms, setTerms] = useRecoilState(termsSingleState);

	const fetchData = useCallback(async () => {
		const data = await processApi<any>("terms-item", {id: params.id});
		setTerms(data);
	}, []);

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<IonPage className="pg-view-terms-detail">
			<HeaderBase title={terms?.title ?? ""} backHref="/"></HeaderBase>
			<IonContent fullscreen>
				<div className="content-inner">
					{terms?.content?.split("\n").map(item => {
						return (
							<p className="ion-padding" key={shortid.generate()}>
								{item}
								<br />
							</p>
						);
					})}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ViewTermsDetail;
