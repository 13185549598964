import {IonActionSheet, IonItem, IonLabel, IonSelect, IonSelectOption} from "@ionic/react";
import shortid from "shortid";
import styled from "styled-components";

const SelectOptionContainer = styled.div`
	display: flex;
	align-items: center;
	padding-right: 1rem;
`;

interface SelectOptionProps {
	showActionSheet?: any;
	hideActionSheet?: any;
	value?: any;
	isShow?: any;
	optionValue?: any;
	setOrderValue?: any;
}

const SelectOption: React.FC<SelectOptionProps> = ({
	showActionSheet,
	hideActionSheet,
	value,
	isShow,
	optionValue,
	setOrderValue,
}) => {
	return (
		<SelectOptionContainer>
			{value.map((item: any) => (
				<IonItem onClick={showActionSheet}>
					<IonLabel>{item.title}</IonLabel>
					<img slot="end" src="assets/icon/icons_arrow_down.png" alt="order" className="icon" />
				</IonItem>
			))}

			<IonActionSheet
				isOpen={isShow}
				onDidDismiss={hideActionSheet}
				buttons={optionValue.map((item: any) => [
					{
						text: `${item.value}`,
						handler: () => {
							setOrderValue(item.value);
						},
					},
				])}
			/>
			{/* <IonSelect
				interface="action-sheet"
				value={defaultValue}
				onIonChange={onchange}
				placeholder={placeholder}
			>
				{value.map((item: any) => (
					<div key={shortid.generate()}>
						<IonLabel>
							<IonSelectOption value={item.value}>
								{item.title}
								<img slot="end" src="assets/icon/icons_arrow_down.png" alt="order" className="icon" />
							</IonSelectOption>
						</IonLabel>
					</div>
				))}
			</IonSelect> */}
		</SelectOptionContainer>
	);
};

export default SelectOption;
