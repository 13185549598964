/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useCallback, useEffect} from "react";
import {Redirect, Route, useLocation, useHistory, Switch} from "react-router-dom";

import {
	IonRouterOutlet,
	IonLabel,
	IonTabBar,
	IonTabButton,
	IonTabs,
	IonPopover,
	IonList,
	IonItem,
	useIonAlert,
} from "@ionic/react";

import {useAuth} from "../hooks/auth";
import AuthRoute from "../components/presentational/AuthRoute";

import SkinInfo from "./skinInfo/SkinInfoPage";
import SkinInfoDetail from "./skinInfo/SkinInfoDetailPage";
import FindClinic from "./hospital/FindHospitalPage";
import FindClinicDetail from "./hospital/FindHospitalDetailPage";
import SkinVideo from "./skinVideo/SkinVideoPage";
import SkinVideoDetail from "./skinVideo/SkinVideoDetailPage";
import Login from "./login/Login";
import SignUpComplete from "./login/SignUpComplete";
import MyPage from "./myPage/MyPage";
import MyInfo from "./myPage/MyInfo";
import MyInfoNicknm from "./myPage/MyInfoNicknm";
import MyInfoPW from "./myPage/MyInfoPW";
import MyInfoPhone from "./myPage/MyInfoPhone";
import MyAccountClose from "./myPage/MyAccountClose";
import Search from "./common/Search";
import Home from "./Home";

import SkinAnalysis from "./skinAnalysis/SkinAnalysisPage";
import RegAsSocial from "./login/RegAsSocial";
import RegAsEmail from "./login/RegAsEmail";
import EmailLogin from "./login/EmailLogin";
import FindEmail from "./login/FindEmail";
import FindPassword from "./login/FindPassword";
import ResetPassword from "./login/ResetPassword";
import Bookmark from "./myPage/Bookmark";
import Notice from "./myPage/Notice";
import FrequentlyAskedQuestion from "./myPage/FrequentlyAskedQuestion";
import ViewTerms from "./myPage/ViewTerms";
import ViewTermsDetail from "./myPage/ViewTermsDetail";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {clinicSearchTypeState, isOpenClinicModalState, mapViewInfoByMap} from "../store/atoms";
import ClinicListModal from "../components/presentational/ClinicListModal";
import FaceUploadPage from "./skinAnalysis/upload/face/UploadPage";
import BodyUploadPage from "./skinAnalysis/upload/body/UploadPage";
import QuestionPage from "./skinAnalysis/question/QuestionPage";
import QuestionDetailPage from "./skinAnalysis/question/QuestionDetailPage";
import QuestionResultPage from "./skinAnalysis/question/QuestionResultPage";
import ResultPage from "./skinAnalysis/result/ResultPage";
import ChangeResultPage from "./skinAnalysis/result/ChangeResultPage";
import SkinLogPage from "./skinLog/SkinLogPage";
import FaceUploadResultPage from "./skinAnalysis/upload/face/UploadResultPage";
import BodyUploadResultPage from "./skinAnalysis/upload/body/UpdateResultPage";
import SimulationPage from "./simulation/SimulationPage";
import SimulationDetailPage from "./simulation/SimulationDetailPage";
import ARresultPage from "./skinAnalysis/result/ARresultPage";

const PageRoute: React.FC = () => {
	const location: any = useLocation();
	const {isLoggedIn} = useAuth();
	const [present] = useIonAlert();
	const history = useHistory();

	const setIsOpenClinicModal = useSetRecoilState(isOpenClinicModalState);
	const clinicSearchType = useRecoilValue(clinicSearchTypeState);
	const beforeViewInfoByMap = useRecoilValue(mapViewInfoByMap);

	const [modalOpen, setModalOpen] = useState(false);
	const [etcIcon, setEtcIcon] = useState<string | undefined>();

	const onTabChanage = useCallback((e: CustomEvent) => {
		if (e.detail.tab === "etc") {
			setModalOpen(true);
		}
	}, []);

	useEffect(() => {
		setIsOpenClinicModal(
			location.pathname === "/t/hospital" &&
				(clinicSearchType === "Around" ||
					(clinicSearchType === "Map" && (beforeViewInfoByMap?.level ?? 13) <= 7)),
		);
		setModalOpen(false);
	}, [location]);

	useEffect(() => {
		let icon = modalOpen ? "etc-icon-selected" : "etc-icon";
		setEtcIcon(icon);
	}, [modalOpen]);

	function unselectTabs() {
		const btnHome = document.getElementById("tab-button-home");
		const btnSkinAnalysis = document.getElementById("tab-button-skin-anlysis");
		const btnSkinChange = document.getElementById("tab-button-skin-change");
		const btnSkinHistory = document.getElementById("tab-button-skin-log");
		const btnSkinHospital = document.getElementById("tab-button-skin-hospital");

		btnHome?.classList.remove("tab-selected");
		btnSkinAnalysis?.classList.remove("tab-selected");
		btnSkinChange?.classList.remove("tab-selected");
		btnSkinHistory?.classList.remove("tab-selected");
		btnSkinHospital?.classList.remove("tab-selected");

		btnHome?.setAttribute("aria-selected", "false");
		btnSkinAnalysis?.setAttribute("aria-selected", "false");
		btnSkinChange?.setAttribute("aria-selected", "false");
		btnSkinHistory?.setAttribute("aria-selected", "false");
		btnSkinHospital?.setAttribute("aria-selected", "false");
	}

	function selectedTabs() {
		const btnSkinHospital = document.getElementById("tab-button-skin-hospital");
		btnSkinHospital?.classList.add("tab-selected");
		btnSkinHospital?.setAttribute("aria-selected", "true");
	}

	return (
		<Switch>
			<Route exact path="/" render={() => <Redirect to="/t/home" />} />
			<Route
				path="/t"
				render={() => (
					<>
						<IonTabs onIonTabsWillChange={onTabChanage}>
							<IonRouterOutlet>
								<Switch>
									<Route exact path="/t/:tab(home)" component={Home} />
									<Route exact path="/t/:tab(skin-analysis)" component={SkinAnalysis} />
									<Route exact path="/t/:tab(skin-log)" component={SkinLogPage} />
									<Route exact path="/t/:tab(hospital)" component={FindClinic} />
									<Route exact path="/t/:tab(info)" component={SkinInfo} />
									<Route exact path="/t/:tab(video)" component={SkinVideo} />
									<Route exact path="/t/:tab(mypage)" component={MyPage} />
									<Route exact path="/t/:tab(simulation)" component={SimulationPage} />
								</Switch>
							</IonRouterOutlet>
							<IonTabBar className="common-tab-bar" slot="bottom">
								<IonTabButton tab="home" href="/t/home" className="tab-home tab">
									<div className="icon-space"></div>
									<IonLabel>홈</IonLabel>
								</IonTabButton>
								<IonTabButton tab="skin-analysis" href="/t/skin-analysis" className="tab-skin-analysis tab">
									<div className="icon-space"></div>
									<IonLabel>내 피부 분석</IonLabel>
								</IonTabButton>
								<IonTabButton tab="skin-log" href="/t/skin-log" className="tab-skin-log tab">
									<div className="icon-space"></div>
									<IonLabel>내 피부 변화</IonLabel>
								</IonTabButton>
								<IonTabButton
									tab="skin-hospital"
									href="/t/hospital"
									className="tab-skin-hospital tab"
									onClick={selectedTabs}
								>
									<div className="icon-space"></div>
									<IonLabel>병원 찾기</IonLabel>
								</IonTabButton>

								<IonTabButton tab="etc">
									<div className="wrap-etc-icon">
										<div className={etcIcon + " icon"}></div>
										{/* <img src={etcIcon} alt="etc icon" /> */}
									</div>
								</IonTabButton>
							</IonTabBar>
						</IonTabs>

						<IonPopover className="popover" isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)}>
							<div className="tab-etc">
								<IonList>
									<IonItem
										routerLink="/t/video"
										detail={false}
										onClick={() => {
											unselectTabs();
										}}
									>
										<img src="assets/icon/menu_video.svg" className="icon" alt="move" />
										<IonLabel>톡톡 피부 영상</IonLabel>
									</IonItem>
									<IonItem
										routerLink="/t/info"
										detail={false}
										onClick={() => {
											unselectTabs();
										}}
									>
										<img src="assets/icon/menu_book.svg" className="icon" alt="move" />
										<IonLabel>쏙쏙 피부 정보</IonLabel>
									</IonItem>
									<IonItem
										button
										onClick={() => {
											history.push("/t/simulation");
											unselectTabs();
										}}
										detail={false}
									>
										<img src="assets/icon/menu_simulation.svg" className="icon" alt="move" />
										<IonLabel>성형 후 시뮬레이션</IonLabel>
									</IonItem>
									<IonItem
										button
										onClick={() => {
											history.push("/t/mypage");
											unselectTabs();
										}}
										detail={false}
									>
										<img src="assets/icon/menu_user.svg" className="icon" alt="move" />
										<IonLabel>마이페이지</IonLabel>
									</IonItem>
								</IonList>
							</div>
						</IonPopover>

						<ClinicListModal></ClinicListModal>
					</>
				)}
			/>

			<Route exact path="/clinic/:id" component={FindClinicDetail} />
			<Route exact path="/info/:id" component={SkinInfoDetail} />
			<Route exact path="/video/:id" component={SkinVideoDetail} />
			<Route exact path="/search" render={() => <Redirect to="/search/clinic" />} />
			<Route exact path="/search/:type" component={Search} />
			<Route path="/notice" component={Notice} />
			<Route path="/faq" component={FrequentlyAskedQuestion} />
			<Route path="/mypage/bookmark" component={Bookmark} exact />
			<Route path="/mypage/terms/:id" component={ViewTermsDetail} exact />
			<Route path="/mypage/terms" component={ViewTerms} exact />
			<AuthRoute path="/myinfo" component={MyInfo} exact />
			<AuthRoute path="/myinfo-pw" component={MyInfoPW} exact />
			<AuthRoute path="/myinfo-nicknm" component={MyInfoNicknm} exact />
			<AuthRoute path="/myinfo-phone" component={MyInfoPhone} exact />
			<AuthRoute path="/account-close" component={MyAccountClose} exact />
			<Route path="/login" component={Login} exact />
			<Route path="/login/email" component={EmailLogin} />
			<Route path="/find-email" component={FindEmail} />
			<Route path="/find-password" component={FindPassword} />
			<Route path="/reset-password" component={ResetPassword} />
			<Route path="/sign-up" component={RegAsEmail} exact />
			<Route path="/sign-up/social" component={RegAsSocial} />
			<Route path="/sign-up/complete" component={SignUpComplete} />
			<Route path="/skin-analysis/upload/face" component={FaceUploadPage} exact />
			<Route path="/skin-analysis/upload/face/result" component={FaceUploadResultPage} exact />
			<Route path="/skin-analysis/upload/body" component={BodyUploadPage} exact />
			<Route path="/skin-analysis/upload/body/result" component={BodyUploadResultPage} exact />
			<Route path="/skin-analysis/question" component={QuestionPage} exact />

			<Route
				path="/skin-analysis/question/simple/list/:nodeId/:pageId"
				component={QuestionDetailPage}
				exact
			/>
			<Route path="/skin-analysis/question/detail/list/:id" component={QuestionDetailPage} exact />

			<Route path="/skin-analysis/question/result" component={QuestionResultPage} exact />
			<Route path="/skin-analysis/result" component={ResultPage} exact />
			<Route path="/skin-change/result/:type" component={ChangeResultPage} exact />

			<Route path="/simulation/result" component={SimulationDetailPage} exact />
			<Route path="/skin-change/ar/result" component={ARresultPage} exact />
		</Switch>
	);
};

export default PageRoute;
