/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useCallback} from "react";
import {
	IonPage,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonList,
	IonRow,
	IonSearchbar,
	IonSlide,
	IonSlides,
	IonTitle,
	IonToolbar,
	IonCard,
	useIonAlert,
} from "@ionic/react";
import {useHistory} from "react-router";
import {useRecoilValue, useSetRecoilState} from "recoil";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {useAuth} from "../hooks/auth";
import {useApi} from "../hooks/api";
import useGeoLocation from "../hooks/useGeoLocation";
import ClinicItem from "../components/presentational/ClinicItem";
import {searchPageTypeState, totalCountGroupState} from "../store/atoms";
import {BannerType, MainSkinInfoList} from "../store/types";
import {bannerSliderOption} from "./configs";

type ClinicList = [];
type Clinic = {
	id: number;
	name: string;
	address: string;
	doctorType: string;
	distance: number;
	isFav: boolean;
};
type PromotionList = [];
type Promotion = {
	id: number;
	isShow: boolean;
	description: string;
	endDate: string;
	path: string;
	link: string;
};
type VideoList = [];

const Home: React.FC = () => {
	// 영상 슬라이더 비율 조건 변수
	let clientWidth = document.body.clientWidth;
	let refVal = 260;
	if (clientWidth > 480) clientWidth = 480; //PC버전
	//40 : 양쪽여백 값, 15 : 슬라이드 간격값
	clientWidth = clientWidth - 40 + 15;
	refVal += 15;

	const mainTopSliderOption = {
		slidesPerView: 1.15,
		centeredSlides: true,
		loop: true,
		spaceBetween: -10,
	};

	const mainContentsSliderOption = {
		spaceBetween: 8,
		slidesPerView: 3,
		freeMode: true,
	};

	const {user} = useAuth();
	const history = useHistory();
	const userState = localStorage.getItem("accessToken");

	const setSearchPageType = useSetRecoilState(searchPageTypeState);

	const [clinicList, setClinicList] = useState<ClinicList | null>(null);
	const [videoList, setVideoList] = useState<VideoList | null>(null);
	const [skinInfoList, setSkinInfoList] = useState<MainSkinInfoList[]>();

	const [topAdBannerList, setTopAdBannerList] = useState<BannerType>();
	const [middleAdBannerList, setMiddleAdBannerList] = useState<BannerType>();
	const [bottomAdBannerList, setBottomAdBannerList] = useState<BannerType>();

	const [bannerAdType] = useState<number>(1);
	const [topBannerSectionType] = useState<number>(1);
	const [middelBannerSectionType] = useState<number>(2);
	const [bottomBannerSectionType] = useState<number>(3);

	const geoLocation = useGeoLocation();
	const {processApi} = useApi();
	const [showAlert] = useIonAlert();

	const middlepPaginationOption = {
		customPaging: function (slider: any) {
			return (
				<span>
					{slider + 1}/{middleAdBannerList?.totalCount}
				</span>
			);
		},
	};

	const bottomPaginationOption = {
		customPaging: function (slider: any) {
			return (
				<span>
					{slider + 1}/{bottomAdBannerList?.totalCount}
				</span>
			);
		},
	};

	useEffect(() => {
		if (!geoLocation.loaded) return;

		let lat = null,
			lng = null;
		if (geoLocation?.coordinates) {
			lat = geoLocation.coordinates?.lat;
			lng = geoLocation.coordinates?.lng;
		}

		processApi("main", {lat, lng}).then((data: any) => {
			setClinicList(data.CLINIC);
			setVideoList(data.VIDEO);
			setSkinInfoList(data.SKININFO);
		});
	}, [geoLocation]);

	const getTopAdBannerList = async () => {
		let params = {
			adType: bannerAdType,
			sectionType: topBannerSectionType,
		};

		try {
			const data = await processApi<any>("ad", params);
			setTopAdBannerList(data);
		} catch (e) {
			console.log(e);
		}
	};

	const getMiddleAdBannerList = async () => {
		let params = {
			adType: bannerAdType,
			sectionType: middelBannerSectionType,
		};

		try {
			const data = await processApi<any>("ad", params);
			setMiddleAdBannerList(data);
		} catch (e) {
			console.log(e);
		}
	};

	const getBottomAdBannerList = async () => {
		let params = {
			adType: bannerAdType,
			sectionType: bottomBannerSectionType,
		};

		try {
			const data = await processApi<any>("ad", params);
			setBottomAdBannerList(data);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getTopAdBannerList();
		getMiddleAdBannerList();
		getBottomAdBannerList();
	}, []);

	const handleLoginClick = useCallback(e => {
		if (userState) return;
		e.preventDefault();
		showAlert({
			message: "로그인이 필요한 서비스입니다.</br>SDOC 회원이라면 로그인해주세요.",
			buttons: ["취소", {text: "로그인", cssClass: "primary", handler: d => history.push("/login")}],
			cssClass: "alert-css2",
		});
	}, []);

	return (
		<IonPage className="home">
			{/* 헤더 */}
			<IonHeader>
				<IonToolbar color="primary">
					<IonTitle size="large">
						<button className="logo">
							<img src="/assets/logo.svg" alt="logo" />
						</button>
					</IonTitle>
				</IonToolbar>
			</IonHeader>

			{/* 컨텐트 */}
			<IonContent fullscreen>
				<div className="content-inner">
					{/* 이미지 슬라이더 */}
					{topAdBannerList && (
						<IonSlides pager={true} className="slide-banner" options={mainTopSliderOption}>
							{topAdBannerList?.detailList?.map((item: any, index: any) => (
								<IonSlide key={index}>
									<IonCard>
										<img
											src={item.fileUrl}
											alt="banner"
											onClick={() => (item.targetUrl ? window.open(item.targetUrl) : undefined)}
										/>
									</IonCard>
								</IonSlide>
							))}
						</IonSlides>
					)}

					{/* 분석 결과 */}
					<h2 className="ion-padding">AI 분석 센터</h2>
					<IonGrid className="result-grid ion-padding">
						<IonRow>
							<IonCol>
								<IonButton
									routerLink="/t/skin-log"
									className="btn-skin-result"
									expand="block"
									onClick={handleLoginClick}
								>
									<img src="assets/icon/icons_notice.png" alt="notification" />
									{user ? (
										<span className="txt-in-btn">내 피부 분석 기록을 확인해보세요.</span>
									) : (
										<span className="txt-in-btn">로그인 하고 내 피부 분석 서비스를 이용해보세요.</span>
									)}
									<img src="assets/icon/icons_navigate_next.png" alt="icons_navigate_next" />
								</IonButton>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonButton
									routerLink="/t/skin-analysis"
									className="btn-skin-analysis"
									expand="block"
									onClick={handleLoginClick}
								>
									<div>
										<img src="assets/img/img_analyze_skin.png" alt="analyze skin" />
										<br />
										<label>내 피부 분석</label>
									</div>
								</IonButton>
							</IonCol>
							<IonCol>
								<IonButton
									routerLink="/t/skin-log"
									className="btn-skin-change"
									expand="block"
									onClick={handleLoginClick}
								>
									<div>
										<img src="assets/img/img_dermal_change.png" alt="dermal change" />
										<br />
										<label>내 피부 변화</label>
									</div>
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>

					{/** 쏙쏙 피부 정보 */}
					<IonGrid className="grid-info">
						<div className="title-container ion-padding">
							<h2>쏙쏙 피부 정보</h2>
							<div
								className="view-all"
								onClick={() => {
									history.push("/t/info");
								}}
							>
								전체보기
							</div>
						</div>
						<IonSlides options={mainContentsSliderOption} className="slide-info ion-padding">
							{skinInfoList?.map((info: any) => (
								<IonSlide key={`info_${info.id}`}>
									<div
										className="slide-wrap"
										onClick={() => {
											history.push(`/info/${info.id}`);
										}}
									>
										<img src={info.thumbnail} alt="" />
									</div>
								</IonSlide>
							))}
						</IonSlides>
					</IonGrid>

					{/** 톡톡 피부 영상 */}
					<IonGrid className="grid-video">
						<div className="title-container ion-padding">
							<h2>톡톡 피부 영상</h2>
							<div
								className="view-all"
								onClick={() => {
									history.push("/t/video");
								}}
							>
								전체보기
							</div>
						</div>
						<IonSlides options={mainContentsSliderOption} className="slide-video ion-padding">
							{videoList?.map((video: any) => (
								<IonSlide key={`video_${video.id}`}>
									<div
										className="slide-wrap"
										onClick={() => {
											history.push(`/video/${video.id}`);
										}}
									>
										<img src={video.thumbnail} alt="" />
									</div>
								</IonSlide>
							))}
						</IonSlides>
					</IonGrid>

					{/** 중간 배너 영역 */}
					{middleAdBannerList && (
						<IonGrid className="grid-ad-banner">
							<Slider {...bannerSliderOption} {...middlepPaginationOption} className="banner-slider">
								{middleAdBannerList?.detailList?.map((item, index) => (
									<div key={index}>
										<img
											src={item.fileUrl}
											onClick={() => (item.targetUrl ? window.open(item.targetUrl) : undefined)}
											alt="ad"
										/>
									</div>
								))}
							</Slider>
						</IonGrid>
					)}

					<IonGrid className="grid-clinic ion-padding">
						<h2>내 주변 병원찾기</h2>
						{/* 내 주변 병원찾기 - 검색창 & 리스트 */}
						<IonRow>
							<IonCol>
								<IonList>
									<div
										onClick={() => {
											setSearchPageType("clinic");
											history.push("/search");
										}}
									>
										<IonSearchbar placeholder="병원명을 검색해 보세요.">
											<img src="assets/icon/icons_search.png" className="search icon" alt="search" />
										</IonSearchbar>
									</div>
									{clinicList?.map((clinic: Clinic, index: number) => {
										return (
											<ClinicItem
												clinic={clinic}
												key={`clinic_${clinic.id}`}
												thumbnail={false}
												showBookmark={true}
											></ClinicItem>
										);
									})}
								</IonList>
							</IonCol>
						</IonRow>
					</IonGrid>

					{/** 하단 배너 영역 */}
					{bottomAdBannerList && (
						<IonGrid className="grid-ad-banner">
							<Slider {...bannerSliderOption} {...bottomPaginationOption} className="banner-slider">
								{bottomAdBannerList?.detailList?.map((item, index) => (
									<div key={index}>
										<img
											src={item.fileUrl}
											onClick={() => (item.targetUrl ? window.open(item.targetUrl) : undefined)}
											alt="ad"
										/>
									</div>
								))}
							</Slider>
						</IonGrid>
					)}

					{/* 푸터 */}
					<IonGrid className="footer ion-padding">
						<IonRow>
							<IonCol>
								<div>AI2U</div>
								<address>
									(주)에이아이투유
									<br />
									사업자등록번호 : 125-86-33065
									<br />
									서울특별시 성북구 고려대로 73 고려대학교 의과대학
								</address>
							</IonCol>
						</IonRow>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Home;
