import {IonContent, IonLoading, IonPage} from "@ionic/react";
import {useRecoilState} from "recoil";
import {useEffect, useState} from "react";
import {HeaderBase} from "../../components";
import {simulationImgListState, tokenState} from "../../store/atoms";

const SimulationDetailPage: React.FC = () => {
	const [token] = useRecoilState(tokenState);
	const [fileList] = useRecoilState(simulationImgListState);

	const [showLoading, setShowLoading] = useState(false);

	const handleDownload = async (url: any) => {
		const response = await fetch(url, {
			method: "GET",
			headers: {
				authorization: `Bearer ${token.token}`,
			},
		});

		const data = await response.blob();
		const fileName = "image.png";

		const link = document.createElement("a");
		document.body.appendChild(link);
		link.style.display = "none";

		const urlObject = window.URL.createObjectURL(data);
		link.href = urlObject;
		link.download = fileName;

		link.click();

		if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
			const reader = new FileReader();
			reader.onload = function () {
				const dataURL = reader.result;
				const filename = fileName;
				const link: any = document.createElement("a");
				link.style.display = "none";
				link.href = dataURL;
				link.download = filename;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			};
			reader.readAsDataURL(data);
		}

		window.URL.revokeObjectURL(urlObject);
	};

	useEffect(() => {
		setShowLoading(true);

		setTimeout(() => {
			setShowLoading(false);
		}, 7000);
	}, []);

	return (
		<IonPage className="simulation-detail-page">
			<HeaderBase title="성형 후 시뮬레이션" backMode />
			<IonLoading
				isOpen={showLoading}
				message="잠시만 기다려주세요."
				onDidDismiss={() => setShowLoading(false)}
			/>
			<IonContent>
				<div className="simulation-detail-page-contents">
					{fileList.map((item: any, index: any) => (
						<div key={index} className={`${index === 0 ? "item" : "item flex"}`}>
							<p>
								<span>
									{index === 0 && "성형 직후"}
									{index === 1 && "7일 후"}
									{index === 2 && "30일 후"}
								</span>
							</p>
							<img src={item} alt="img" />
							{index !== 0 && (
								<div className="download-area">
									<button className="btn-download" onClick={() => handleDownload(item)}>
										<i className="icon-download" />
										사진 다운로드
									</button>
								</div>
							)}
						</div>
					))}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SimulationDetailPage;
