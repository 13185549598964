export const helpModalSliderContents = [
	{
		id: 0,
		img: "assets/img/guide-popup_img01.png",
		info: "정확한 분석 및 기록을 위해 피부를\n깨끗하게 하고 얼굴이 가려지지 않도록\n사진을 촬영해 주세요.",
	},
	{
		id: 1,
		img: "assets/img/guide-popup_img02.png",
		info: "가이드라인에 얼굴을 맞춰\n정면과 측면의 사진을 촬영해 주세요.",
	},
	{
		id: 2,
		img: "assets/img/guide-popup_img03.png",
		info: "강한 조명, 측면 조명, 역광 등 촬영된\n사진이 너무 밝거나, 그림자가 생길 수\n있는 조명은 피해주세요.",
	},
];

export const bodyGuidePopupContents = [
	{
		id: 0,
		img: "assets/img/guide-popup_img04.png",
		info: "정확한 분석 및 기록을 위해\n증상 부위를 깨끗하게 하고 \n사진을 촬영해 주세요.",
	},
	{
		id: 1,
		img: "assets/img/guide-popup_img05.png",
		info: "사각 가이드라인에 맞춰 \n 선명하게 사진을 촬영해 주세요.\n (사진이 흐리면 분석이 어려워요.)",
	},
	{
		id: 2,
		img: "assets/img/guide-popup_img06.png",
		info: "강한 조명, 측면 조명, 역광 등 촬영된 \n사진이 너무 밝거나, 그림자가 생길 수 \n있는 조명은 피해주세요.",
	},
];

export const orderValue = [
	{
		title: "최신순",
		value: "desc",
	},
	{
		title: "과거순",
		value: "asc",
	},
];

export const faceModeUser = "user";
export const faceModeEnv = "environment";

export const camModeAll = "all";
export const camModeOne = "one";

export const mainBannerImg = [
	{
		img: "assets/img/banner/main-banner01.png",
		url: "http://w.theu.co.kr/store_search/searching.php?sc_str3=2000601&page=1&",
	},
	{
		img: "assets/img/banner/main-banner02.gif",
		url: "http://www.dongkoo.com",
	},
];

export const infoText = {
	subTitle: "잠시만 기다려 주세요.",
	info: "해당 결과는 진료나 진단이 아님을 알려드립니다. 자세한 내용은 전문적인 의료기관을 방문하여 상담하시길 바랍니다.",
};

export const bannerSliderOption = {
	dots: true,
	arrows: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	infinite: true,
	autoplay: true,
	pauseOnHover: false,
	speed: 400,
};

export const bannerExamImg = [
	{
		img: "assets/img/banner/analysis-banner01.png",
		url: "",
	},
	{
		img: "assets/img/banner/banner01.png",
		url: "https://naver.me/FijKgAtg",
	},
];
