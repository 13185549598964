/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useCallback, useEffect} from "react";
import {RouteComponentProps} from "react-router-dom";
import {withRouter} from "react-router";
import "@tensorflow/tfjs-backend-webgl";
import {useRecoilState, useRecoilValue} from "recoil";
import {IonRow, IonPage, useIonAlert} from "@ionic/react";

import {Webcam, Modal} from "../../../../components/presentational";
import {HeaderBase, SwitchCase} from "../../../../components";
import {camModeAll, faceModeEnv, faceModeUser, helpModalSliderContents} from "../../../configs";
import {
	analysisFaceCenter,
	analysisFaceLeft,
	analysisFacePhotoList,
	analysisFaceRight,
	analysisGuidePopupState,
	faceLocation,
	centerFaceCamMode,
	leftFaceCamMode,
	rightFaceCamMode,
	takeCamMode,
} from "../../../../store/atoms";

const blazeface = require("@tensorflow-models/blazeface");

const UploadPage: React.FC<RouteComponentProps> = ({history}) => {
	// ref
	const centerCamRef = useRef<any>(null);
	const leftCamRef = useRef<any>(null);
	const rightCamRef = useRef<any>(null);
	const canvasRef = useRef<any>(null);
	const audioRef = useRef<any>(null);

	const [showAlert] = useIonAlert();

	const [centerPhotoState, setCenterPhotoState] = useRecoilState(analysisFaceCenter);
	const [leftPhotoState, setLeftPhotoState] = useRecoilState(analysisFaceLeft);
	const [rightPhotoState, setRightPhotoState] = useRecoilState(analysisFaceRight);
	const [facePhotoListState, setFacePhotoListState] = useRecoilState(analysisFacePhotoList);
	const [faceLocationValue, setFaceLocationValue] = useRecoilState(faceLocation);

	// 정면/좌측/우측 사진
	const [centerPhoto, setCenterPhoto] = useState("");
	const [leftPhoto, setLeftPhoto] = useState("");
	const [rightPhoto, setRightPhoto] = useState("");

	// 실시간 가이드라인 판정 state
	const [centerDetectState, setCenterDetectState] = useState<boolean>(false);
	const [leftDetectState, setLeftDetectState] = useState<boolean>(false);
	const [rightDetectState, setRightDetectState] = useState<boolean>(false);

	// 찍은 사진 판정 state
	const [centerDetectPhotoState, setCenterDetectPhotoState] = useState<boolean>(false);
	const [leftDetectPhotoState, setLeftDetectPhotoState] = useState<boolean>(false);
	const [rightDetectPhotoState, setRightDetectPhotoState] = useState<boolean>(false);

	// 촬영 가이드 팝업
	const [showGuidePopup, setShowGuidePopup] = useRecoilState(analysisGuidePopupState);

	// 얼굴 모드 전환
	const [centerFacingMode, setCenterFacingMode] = useRecoilState(centerFaceCamMode);
	const [leftFacingMode, setLeftFacingMode] = useRecoilState(leftFaceCamMode);
	const [rightFacingMode, setRightFacingMode] = useRecoilState(rightFaceCamMode);

	const [centerFaceComplete, setCenterFaceComplete] = useState<string>(faceModeUser);
	const [leftFaceComplete, setLeftFaceComplete] = useState<string>(faceModeUser);
	const [rightFaceComplete, setRightFaceComplete] = useState<string>(faceModeUser);

	const centerVideoConstraints = {facingMode: centerFaceComplete};
	const leftVideoConstraints = {facingMode: leftFaceComplete};
	const rightVideoConstraints = {facingMode: rightFaceComplete};

	// 모두찍기/하나만 찍기
	const camMode = useRecoilValue(takeCamMode);

	//화면 정면 판정 비율 상수 선언 부분
	//아이폰 13 ProMax 기준 [videoWidth:480, videoHeight:640]
	const VideoWidthSplit = 10; //[x좌표] 넓이를 10개로 분할.
	const VideoCheckRR = 500; //동영상 판정 업데이트 인터벌(0.5초)

	//모바일 기준
	const MRightEyeBegin = 3; //[x좌표] 정면 판정시 오른쪽눈(화면상 왼쪽)의 판정영역 시작 지점 [3/10 지점에서 시작]
	const MRightEyeEnd = 5; //[x좌표] 정면 판정시 오른쪽눈(화면상 왼쪽)의 판정영역 끝 지점  [5/10 지점에서 끝]
	const MLeftEyeBegin = 5; //[x좌표] 정면 판정시 왼쪽눈(화면상 오른쪽)의 판정영역 시작 지점 [5/10 지점에서 시작]
	const MLeftEyeEnd = 7; //[x좌표] 정면 판정시 왼쪽눈(화면상 오른쪽)의 판정영역 끝 지점 [7/10 지점에서 끝]

	const EarEyeSpace = 30; //[x좌표] 정면 판정시 귀와 눈의 간격

	//모바일 기준
	const MFrontEyeTop = 280; //[y좌표] 정면 판정시 눈 판정영역 시작 지점
	const MFrontEyeBottom = 320; //[y좌표] 정면 판정시 눈 판정영역 끝 지점

	//모바일기준
	const MMouthTop = 400; //[y좌표] 각 방면 판정시 입의 판정영역 시작 지점
	const MMouthBottom = 500; //[y좌표] 각 방면 판정시 입의 판정역역 끝 지점
	/** 정면 끝 */

	const MEyeEarSpace = 0.25;

	//모바일기준
	const MRightEyeBeginLM = 2.0;
	const MRightEyeEndLM = 3.5;

	/** 좌측 끝 */

	//모바일기준
	const MLeftEyeBeginRM = 7;
	const MLeftEyeEndRM = 8;
	/** 우측 끝 */

	// 정면 사진 촬영
	const handleCenterDetect = useCallback(() => {
		const img = centerCamRef.current;
		const mirrorMode = centerFaceComplete === faceModeUser ? true : false;

		if (centerDetectState === true) {
			handleCameraShutterAudio();

			const imgSrc = img.getScreenshot();
			setCenterPhoto(imgSrc);
		} else {
			handleFaceNotDetect();
		}

		if (centerDetectState === true && img.getScreenshot()) {
			setCenterDetectPhotoState(true);
			setCenterPhotoState({contentBase64: img.getScreenshot(), tag: "center", isMirrored: mirrorMode});
		} else {
			setCenterDetectPhotoState(false);
			setCenterPhotoState(null);
		}

		if (camMode === camModeAll) {
			if (centerFaceComplete && centerFacingMode === faceModeEnv) {
				setLeftFacingMode(faceModeEnv);
				setLeftFaceComplete(faceModeEnv);
			}
		}

		if (centerFaceComplete === faceModeEnv) {
			setLeftFaceComplete(faceModeEnv);
		} else {
			setLeftFaceComplete(faceModeUser);
		}
	}, [centerDetectState]);

	const handleCenterPhotoDetect = () => {
		if (centerPhotoState === null) {
			setFaceLocationValue("left");
		}
	};

	// 좌측 사진 촬영
	const handleLeftDetect = useCallback(() => {
		const img = leftCamRef.current;
		const mirrorMode = leftFaceComplete === faceModeUser ? true : false;

		if (leftDetectState === true) {
			handleCameraShutterAudio();

			const imgSrc = img.getScreenshot();
			setLeftPhoto(imgSrc);
		} else {
			handleFaceNotDetect();
		}

		if (leftDetectState === true && img.getScreenshot()) {
			setLeftDetectPhotoState(true);
			setLeftPhotoState({contentBase64: img.getScreenshot(), tag: "left", isMirrored: mirrorMode});
		} else {
			setLeftDetectPhotoState(false);
			setLeftPhotoState(null);
		}

		if (camMode === camModeAll) {
			if (leftFaceComplete && leftFacingMode === faceModeEnv) {
				setRightFacingMode(faceModeEnv);
				setRightFaceComplete(faceModeEnv);
			}
		}

		if (leftFaceComplete === faceModeEnv) {
			setRightFaceComplete(faceModeEnv);
		} else {
			setRightFaceComplete(faceModeUser);
		}
	}, [leftDetectState]);

	const handleLeftPhotoDetect = () => {
		if (leftPhotoState === null) {
			setFaceLocationValue("right");
		}
	};

	// 우측 사진 촬영
	const handleRightDetect = useCallback(() => {
		const img = rightCamRef.current;
		const mirrorMode = rightFaceComplete === faceModeUser ? true : false;

		if (rightDetectState === true) {
			handleCameraShutterAudio();

			const imgSrc = img.getScreenshot();
			setRightPhoto(imgSrc);
		} else {
			handleFaceNotDetect();
		}

		if (rightDetectState === true && img.getScreenshot()) {
			setRightDetectPhotoState(true);
			setRightPhotoState({contentBase64: img.getScreenshot(), tag: "right", isMirrored: mirrorMode});
		} else {
			setRightDetectPhotoState(false);
			setRightPhotoState(null);
		}
	}, [rightDetectState]);

	// 실시간 정면 얼굴 판정
	const centerDetect = async () => {
		const model = await blazeface.load();
		const returnTensors = !true;

		const timerIntervalId = setInterval(() => {
			(async () => {
				if (
					typeof centerCamRef.current !== "undefined" &&
					centerCamRef.current !== null &&
					centerCamRef.current.video.readyState === 4
				) {
					//Get Video
					const video = centerCamRef.current.video;
					const videoWidth = centerCamRef.current.video.videoWidth;
					const videoHeight = centerCamRef.current.video.videoHeight;

					centerCamRef.current.video.width = videoWidth;
					centerCamRef.current.video.height = videoHeight;

					canvasRef.current.width = videoWidth; // 480 아이폰 ProMax13 기준
					canvasRef.current.height = videoHeight; // 640  아이폰 ProMax13 기준

					const predictions = await model.estimateFaces(video, returnTensors);

					if (predictions.length > 0) {
						// console.log(predictions);
					}
					for (let i = 0; i < predictions.length; i++) {
						const Landmark = predictions[i].landmarks as any;
						if (
							Landmark[0][0] > (videoWidth * MRightEyeBegin) / VideoWidthSplit &&
							Landmark[0][0] < (videoWidth * MRightEyeEnd) / VideoWidthSplit &&
							Landmark[1][0] > (videoWidth * MLeftEyeBegin) / VideoWidthSplit &&
							Landmark[1][0] < (videoWidth * MLeftEyeEnd) / VideoWidthSplit &&
							Landmark[0][1] > MFrontEyeTop &&
							Landmark[0][1] < MFrontEyeBottom &&
							Landmark[1][1] > MFrontEyeTop &&
							Landmark[1][1] < MFrontEyeBottom &&
							Landmark[0][0] > Landmark[4][0] + EarEyeSpace &&
							Landmark[5][0] > Landmark[1][0] + EarEyeSpace &&
							Landmark[3][1] > MMouthTop &&
							Landmark[3][1] < MMouthBottom
						) {
							setCenterDetectState(true);
						} else {
							setCenterDetectState(false);
							setLeftDetectState(false);
							setRightDetectState(false);
						}
					}
				}
			})();
		}, VideoCheckRR);

		return () => {
			clearInterval(timerIntervalId);
		};
	};

	// 실시간 좌측 얼굴 판정
	const leftDetect = async () => {
		const model = await blazeface.load();
		const returnTensors = !true;

		const timerIntervalId = setInterval(() => {
			(async () => {
				if (
					typeof leftCamRef.current !== "undefined" &&
					leftCamRef.current !== null &&
					leftCamRef.current.video.readyState === 4
				) {
					const video = leftCamRef.current.video;
					const videoWidth = leftCamRef.current.video.videoWidth;
					const videoHeight = leftCamRef.current.video.videoHeight;

					leftCamRef.current.video.width = videoWidth;
					leftCamRef.current.video.height = videoHeight;

					canvasRef.current.width = videoWidth; // 640
					canvasRef.current.height = videoHeight; // 480

					const predictions = await model.estimateFaces(video, returnTensors);
					if (predictions.length > 0) {
						// console.log(predictions);
					}
					for (let i = 0; i < predictions.length; i++) {
						const Landmark = predictions[i].landmarks as any;
						if (
							Landmark[5][0] - Landmark[0][0] > videoWidth * MEyeEarSpace &&
							Landmark[4][0] > Landmark[0][0] &&
							Landmark[0][0] > (videoWidth * MRightEyeBeginLM) / VideoWidthSplit &&
							Landmark[0][0] < (videoWidth * MRightEyeEndLM) / VideoWidthSplit &&
							Landmark[1][1] > MFrontEyeTop &&
							Landmark[1][1] < MFrontEyeBottom &&
							Landmark[3][1] > MMouthTop &&
							Landmark[3][1] < MMouthBottom
						) {
							setLeftDetectState(true);
						} else {
							setLeftDetectState(false);
							setCenterDetectState(false);
							setRightDetectState(false);
						}
					}
				}
			})();
		}, VideoCheckRR);

		return () => {
			clearInterval(timerIntervalId);
		};
	};

	// 실시간 우측 얼굴 판정
	const rightDetect = async () => {
		const model = await blazeface.load();
		const returnTensors = !true;

		const timerIntervalId = setInterval(() => {
			(async () => {
				if (
					typeof rightCamRef.current !== "undefined" &&
					rightCamRef.current !== null &&
					rightCamRef.current.video.readyState === 4
				) {
					const video = rightCamRef.current.video;
					const videoWidth = rightCamRef.current.video.videoWidth;
					const videoHeight = rightCamRef.current.video.videoHeight;

					rightCamRef.current.video.width = videoWidth;
					rightCamRef.current.video.height = videoHeight;

					canvasRef.current.width = videoWidth;
					canvasRef.current.height = videoHeight;

					const predictions = await model.estimateFaces(video, returnTensors);
					if (predictions.length > 0) {
						// console.log(predictions);
					}
					for (let i = 0; i < predictions.length; i++) {
						const Landmark = predictions[i].landmarks as any;
						if (
							Landmark[1][0] - Landmark[4][0] > videoWidth * MEyeEarSpace &&
							Landmark[5][0] < Landmark[1][0] &&
							Landmark[1][0] > (videoWidth * MLeftEyeBeginRM) / VideoWidthSplit &&
							Landmark[1][0] < (videoWidth * MLeftEyeEndRM) / VideoWidthSplit &&
							Landmark[0][1] > MFrontEyeTop &&
							Landmark[0][1] < MFrontEyeBottom &&
							Landmark[3][1] > MMouthTop &&
							Landmark[3][1] < MMouthBottom
						) {
							setRightDetectState(true);
						} else {
							setRightDetectState(false);
							setCenterDetectState(false);
							setLeftDetectState(false);
						}
					}
				}
			})();
		}, VideoCheckRR);

		return () => {
			clearInterval(timerIntervalId);
		};
	};

	// 카메라 전환
	const handleReverseCamera = useCallback(() => {
		switch (faceLocationValue) {
			case "center":
				setCenterFacingMode(prevState => (prevState === faceModeUser ? faceModeEnv : faceModeUser));
				setCenterFaceComplete(prevState => (prevState === faceModeUser ? faceModeEnv : faceModeUser));
				break;

			case "left":
				setLeftFacingMode(prevState => (prevState === faceModeUser ? faceModeEnv : faceModeUser));
				setLeftFaceComplete(prevState => (prevState === faceModeUser ? faceModeEnv : faceModeUser));
				break;

			case "right":
				setRightFacingMode(prevState => (prevState === faceModeUser ? faceModeEnv : faceModeUser));
				setRightFaceComplete(prevState => (prevState === faceModeUser ? faceModeEnv : faceModeUser));
				break;
		}
	}, [faceLocationValue]);

	// 카메라 셔터
	const handleCameraShutterAudio = () => {
		audioRef.current.play();
	};

	// 가이드 팝업
	const openModal = () => {
		setShowGuidePopup(true);
	};

	const closeModal = () => {
		setShowGuidePopup(false);
	};

	// 가이드라인 불일치, 촬영 버튼 클릭 시
	const handleFaceNotDetect = () => {
		showAlert({
			message: "얼굴 인식이 안되었어요.<br />얼굴 가이드라인이 파란색일 때<br />사진을 찍어주세요.",
			buttons: [{text: "확인", cssClass: "primary"}],
			cssClass: "alert-css2",
		});
	};

	// 홈 버튼 클릭 이벤트
	const handleClickCancelButton = () => {
		showAlert({
			message: "내 피부 분석을 중단하시겠어요? <br />지금 중단하시면 <br />진행하고 있는 데이터는 사라져요.",
			buttons: [{text: "중단하기", handler: d => history.push("/t/home")}, "계속하기"],
			cssClass: "alert-css2",
		});
	};

	// 좌측 face guide class name
	const leftFaceGuideClass = `guideline${
		leftDetectState && leftFaceComplete === faceModeUser ? " left-ok" : ""
	}${!leftDetectState && leftFaceComplete === faceModeUser ? " left-cancel" : ""}${
		leftDetectState && leftFaceComplete === faceModeEnv ? " right-ok" : ""
	}${!leftDetectState && leftFaceComplete === faceModeEnv ? " right-cancel" : ""}`;

	// 우측 face guide class name
	const rightFaceGuideClass = `guideline${
		rightDetectState && rightFaceComplete === faceModeUser ? " right-ok" : ""
	}${!rightDetectState && rightFaceComplete === faceModeUser ? " right-cancel" : ""}${
		rightDetectState && rightFaceComplete === faceModeEnv ? " left-ok" : ""
	}${!rightDetectState && rightFaceComplete === faceModeEnv ? " left-cancel" : ""}`;

	useEffect(() => {
		switch (faceLocationValue) {
			case "left":
				leftDetect();
				break;
			case "right":
				rightDetect();
				break;
			case "center":
				centerDetect();
				break;
		}
	}, [faceLocationValue]);

	useEffect(() => {
		if (centerDetectPhotoState === true) {
			handleCenterPhotoDetect();
		}
	}, [centerDetectPhotoState]);

	useEffect(() => {
		if (leftDetectPhotoState === true) {
			handleLeftPhotoDetect();
		}
	}, [leftDetectPhotoState]);

	useEffect(() => {
		if (centerPhotoState !== null && leftPhotoState !== null && rightPhotoState !== null) {
			setFacePhotoListState([centerPhotoState, leftPhotoState, rightPhotoState]);

			setTimeout(() => {
				history.push("/skin-analysis/upload/face/result");
			}, 400);
		}
	}, [centerPhotoState, leftPhotoState, rightPhotoState]);

	useEffect(() => {
		setFacePhotoListState([]);
	}, []);

	const renderCameraMode = () => {
		return (
			<>
				<div className="content-padding analysis-upload__cam">
					{/** 사진 촬영 Cam, Guide */}
					<SwitchCase
						tests={[
							{
								test: faceLocationValue === "left",
								component: (
									<div className="camera">
										<Webcam
											camRef={leftCamRef}
											handleTakePhoto={handleLeftDetect}
											photo={leftPhoto}
											videoConstraints={{
												...leftVideoConstraints,
												leftFaceComplete,
											}}
											mirrorMode={leftFaceComplete === faceModeUser ? true : false}
										/>
										<div className="guideline-wrap">
											<div className={leftFaceGuideClass} />
										</div>
									</div>
								),
							},
							{
								test: faceLocationValue === "right",
								component: (
									<div className="camera">
										<Webcam
											camRef={rightCamRef}
											handleTakePhoto={handleRightDetect}
											photo={rightPhoto}
											videoConstraints={{
												...rightVideoConstraints,
												rightFaceComplete,
											}}
											mirrorMode={rightFaceComplete === faceModeUser ? true : false}
										/>
										<div className="guideline-wrap">
											<div className={rightFaceGuideClass} />
										</div>
									</div>
								),
							},
							{
								test: faceLocationValue === "center",
								component: (
									<div className="camera">
										<Webcam
											camRef={centerCamRef}
											handleTakePhoto={handleCenterDetect}
											photo={centerPhoto}
											videoConstraints={{
												...centerVideoConstraints,
												centerFaceComplete,
											}}
											mirrorMode={centerFaceComplete === faceModeUser ? true : false}
										/>
										<div className="guideline-wrap">
											<div className={`guideline ${centerDetectState ? "center-ok" : "center-cancel"}`} />
										</div>
									</div>
								),
							},
						]}
					/>
				</div>

				<div className="content-margin analysis-upload__camera">
					{/** Guide Line 상태에 따른 문구 */}
					<div className="guideline-state">
						<SwitchCase
							tests={[
								{
									test: centerDetectState || leftDetectState || rightDetectState,
									component: (
										<p>
											얼굴 인식이 성공했습니다.
											<br />
											사진을 찍어주세요!
										</p>
									),
								},
								{
									test: !leftDetectState && faceLocationValue === "left",
									component: (
										<p>
											좌측 촬영입니다. <br />
											얼굴을 가이드라인 안에 잘 맞춰주세요.
										</p>
									),
								},
								{
									test: !rightDetectState && faceLocationValue === "right",
									component: (
										<p>
											우측 촬영입니다. <br />
											얼굴을 가이드라인 안에 잘 맞춰주세요.
										</p>
									),
								},
							]}
							defaultComponent={
								<p>
									정면 촬영입니다. <br />
									얼굴을 가이드라인 안에 잘 맞춰주세요.
								</p>
							}
						/>
					</div>

					{/** Camera Controls */}
					<div className="camera-controls">
						<IonRow className="camera-controls__align">
							<button onClick={openModal} className="btn-area">
								<img src="assets/icon/icon_guide.svg" alt="" />
								<span>가이드</span>
							</button>
							<div className="ion-text-center camera-area">
								<SwitchCase
									tests={[
										{
											test: faceLocationValue === "left",
											component: <button onClick={handleLeftDetect} />,
										},
										{
											test: faceLocationValue === "right",
											component: <button onClick={handleRightDetect} />,
										},
									]}
									defaultComponent={<button onClick={handleCenterDetect} />}
								/>
							</div>
							<div className="ion-text-right">
								<button onClick={handleReverseCamera} className="btn-area">
									<img src="assets/icon/icon_camera_reverse.svg" alt="" />
									<span>전환</span>
								</button>
							</div>
						</IonRow>
					</div>

					{/** 얼굴 판정 Canvas */}
					<div className="AppCanvas">
						<header className="App-headerCanvas">
							<canvas
								ref={canvasRef}
								style={{
									position: "absolute",
									marginLeft: "auto",
									marginRight: "auto",
									left: 0,
									right: 0,
									textAlign: "center",
									zIndex: 9,
									width: 640,
									height: 1080,
								}}
							/>
						</header>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<IonPage className="analysis-upload">
				<HeaderBase backHref="/t/skin-analysis" backMode homeMode onHomeButton={handleClickCancelButton} />

				{renderCameraMode()}

				{/** 얼굴 촬영 가이드 팝업 */}
				<Modal
					isOpen={showGuidePopup}
					closeMode
					isClose={closeModal}
					isCloseComplete={closeModal}
					slideMode
					sliderContents={helpModalSliderContents}
					className="guide-popup"
				/>

				{/** 카메라 촬영 셔터 사운드 */}
				<audio src="assets/audio/camera-shutter.mp3" ref={audioRef} onPlay={handleCameraShutterAudio} />
			</IonPage>
		</>
	);
};

export default withRouter(UploadPage);
