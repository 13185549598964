/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import {useEffect, useState} from "react";
import {IonCard, IonCardContent, IonCheckbox, IonGrid, IonItem} from "@ionic/react";
import shortid from "shortid";
import {RouteComponentProps, withRouter} from "react-router";

interface TypeProps {
	data: any;
	id?: number;
	updateIdData?: any;
	isSelected?: any;
}

const Type6: React.FC<TypeProps & RouteComponentProps> = ({data, id, updateIdData, isSelected}) => {
	const [list, setList] = useState<any>();
	const [selectedItemNum, setSelectedItemNum] = useState<any>([]);
	const [isSelectedItem, setIsSelectedItem] = useState<boolean>(false);

	const handleSelectedItem = (e: any, index: number) => {
		const selectedItems = [...list];
		const selectedItemNums = [...selectedItemNum];
		const value = e.detail.value;

		if (e.detail.checked) {
			selectedItems[index].checked = true;

			if (value === "해당 없음") {
				selectedItems.find(item => {
					if (item.title !== "해당 없음") {
						item.checked = false;
					} else {
						selectedItems[selectedItems.length - 1].checked = true;
						setSelectedItemNum([item.id]);
					}
				});
			} else {
				selectedItemNums.push(selectedItems[index].id);
				setSelectedItemNum(selectedItemNums);

				selectedItems.map(item => {
					if (item.title === "해당 없음") {
						item.checked = false;
						selectedItems[selectedItems.length - 1].checked = false;
					}
				});
			}

			setList(selectedItems);
		} else {
			[...list][index].checked = false;

			const selectedItemNums = [...selectedItemNum].filter(v => v !== selectedItems[index].id);
			setSelectedItemNum(selectedItemNums);
		}

		if (selectedItems) {
			const selectedItemsState = selectedItems.map(item => {
				if (item.checked === true) {
					return true;
				} else {
					return false;
				}
			});

			if (selectedItemsState.includes(true)) {
				setIsSelectedItem(true);
			} else {
				setIsSelectedItem(false);
			}
		}

		setList(selectedItems);
	};

	useEffect(() => {
		updateIdData(selectedItemNum);
	}, [selectedItemNum]);

	useEffect(() => {
		isSelected(isSelectedItem);
	}, [isSelectedItem]);

	useEffect(() => {
		setList(data.moonjinLabelPageDto.moonjinLabelDetailList);
		setSelectedItemNum([]);
	}, [data]);

	useEffect(() => {
		setIsSelectedItem(false);
	}, []);

	return (
		<div className="question-contents__list--wrap">
			<div className="question-contents__list--title">
				<div className="ion-padding">
					<span className="num">질문{id}</span>
					<p className="category">{data.moonjinLabelPageDto.title}</p>
					<span className="description">(중복 선택 가능)</span>
				</div>
			</div>
			<IonGrid className="question-contents__list card-contents">
				<div className="question-contents__list--item item-padding">
					{list?.map((item: any, index: any) => (
						<div key={index} className="list-wrap">
							<IonCard className="photo-card">
								<IonItem>
									<IonCheckbox
										value={item.title}
										onIonChange={e => handleSelectedItem(e, index)}
										checked={item.checked}
									/>
									{item.imageList?.map((img: any, index: any) => (
										<div className="photo-img" key={index}>
											<img src={img.url} alt="" />
										</div>
									))}
									{item.imageList === null ? (
										<IonCardContent>
											<p className="label-none">{item.title}</p>
										</IonCardContent>
									) : (
										<IonCardContent>
											<p className="label-text">{item.title}</p>
										</IonCardContent>
									)}
								</IonItem>
							</IonCard>
						</div>
					))}
				</div>
			</IonGrid>
		</div>
	);
};

export default withRouter(Type6);
