import {useHistory, withRouter} from "react-router";
import {IonButton, IonContent, IonFooter, IonPage, useIonAlert} from "@ionic/react";
import {HeaderBase} from "../../../components";

const QuestionPage: React.FC = () => {
	const history = useHistory();
	const [showAlert] = useIonAlert();

	const analysisValue = localStorage.getItem("analysisValue");
	const analysisPartValue = localStorage.getItem("analysisPartValue");

	const handleMoveToQuestionDetail = () => {
		if (analysisValue === "simple") {
			history.push("/skin-analysis/question/simple/list/0/0");
		} else if (analysisValue === "detail" && analysisPartValue === "face") {
			history.push("/skin-analysis/question/detail/list/1");
		} else if (analysisValue === "detail" && analysisPartValue === "body") {
			history.push("/skin-analysis/question/detail/list/0");
		}
	};

	const handleClickCancelButton = () => {
		showAlert({
			message: "내 피부 분석을 중단하시겠어요? <br />지금 중단하시면 <br />진행하고 있는 데이터는 사라져요.",
			buttons: [{text: "중단하기", handler: d => history.push("/t/home")}, "계속하기"],
			cssClass: "alert-css2",
		});
	};

	return (
		<IonPage className="pg-question">
			<HeaderBase
				title="내 피부 문진 분석"
				backHref="/"
				backMode
				homeMode
				onHomeButton={handleClickCancelButton}
			/>
			<IonContent fullscreen>
				<div className="content-inner question-contents__info">
					<h2>문진표를 작성해주세요.</h2>
					<p>
						보다 정확한 내 피부 분석을 위해 문진이 필요합니다. <br />
						매우 중요한 과정이니 <br />
						정확하게 선택해 주세요.
					</p>
				</div>
			</IonContent>
			<IonFooter className="content-footer">
				<div className="btn-wrap question-contents__bottom">
					<IonButton expand="full" shape="round" onClick={handleMoveToQuestionDetail}>
						문진 시작하기
					</IonButton>
				</div>
			</IonFooter>
		</IonPage>
	);
};

export default withRouter(QuestionPage);
