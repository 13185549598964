/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {IonCol, IonGrid, IonItem, IonLabel, IonRadio, IonRadioGroup, IonRow} from "@ionic/react";
import {RouteComponentProps, withRouter} from "react-router";

interface TypeProps {
	data: any;
	id?: number;
	updateIdData?: any;
	isSelected?: any;
}

const Type1: React.FC<TypeProps & RouteComponentProps> = ({data, id, updateIdData, isSelected}) => {
	const [list, setList] = useState<any>();
	const [selectedItemNum, setSelectedItemNum] = useState<any>([]);
	const [isSelectedItem, setIsSelectedItem] = useState<boolean>(false);

	const handleChangeSelectedItem = (e: any) => {
		setIsSelectedItem(true);
		setSelectedItemNum(e.detail.value);
	};

	useEffect(() => {
		updateIdData([selectedItemNum]);
	}, [selectedItemNum]);

	useEffect(() => {
		setList(data.moonjinLabelPageDto.moonjinLabelDetailList);
		setSelectedItemNum([]);
	}, [data]);

	useEffect(() => {
		setIsSelectedItem(false);
	}, [list]);

	useEffect(() => {
		isSelected(isSelectedItem);
	}, [isSelectedItem]);

	return (
		<div className="question-contents__list--wrap">
			<div className="question-contents__list--title">
				<div className="ion-padding">
					<span className="num">질문{id}</span>
					<p className="category">{data.moonjinLabelPageDto.title}</p>
				</div>
			</div>
			<IonGrid className="question-contents__list">
				<div className="question-contents__list--item">
					{list?.map((item: any, index: any) => (
						<IonRow key={index}>
							<IonCol>
								<IonRadioGroup
									value={selectedItemNum}
									onIonChange={handleChangeSelectedItem}
									className="radio-type"
								>
									<IonItem>
										<IonLabel>{item.title}</IonLabel>
										<IonRadio value={item.id} />
									</IonItem>
								</IonRadioGroup>
							</IonCol>
						</IonRow>
					))}
				</div>
			</IonGrid>
		</div>
	);
};

export default withRouter(Type1);
