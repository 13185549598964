/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback} from "react";
import {IonPage, IonContent, IonGrid, IonCol, IonRow, IonButton} from "@ionic/react";
import useMessage from "../../hooks/message";

import HeaderBase from "../../components/HeaderBase";
import {useHistory} from "react-router";
import {useRecoilState} from "recoil";
import {userState, tokenState, tempUserState} from "../../store/atoms";

const Login: React.FC = () => {
	const BASE_URL = process.env.REACT_APP_API_URL;

	const [tempUser, setTempUser] = useRecoilState(tempUserState);
	const [user] = useRecoilState(userState);
	const [token, setToken] = useRecoilState(tokenState);
	const history = useHistory();

	useEffect(() => {
		if (user) {
			history.replace("/");
		}
	}, [user]);

	useMessage("social-login", (send: any, payload: any) => {
		if (payload.socialToken !== undefined) {
			setTempUser({socialToken: payload.socialToken});
			setTimeout(() => {
				history.replace("/sign-up/social");
			}, 1);
		} else {
			// 로그인
			setToken({token: payload.token});
		}
	});

	const openLogin = useCallback(type => {
		const popup = window.open(`${BASE_URL}/social/login/${type}`, "_blank");
		popup?.focus();
	}, []);

	return (
		<IonPage className="pg-login-base">
			<HeaderBase title="" backHref="/" />
			<IonContent fullscreen>
				<div className="content-inner">
					<IonRow className="login-logo">
						<IonCol className="logo">
							<div className="img">
								<img src="/assets/img/img-login.svg" alt="" />
							</div>
						</IonCol>
					</IonRow>
					<div className="login-wrap">
						<IonGrid className="ion-padding">
							<IonRow>
								<IonCol>
									<IonButton
										className="btn-kakao"
										expand="block"
										onClick={() => openLogin("kakao")}
										mode="md"
										shape="round"
									>
										<img src="assets/icon/login_kakao.png" alt="kakao" className="icon" />
										카카오로 시작하기
									</IonButton>

									<IonButton
										className="btn-naver"
										expand="block"
										onClick={() => openLogin("naver")}
										mode="md"
										shape="round"
									>
										<img src="assets/icon/login_naver.png" alt="kakao" className="icon" />
										네이버로 시작하기
									</IonButton>

									<IonButton
										className="btn-google"
										expand="block"
										onClick={() => openLogin("google")}
										mode="md"
										shape="round"
									>
										<img src="assets/icon/login_google.png" alt="kakao" className="icon" />
										구글로 시작하기
									</IonButton>
									{/* <div className="divide">OR</div> */}
									<IonButton
										onClick={() => history.push("/login/email")}
										className="btn-email"
										expand="block"
										mode="md"
										shape="round"
									>
										<img src="assets/icon/login_mail.png" alt="kakao" className="icon" />
										이메일로 시작하기
									</IonButton>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonCol>
									<p>
										SDOC에 처음 오셨나요?
										<span onClick={() => history.push("/sign-up")} className="reg">
											회원가입
										</span>
									</p>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Login;
