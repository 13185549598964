/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useState} from "react";
import {IonContent, IonPage} from "@ionic/react";
import {RouteComponentProps, useLocation} from "react-router";
import {useRecoilState} from "recoil";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {HeaderBase, SwitchCase} from "../../../components";
import {useApi} from "../../../hooks/api";
import {useAuth} from "../../../hooks/auth";
import {
	centerFaceCamMode,
	clinicSearchTypeState,
	leftFaceCamMode,
	rightFaceCamMode,
} from "../../../store/atoms";
import {bannerSliderOption, mainBannerImg} from "../../configs";
import {BannerType} from "../../../store/types";

const ResultPage: React.FC<RouteComponentProps> = ({history}) => {
	const location: any = useLocation();
	const analysisKey = location.state?.key;

	const {analysisProcessApi, processApi} = useApi();
	const {user} = useAuth();

	const keyValue = localStorage.getItem("analysisKey");

	const [data, setData] = useState<any>([]);
	const [diseaseData, setDiseaseData] = useState<any>();
	const [analysisImgData, setAnalysisImgData] = useState<any>([]);
	const [skinwikiData, setSkinwikiData] = useState<any>([]);
	const [clinicSearchType, setClinicSearchType] = useRecoilState(clinicSearchTypeState);

	const [centerFaceMode] = useRecoilState(centerFaceCamMode);
	const [leftFaceMode] = useRecoilState(leftFaceCamMode);
	const [rightFaceMode] = useRecoilState(rightFaceCamMode);

	const [adBannerList, setAdBannerList] = useState<BannerType>();
	const [bannerAdType, setBannerAdType] = useState<number>(1);
	const [bannerSectionType, setBannerSectionType] = useState<number>(4);

	const paginationOption = {
		customPaging: function (slider: any) {
			return (
				<span>
					{slider + 1}/{adBannerList?.totalCount}
				</span>
			);
		},
	};

	const getData = async () => {
		try {
			const {data} = await analysisProcessApi<any>("analysis-result", {
				analysisKey: analysisKey ? analysisKey : keyValue,
			});

			setData(data.resultPayload.moonjinSubmitLabelPageDtos);
			setDiseaseData(data.resultPayload.diseaseClazz);
			setAnalysisImgData(data.userAnalysisImage.imageList);
			setSkinwikiData(data.skinwikiList);
		} catch (error) {
			console.log(error);
		}
	};

	const getdBannerList = async () => {
		let params = {
			adType: bannerAdType,
			sectionType: bannerSectionType,
		};

		try {
			const data = await processApi<any>("ad", params);
			setAdBannerList(data);
		} catch (e) {
			console.log(e);
		}
	};

	const handleMoveToAnalysisPage = async () => {
		history.push("/t/skin-analysis");
	};

	const handleMoveToVideoPage = (value: any) => {
		if (data) {
			history.push({pathname: "/t/video", state: {videoFilterCategory: value}});
		}
	};

	const handleMoveToHospitalPage = () => {
		setClinicSearchType("Around");
		history.push({pathname: "/t/hospital", state: {hospitalSearchType: clinicSearchType}});
	};

	const handleMoveToInfoPage = (value: any) => {
		if (data) {
			history.push({pathname: "/t/info", state: {infoFilterCategory: value}});
		}
	};

	const handleClass = useCallback(
		(tag: any, mirrorMode: any): any => {
			if (tag === "center" && mirrorMode === false) {
				return "reverse";
			} else if (tag === "left" && mirrorMode === false) {
				return "reverse";
			} else if (tag === "right" && mirrorMode === false) {
				return "reverse";
			} else return "";
		},
		[centerFaceMode, leftFaceMode, rightFaceMode],
	);

	useEffect(() => {
		getData();
		getdBannerList();
	}, []);

	return (
		<IonPage>
			<HeaderBase
				title={analysisKey ? "피부 기록" : " 내 피부 분석 결과"}
				backMode={analysisKey ? true : false}
			/>
			<IonContent>
				<div className="content-inner analysis-result__wrap">
					{/** 분석 결과 타이틀 */}
					<div className="title-area">
						<div className="title">
							<h2>
								<span className="name">{user?.realName}</span> 님의 <br />
								피부 분석 결과 <span className="highlight">{diseaseData?.length}가지 질환</span>이 의심됩니다.
							</h2>
						</div>
					</div>

					{/** 예상질환 리스트 */}
					<div className="analysis-result__summary">
						<div className="summary-area">
							<div className="summary">
								<div className="text-inner">
									{diseaseData?.map((item: any, index: any) => (
										<h3 key={index}>
											{`${diseaseData?.length > 1 && index !== 0 ? ", " : ""}`} {item.text}
										</h3>
									))}
								</div>
								<div className={`photo-area ${analysisImgData?.length === 1 ? "only" : ""}`}>
									{analysisImgData?.map((item: any, index: any) => (
										<div className="photo" key={index}>
											<img src={item.url} alt={item.tag} className={handleClass(item.tag, item.isMirrored)} />
										</div>
									))}
								</div>
							</div>
						</div>
					</div>

					{/** 예상질환 관련 정보/안내 */}
					<div className="analysis-result__contents">
						<div className="contents">
							<div className="info-area">
								<i className="icon-hospital" />
								<p className="info">
									예상 질환은 <span>{user?.realName}</span> 님의 <span className="block" />
									현재 증상들과 유사한 질병 목록입니다. <br />
									정확한 진단은 반드시&nbsp;
									<span className="highlight">전문적인 의료기관을 방문하여 진료</span>를 받으시길 바랍니다.
								</p>
							</div>

							{/** 가까운 피부과 추천 */}
							<div className="analysis-result__hospital">
								<div className="button-area">
									<button className="btn" onClick={handleMoveToHospitalPage}>
										가까운 피부과 병원 추천해 드릴까요?
										<i className="icon-arrow icon-arrow-white" />
									</button>
								</div>
							</div>

							{/** 배너 영역 */}
							{adBannerList && (
								<Slider {...bannerSliderOption} {...paginationOption} className="banner-slider">
									{adBannerList?.detailList?.map((item, index) => (
										<div key={index}>
											<img
												src={item.fileUrl}
												onClick={() => (item.targetUrl ? window.open(item.targetUrl) : undefined)}
												alt="ad"
											/>
										</div>
									))}
								</Slider>
							)}

							{/** 질환 정보 리스트 */}
							<div className="analysis-result__list">
								{skinwikiData?.map((item: any, index: any) => (
									<div className="list-area" key={index}>
										<div className="list-title">
											<h3 key={index}>{item.skinwiki?.category?.name}</h3>
											<button onClick={() => handleMoveToInfoPage(item.skinwiki?.category?.key)}>
												<i className="icon-guide" />
												질환 정보
											</button>
										</div>
										<div className="photo-area">
											{item.skinwiki?.imageList.map((img: any, index: any) => (
												<div className="photo" key={index}>
													<img src={img.url} alt="" />
												</div>
											))}
										</div>
										<div className="info-area">
											<p>{item.skinwiki?.description}</p>
										</div>

										<div className="button-area">
											<button
												className="btn"
												onClick={() => handleMoveToVideoPage(item.skinwiki?.category?.key)}
											>
												관련 톡톡 피부 영상을 보러 갈까요?
												<i className="icon-arrow icon-arrow-black" />
											</button>
										</div>
									</div>
								))}
							</div>

							<SwitchCase
								tests={[
									{
										test: analysisKey,
										component: <></>,
									},
								]}
								defaultComponent={
									<div className="btn-bottom btn-wrap">
										<button className="button" onClick={handleMoveToAnalysisPage}>
											내 피부 분석 다시 하기
										</button>
										<button className="button" onClick={() => history.push("/t/skin-log")}>
											내 피부 변화 보기
										</button>
									</div>
								}
							/>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ResultPage;
