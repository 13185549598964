/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import {
	IonPage,
	IonContent,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonSegment,
	IonSegmentButton,
	IonActionSheet,
	IonChip,
} from "@ionic/react";
import {useLocation} from "react-router";
import {useRecoilState, useSetRecoilState} from "recoil";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HeaderBase from "../../components/HeaderBase";
import {
	searchPageTypeState,
	videoCategoryIdState,
	videoCategoryListState,
	videoFilterIdState,
	videoListState,
	videoPageIndexState,
} from "../../store/atoms";
import {Video, CategoryDto, ListData, BannerType} from "../../store/types";
import {useApi} from "../../hooks/api";
import VideoItem from "../../components/VideoItem";
import {bannerSliderOption} from "../configs";

type Order = "DATE" | "SCORE";

const SkinVideo: React.FC = () => {
	const location: any = useLocation();
	const [videoFilterCategory, setVideoFilterCategory] = useState<any>(location.state?.videoFilterCategory);

	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const pageSize = 10;
	const [pageIndex, setPageIndex] = useRecoilState(videoPageIndexState);

	const [list, setList] = useRecoilState(videoListState);
	const [categoryList, setCategoryList] = useRecoilState(videoCategoryListState);
	const [filterId, setFilterId] = useRecoilState(videoFilterIdState);

	const [adBannerList, setAdBannerList] = useState<BannerType>();
	const [bannerAdType, setBannerAdType] = useState<number>(1);
	const [bannerSectionType, setBannerSectionType] = useState<number>(7);

	const setSearchPageType = useSetRecoilState(searchPageTypeState);

	const [categoryExpanded, setCategoryExpanded] = useState<boolean>(false);
	const [orderType, setOrderType] = useState<Order>("DATE"); // "DATE" 최신 | "SCORE" 인기
	const [showActionSheet, setShowActionSheet] = useState(false);
	const [categoryId, setCategoryId] = useRecoilState(videoCategoryIdState);

	const {processApi} = useApi();

	const paginationOption = {
		customPaging: function (slider: any) {
			return (
				<span>
					{slider + 1}/{adBannerList?.totalCount}
				</span>
			);
		},
	};

	const fetchCategories = useCallback(async () => {
		let data: any[] | null; // :CategoryDto[] = []
		try {
			data = await processApi<CategoryDto[]>("video-category");
		} catch (error) {
			data = null;
		}

		let categories = [{key: "-1", name: "전체"}];
		if (data != null) {
			categories.push(...data);
		}
		setCategoryList(categories);
		setCategoryId(categories[0].key);
	}, []);

	const fetchList = useCallback(
		async (sortType, category, pageIndex: number) => {
			let params: any = {pageIndex: pageIndex, pageSize: pageSize, sortType: sortType};
			if (category !== "-1" && filterId && filterId !== "-1") {
				params["category"] = filterId;
			} else if (category !== "-1" && !filterId) {
				params["category"] = category;
			}

			const data = await processApi<any>("video", params);

			setList((prev: ListData<any>) => {
				if (pageIndex === 0) return data;
				else return {total: data.total, list: [...prev.list, ...data.list]};
			});

			setPageIndex(prev => (prev = pageIndex + 1));
		},
		[filterId, categoryId],
	);

	const getdBannerList = async () => {
		let params = {
			adType: bannerAdType,
			sectionType: bannerSectionType,
		};

		try {
			const data = await processApi<any>("ad", params);
			setAdBannerList(data);
		} catch (e) {
			console.log(e);
		}
	};

	const moveScroll = (key: string) => {
		const offsetLeft = document.getElementById(`segmentButton${key}`)?.offsetLeft ?? 0;
		const clientWidth = document.getElementById(`segmentButton${key}`)?.clientWidth ?? 0;
		let innerWidth: number = window.innerWidth;
		if (innerWidth > 480) innerWidth = 480; // PC max-width 480px
		document.getElementById("segment")?.scroll(offsetLeft - innerWidth / 2 + clientWidth / 2, 0);
	};

	function scrollLeft() {
		document.getElementById("segment")!.scrollLeft -= 100;
	}

	function scrollRight() {
		document.getElementById("segment")!.scrollLeft += 100;
	}

	const handleClass = (key: any) => {
		if (filterId && key === filterId) {
			return "selected";
		} else if (!filterId && !videoFilterCategory && key === categoryId) {
			return "selected";
		} else {
			return "";
		}
	};

	const handleClassSubMenu = (key: any) => {
		if (filterId && key === filterId) {
			return "active";
		} else if (!filterId && !videoFilterCategory && key === categoryId) {
			return "active";
		} else {
			return "";
		}
	};

	useEffect(() => {
		fetchCategories();
		setSearchPageType("video");
		getdBannerList();
	}, []);

	useEffect(() => {
		moveScroll(filterId ? filterId : categoryId);
	});

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	useEffect(() => {
		if (videoFilterCategory) {
			setFilterId(videoFilterCategory);
		} else {
			setFilterId(null);
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (!videoFilterCategory && !filterId) {
				fetchList(orderType, categoryId, 0);
			} else {
				fetchList(orderType, filterId, 0);
			}
		}, 50);
	}, [filterId, orderType, categoryId]);

	return (
		<IonPage className="pg-skin-video">
			<HeaderBase title="톡톡 피부 영상" enableSearch={true} />
			<div className="menu-wrap">
				{/* 피부증상 메뉴 */}
				<IonSegment
					id="segment"
					className="menu"
					slot="start"
					scrollable
					value={filterId ? filterId : categoryId}
					mode="md"
					onIonChange={event => {
						const id = event.detail.value ?? "";
						setCategoryId(id);
						if (videoFilterCategory) {
							setFilterId(id);
						}
					}}
				>
					{categoryList.map((x, index) => (
						<IonSegmentButton
							id={`segmentButton${x.key}`}
							key={`category_button_${x.key}`}
							value={x.key}
							mode="md"
							className={filterId ? handleClassSubMenu(x.key) : handleClass(x.key)}
							onClick={() => {
								setCategoryExpanded(false);
								moveScroll(filterId ? filterId : x.key);
							}}
						>
							{x.name}
						</IonSegmentButton>
					))}
					<IonSegmentButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</IonSegmentButton>
				</IonSegment>

				<div className="menu-all">
					<img
						slot="end"
						src={categoryExpanded ? "assets/icon/icons_expand_less.png" : "assets/icon/icons_expand_more.png"}
						alt="more"
						className="icon"
						onClick={() => setCategoryExpanded(prev => !prev)}
					/>
				</div>

				<div className="menu-before">
					<img
						slot="end"
						src="assets/icon/icons_navigate_before.png"
						alt="prev"
						className="icon"
						onClick={() => scrollLeft()}
					/>
				</div>

				<div className="menu-next">
					<img
						slot="end"
						src="assets/icon/icons_navigate_next.png"
						alt="next"
						className="icon"
						onClick={() => scrollRight()}
					/>
				</div>

				{categoryExpanded ? (
					<IonSegment slot="start" className="wrap menu-contents" mode="md">
						{categoryList.map((category, index) => (
							<IonChip
								key={`category_segment_${category.key}`}
								outline
								onClick={() => {
									setCategoryExpanded(false);
									setCategoryId(category.key);
									if (videoFilterCategory) {
										setFilterId(category.key);
									}
									moveScroll(category.key);
								}}
								className={handleClass(category.key)}
							>
								<IonLabel color="primary">{category.name}</IonLabel>
							</IonChip>
						))}
					</IonSegment>
				) : (
					<></>
				)}
			</div>
			<IonContent fullscreen>
				<div className="content-inner">
					{adBannerList && (
						<Slider {...bannerSliderOption} {...paginationOption} className="banner-slider">
							{adBannerList?.detailList?.map((item, index) => (
								<div key={index}>
									<img
										src={item.fileUrl}
										onClick={() => (item.targetUrl ? window.open(item.targetUrl) : undefined)}
										alt="ad"
									/>
								</div>
							))}
						</Slider>
					)}

					<IonItem
						className="order ion-padding"
						onClick={() => {
							setShowActionSheet(true);
						}}
					>
						<IonLabel slot="end">{orderType === "DATE" ? "최신순" : "인기순"}</IonLabel>
						<img slot="end" src="assets/icon/icons_arrow_down.png" alt="order" className="icon" />
					</IonItem>
					{(list?.list?.length ?? 0) === 0 ? (
						<div className="no-result">
							{videoFilterCategory ? videoFilterCategory + "에 대한" : ""} 조회 결과가 없습니다.
						</div>
					) : (
						<></>
					)}
					<IonList>
						{list?.list?.map((video: Video, index) => {
							return <VideoItem video={video} key={`video_${video.id}`} />;
						})}
						{(list?.list?.length ?? 0) > 0 && (list?.total ?? 0) > pageIndex * pageSize ? (
							<IonItem className="btn-more" onClick={() => fetchList(orderType, categoryId, pageIndex)}>
								<p>
									더보기<em></em>
								</p>
							</IonItem>
						) : (
							<></>
						)}
					</IonList>
				</div>
			</IonContent>

			<IonActionSheet
				isOpen={showActionSheet}
				onDidDismiss={() => setShowActionSheet(false)}
				cssClass="my-custom-class"
				buttons={[
					{
						text: "최신순",
						handler: () => {
							setOrderType("DATE");
						},
					},
					{
						text: "인기순",
						handler: () => {
							setOrderType("SCORE");
						},
					},
				]}
			></IonActionSheet>
		</IonPage>
	);
};

export default SkinVideo;
