import {
	IonButton,
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonPage,
	IonGrid,
	IonRow,
	IonCol,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import {useState, useRef} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {useRecoilValue} from "recoil";
import HeaderBase from "../../components/HeaderBase";
import {emailDomainState} from "../../store/atoms";
import {ErrorMessage} from "@hookform/error-message";

interface FindPasswordInputProps {
	onNext: (form: any) => void;
}

interface FormData {
	username?: string;
	emailEnd1?: string;
	emailEnd2?: string;
}

const FindPasswordInput: React.FC<FindPasswordInputProps> = ({onNext}) => {
	const [emailEnd, setEmailEnd] = useState<string>();
	const emailDomainList = useRecoilValue(emailDomainState);

	const {
		control,
		handleSubmit,
		watch,
		formState: {errors},
	} = useForm<FormData>({
		defaultValues: {username: "", emailEnd2: "", emailEnd1: ""},
	});

	const onSubmit: SubmitHandler<FormData> = async data => {
		let email = "";
		if (data.emailEnd2 === "0") {
			email = `${data.username}@${data.emailEnd1}`;
		} else {
			email = `${data.username}@${data.emailEnd2}`;
		}
		onNext(email);
	};

	const domain: any = useRef();
	domain.current = watch("emailEnd1");

	return (
		<IonPage className="pg-sign-up pg-vrf">
			<HeaderBase title="비밀번호 찾기" backHref="/" />
			<IonContent fullscreen>
				<div className="content-inner">
					<form onSubmit={handleSubmit(onSubmit)}>
						<IonGrid className="ion-padding pg-find-pw">
							<IonRow>
								<IonCol>
									<IonItem>
										<IonLabel position="stacked">이메일</IonLabel>
										<Controller
											name="username"
											control={control}
											rules={{
												validate: value =>
													1 + //@
														(value?.length ?? 0) +
														(emailEnd === "0" ? domain.current.length : emailEnd?.length ?? 0) <=
													40,
												required: true,
												pattern: {
													value: /^[A-Z0-9._%+-]{1,40}$/i /*+@[A-Z0-9.-]+\.[A-Z] */,
													message: "이메일 형식을 확인해주세요.",
												},
											}}
											render={({field}) => (
												<IonInput
													{...field}
													onIonChange={field.onChange}
													placeholder="이메일을 입력해주세요."
													className={errors.username === undefined ? "input-email" : "error input-email"}
												/>
											)}
										/>
										<span className="email">@</span>
									</IonItem>

									{emailEnd === "0" && (
										<IonItem className="item-email-end">
											<IonLabel position="stacked"></IonLabel>
											<Controller
												name="emailEnd1"
												control={control}
												rules={{
													required: true,
													pattern: {
														value: /^[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
														message: "이메일 형식을 확인해주세요.",
													},
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={40}
														onIonChange={field.onChange}
														placeholder="이메일 형식을 입력해주세요."
														className={errors.emailEnd1 === undefined ? "" : "error"}
													/>
												)}
											/>
										</IonItem>
									)}

									{/* 이메일 선택 검증 컨트롤러 */}
									<IonItem style={{display: "none"}}>
										<Controller
											name="emailEnd2"
											control={control}
											rules={{
												required: true,
											}}
											render={({field}) => (
												<IonInput {...field} value={emailEnd} onIonChange={field.onChange} />
											)}
										/>
									</IonItem>

									{/* 이메일 도메인 선택 */}
									<IonItem className="select-email">
										<IonSelect
											value={emailEnd}
											interface="action-sheet"
											placeholder="선택"
											cancelText="취소"
											onIonChange={e => setEmailEnd(e.detail.value)}
											className={errors.emailEnd2 === undefined ? "" : emailEnd === "0" ? "" : "error"}
										>
											<IonLabel position="stacked">이메일 선택</IonLabel>
											{emailDomainList?.map((domain: any, index) => (
												<IonSelectOption key={index} value={domain.id}>
													{domain.domain}
												</IonSelectOption>
											))}
										</IonSelect>
									</IonItem>

									{errors.username !== undefined ? (
										<ErrorMessage
											errors={errors}
											message="이메일 형식을 확인해주세요."
											name="username"
											as={<div className="error-message" />}
										/>
									) : (
										<div>
											<ErrorMessage
												errors={errors}
												name="emailEnd1"
												message="이메일을 입력해주세요."
												as={<div className="error-message" />}
											/>
											<ErrorMessage
												errors={errors}
												name="emailEnd2"
												message="이메일을 선택해주세요."
												as={<div className="error-message" />}
											/>
										</div>
									)}
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton className="" type="submit" expand="full" mode="md" shape="round">
										다음
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					</form>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default FindPasswordInput;
