/* eslint-disable react-hooks/exhaustive-deps */
import {IonButton, IonContent, IonPage, IonRow} from "@ionic/react";
import {useCallback, useEffect} from "react";
import {RouteComponentProps} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import shortid from "shortid";
import {HeaderBase} from "../../../../components";
import {useApi} from "../../../../hooks/api";
import {
	analysisBodyPhoto,
	analysisGuidePopupState,
	analysisKey,
	analysisState,
} from "../../../../store/atoms";

const UploadResultPage: React.FC<RouteComponentProps> = ({history}) => {
	const {analysisProcessApi} = useApi();

	const analysisValue = useRecoilValue(analysisState);
	const [bodyPhoto, setBodyPhoto] = useRecoilState(analysisBodyPhoto);
	const [analysisKeyValue, setAnalysisKeyValue] = useRecoilState(analysisKey);
	const [showGuidePopup, setShowGuidePopup] = useRecoilState(analysisGuidePopupState);

	const handleRetakePhoto = () => {
		setBodyPhoto([]);
		history.push("/skin-analysis/upload/body");
	};

	const savePhoto = useCallback(async () => {
		try {
			const {data} = await analysisProcessApi<any>("attach-file", bodyPhoto);

			localStorage.setItem("analysisKey", encodeURI(data.analysisKey));
			setAnalysisKeyValue(encodeURI(data.analysisKey));

			if (analysisValue) {
				history.push("/skin-analysis/question");
			}
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		setShowGuidePopup(false);
	}, []);

	const renderPhotoMode = () => {
		return (
			<div className="analysis-upload__photo">
				<div className="photo-area">
					{bodyPhoto.map(item => (
						<img src={item.contentBase64} alt="" key={shortid.generate()} />
					))}
				</div>
				<p className="analysis-upload__photo--info">
					이 사진들로 분석을 진행하시겠습니까?
					<br />
					원하시면 확인 버튼을 눌러주세요.
				</p>
				<div className="btn-bottom__single btn-wrap">
					<IonRow>
						<IonButton expand="full" shape="round" onClick={savePhoto}>
							확인
						</IonButton>
					</IonRow>
				</div>
				<IonRow className="btn-bottom__single analysis-upload__photo--button">
					<button className="btn btn-retake" onClick={handleRetakePhoto}>
						다시 찍기
					</button>
				</IonRow>
			</div>
		);
	};

	return (
		<IonPage className="analysis-upload">
			<HeaderBase />
			<IonContent fullscreen>{renderPhotoMode()}</IonContent>
		</IonPage>
	);
};

export default UploadResultPage;
