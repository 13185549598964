import {atom, AtomEffect, DefaultValue} from "recoil";
import {recoilPersist} from "recoil-persist";
import {
	User,
	Token,
	TempUser,
	ListData,
	Clinic,
	SkinWiki,
	Video,
	AnsweredSurvey,
	SearchPageType,
	RecentSearchItem,
	ClinicFilter,
	NoticeDto,
	FaqDto,
	TermsDto,
	CategoryDto,
	ClinicSearchType,
	UserDetail,
	EmailDomain,
	MapViewInfo,
	AnalysisType,
	AnalysisPartType,
	SkinInfo,
	PhotoUpload,
	MainSkinInfoList,
	CompareAnalysisKey,
} from "./types";
const {persistAtom} = recoilPersist();

const localStorageEffect: <T>(key: string) => AtomEffect<T> =
	(key: string) =>
	({setSelf, onSet}) => {
		const savedValue = localStorage.getItem(key);
		if (savedValue != null) {
			try {
				setSelf(JSON.parse(savedValue));
			} catch (e) {
				localStorage.removeItem(key);
				setSelf(new DefaultValue());
			}
		}
		onSet((newValue: any) => {
			if (newValue instanceof DefaultValue || newValue == null) {
				localStorage.removeItem(key);
			} else {
				localStorage.setItem(key, JSON.stringify(newValue));
			}
		});
	};

export const tokenState = atom<Token>({
	key: "tokenState",
	default: null as any,
	effects_UNSTABLE: [localStorageEffect("accessToken")],
});

export const userState = atom<User>({
	key: "userState",
	default: null as any,
});

export const userDetailState = atom<UserDetail>({
	key: "userDetailState",
	default: null as any,
});

// 검색
export const enableRecentSearchState = atom<boolean>({
	key: "enableRecentSearchState",
	default: true,
	effects_UNSTABLE: [localStorageEffect("enableRecentSearch")],
});

// 검색 로그
export const recentClinicListState = atom<RecentSearchItem[]>({
	key: "recentClinicListState",
	default: [],
	effects_UNSTABLE: [localStorageEffect("recentClinicListState")],
});

export const recentVideoListState = atom<RecentSearchItem[]>({
	key: "recentVideoListState",
	default: [],
	effects_UNSTABLE: [localStorageEffect("recentVideoListState")],
});

export const recentWikiListState = atom<RecentSearchItem[]>({
	key: "recentWikiListState",
	default: [],
	effects_UNSTABLE: [localStorageEffect("recentWikiListState")],
});

export const recentInfoListState = atom<RecentSearchItem[]>({
	key: "recentInfoListState",
	default: [],
	effects_UNSTABLE: [localStorageEffect("recentInfoListState")],
});

export const searchPageTypeState = atom<SearchPageType | null>({
	key: "searchPageTypeState",
	default: null,
});

export const tempUserState = atom<TempUser>({
	key: "tempUserState",
	default: null as any,
});

// 병원찾기
export const lastFetchLevelState = atom<number>({
	key: "lastFetchLevelState",
	default: 0,
});

export const clinicAroundListState = atom<Clinic[]>({
	key: "clinicAroundListState",
	default: [],
});

export const clinicListState = atom<Clinic[]>({
	key: "clinicListState",
	default: [],
});

export const clinicSingleState = atom<Clinic | null>({
	key: "clinicSingleState",
	default: null,
});

export const clinicIdState = atom({
	key: "clinicIdState",
	default: null,
});

export const selectedClinicState = atom<Clinic | undefined>({
	key: "selectedClinicState",
	default: undefined,
});

// 1분영상
export const videoPageIndexState = atom<number>({
	key: "videoPageIndexState",
	default: 0,
});

export const videoListState = atom<ListData<Video>>({
	key: "videoListState",
	default: {list: [], total: 0},
});

export const videoCategoryIdState = atom<string>({
	key: "videoCategoryIdState",
	default: "-1",
});

export const videoCategoryListState = atom<CategoryDto[]>({
	key: "videoCategoryListState",
	default: [],
});

export const videoSingleState = atom<Video | null>({
	key: "videoSingleState",
	default: null,
});

export const videoFilterIdState = atom<any>({
	key: "videoFilterIdState",
	default: null,
});

// 백과사전
export const wikiPageIndexState = atom<number>({
	key: "wikiPageIndexState",
	default: 0,
});

export const wikiListState = atom<ListData<SkinWiki>>({
	key: "wikiListState",
	default: {list: [], total: 0},
});

export const wikiIdState = atom({
	key: "wikiIdState",
	default: -1,
});

export const wikiSingleState = atom<SkinWiki | null>({
	key: "wikiSingleState",
	default: null,
});

export const answeredSurveyState = atom<AnsweredSurvey[]>({
	key: "answeredSurveyState",
	default: [],
});

export const clinicFilterState = atom<ClinicFilter>({
	key: "clinicFilterState",
	default: {radius: 1500, onlySpecilist: false},
});

// 카테고리
export const categoryListState = atom<CategoryDto[]>({
	key: "categoryListState",
	default: [],
});

// 검색
export const searchPageIndexState = atom<number>({
	key: "searchPageIndexState",
	default: 0,
});

export const searchClinicListState = atom<ListData<Clinic>>({
	key: "searchClinicListState",
	default: {list: [], total: 0},
});

export const searchVideoListState = atom<ListData<Video>>({
	key: "searchVideoListState",
	default: {list: [], total: 0},
});

export const searchWikiListState = atom<ListData<SkinWiki>>({
	key: "searchWikiListState",
	default: {list: [], total: 0},
});

export const searchInfoListState = atom<ListData<SkinInfo>>({
	key: "searchInfoListState",
	default: {list: [], total: 0},
});

// 북마크
export const bookmarkVideoListState = atom<Video[]>({
	key: "bookmarkVideoListState",
	default: [],
});

export const bookmarkClnicListState = atom<Clinic[]>({
	key: "bookmarkClnicListState",
	default: [],
});

export const bookmarkInfoListState = atom<SkinInfo[]>({
	key: "bookmarkInfoListState",
	default: [],
});

// 공지사항
export const noticeListState = atom<NoticeDto[]>({
	key: "noticeListState",
	default: [],
});

export const noticeSingleState = atom<NoticeDto>({
	key: "noticeSingleState",
	default: {id: -1},
});

// 자주묻는질문
export const faqListState = atom<FaqDto[]>({
	key: "faqListState",
	default: [],
});

export const faqSingleState = atom<FaqDto>({
	key: "faqSingleState",
	default: {id: -1},
});

export const faqCategoryListState = atom<CategoryDto[]>({
	key: "faqCategoryListState",
	default: [],
});

// 약관
export const termsListState = atom<TermsDto[]>({
	key: "termsListState",
	default: [],
});

export const termsSingleState = atom<TermsDto>({
	key: "termsSingleState",
	default: {id: -1},
});

export const isOpenClinicModalState = atom<boolean>({
	key: "isOpenClinicModalState",
	default: false,
});

export const clinicSearchTypeState = atom<ClinicSearchType>({
	key: "clinicSearchTypeState",
	default: "Map",
});

export const emailDomainState = atom<EmailDomain[]>({
	key: "emailDomainState",
	default: [
		{
			id: "gmail.com",
			domain: "gmail.com",
		},
		{
			id: "naver.com",
			domain: "naver.com",
		},
		{
			id: "daum.net",
			domain: "daum.net",
		},
		{
			id: "nate.com",
			domain: "nate.com",
		},
		{
			id: "0",
			domain: "직접입력",
		},
	],
});

export const mapViewInfoAroundMe = atom<MapViewInfo | undefined>({
	key: "mapViewInfoAroundMe",
	default: undefined,
});

export const mapViewInfoByMap = atom<MapViewInfo | undefined>({
	key: "mapViewInfoByMap",
	default: undefined,
});

/**
 * 내 피부 분석
 */

export const analysisKey = atom<string | any>({
	key: "analysisKey",
	default: null as any,
});

// 얼굴 위치
export const faceLocation = atom<string>({
	key: "faceLocation",
	default: "center",
});

// 얼굴 사진 - 정면
export const analysisFaceCenter = atom<PhotoUpload | null>({
	key: "analysisFaceCenter",
	default: null,
});

// 얼굴 사진 - 좌측
export const analysisFaceLeft = atom<PhotoUpload | null>({
	key: "analysisFaceLeft",
	default: null,
});

// 얼굴 사진 - 우측
export const analysisFaceRight = atom<PhotoUpload | null>({
	key: "analysisFaceRight",
	default: null,
});

// 얼굴 사진 업로드
export const analysisFacePhotoList = atom<any[]>({
	key: "analysisFacePhotoList",
	default: [],
});

// 신체 사진 업로드
export const analysisBodyPhoto = atom<PhotoUpload[]>({
	key: "analysisBodyPhoto",
	default: [],
});

// 얼굴 촬영 모드(정면/전환)
export const faceCamMode = atom<string>({
	key: "faceCamMode",
	default: "user",
});
export const centerFaceCamMode = atom<string>({
	key: "centerFaceCamMode",
	default: "user",
});
export const leftFaceCamMode = atom<string>({
	key: "leftFaceCamMode",
	default: "user",
});
export const rightFaceCamMode = atom<string>({
	key: "rightFaceCamMode",
	default: "user",
});

// 모두 다시 찍기/하나만 찍기(얼굴)
export const takeCamMode = atom<string>({
	key: "takeCamMode",
	default: "",
});

// 정밀/빠른 선택
export const analysisState = atom<AnalysisType | any>({
	key: "analysisState",
	default: null as any,
});

// 얼굴/신체 선택
export const analysisPartState = atom<AnalysisPartType | any>({
	key: "analysisPartState",
	default: null as any,
});

// 분석 가이드 팝업
export const analysisGuidePopupState = atom<boolean>({
	key: "analysisGuidePopupState",
	default: true,
});

// 피부 기록
export const logPageIndexState = atom<number>({
	key: "logPageIndexState",
	default: 1,
});

export const logListState = atom<ListData<Video>>({
	key: "videoListState",
	default: {list: [], total: 0},
});

export const totalCountGroupState = atom<number>({
	key: "totalCountGroupState",
	default: 0,
});

export const compareLogState = atom<CompareAnalysisKey | any>({
	key: "compareLogData",
	default: [],
	effects_UNSTABLE: [persistAtom],
});

export const guidePopupState = atom<null | any>({
	key: "guidePopupState",
	default: null as any,
	effects_UNSTABLE: [localStorageEffect("guidePopup")],
});

export const logTypeState = atom<string>({
	key: "logTypeState",
	default: "face",
});

// 쏙쏙피부정보
export const infoPageIndexState = atom<number>({
	key: "infoPageIndexState",
	default: 0,
});

export const infoListState = atom<ListData<SkinInfo>>({
	key: "infoListState",
	default: {list: [], total: 0},
});

export const infoCategoryIdState = atom<string>({
	key: "infoCategoryIdState",
	default: "-1",
});

export const infoCategoryListState = atom<CategoryDto[]>({
	key: "infoCategoryListState",
	default: [],
});

export const infoDetailState = atom<SkinInfo | null>({
	key: "infoDetailState",
	default: null,
});

export const infoFilterIdState = atom<any>({
	key: "infoIdState",
	default: null,
});

// 시뮬레이션
export const simulationImgState = atom<PhotoUpload[]>({
	key: "simulationImgState",
	default: [],
});

export const simulationImgListState = atom<any>({
	key: "simulationImgState",
	default: [],
	effects_UNSTABLE: [persistAtom],
});
