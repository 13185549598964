/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback, useState} from "react";
import {IonPage, IonList, IonLabel, IonContent, IonSegment, IonSegmentButton, IonButton} from "@ionic/react";
import {SetterOrUpdater, useRecoilState} from "recoil";
import {bookmarkClnicListState, bookmarkInfoListState, bookmarkVideoListState} from "../../store/atoms";
import {useHistory} from "react-router";
import HeaderBase from "../../components/HeaderBase";
import {useApi} from "../../hooks/api";
import {Clinic, Video, SkinInfo} from "../../store/types";
import VideoItem from "../../components/VideoItem";
import ClinicItem from "../../components/presentational/ClinicItem";
import {SkinInfoItem} from "../../components";

export type BookmarkType = "Video" | "Clinic" | "Info";

const Bookmark: React.FC = () => {
	const history = useHistory();

	const [videoList, setVideoList] = useRecoilState(bookmarkVideoListState);
	const [clnicList, setClnicList] = useRecoilState(bookmarkClnicListState);
	const [infoList, setInfoList] = useRecoilState(bookmarkInfoListState);

	const [isLoadedData, setIsLoadedData] = useState<boolean>(false);
	const [bookmarkType, setBookmarkType] = useState<BookmarkType>("Clinic");
	const {processApi} = useApi();

	const fetchData = useCallback(async (bookmarkType: BookmarkType) => {
		setIsLoadedData(false);
		let path = "";
		let setter: SetterOrUpdater<Video[]> | SetterOrUpdater<Clinic[]> | SetterOrUpdater<SkinInfo[]> | null =
			null;
		if (bookmarkType === "Video") {
			path = "video-fav";
			setter = setVideoList;
		} else if (bookmarkType === "Clinic") {
			path = "clinic-fav";
			setter = setClnicList;
		} else if (bookmarkType === "Info") {
			path = "info-fav";
			setter = setInfoList;
		}

		if (setter != null) {
			const data = await processApi<any>(path);
			setter(data);
		}
		setIsLoadedData(true);
	}, []);

	useEffect(() => {
		fetchData(bookmarkType);
	}, [bookmarkType]);

	return (
		<IonPage className="pg-bookmark">
			<HeaderBase title="나의 북마크" backHref="/"></HeaderBase>

			<IonSegment
				scrollable
				value={bookmarkType}
				mode="md"
				onIonChange={event => setBookmarkType(event.detail.value as BookmarkType)}
			>
				<IonSegmentButton value="Clinic">병원</IonSegmentButton>
				{/* <IonSegmentButton value="Video">톡톡 피부 영상</IonSegmentButton> */}
				<IonSegmentButton value="Info">쏙쏙 피부 정보</IonSegmentButton>
			</IonSegment>
			<IonContent>
				<div className="content-inner">
					{/* 영상 북마크 리스트 */}
					{isLoadedData && bookmarkType === "Video" && videoList.length === 0 ? (
						<IonLabel>
							<p className="ion-padding title">북마크한 영상이 없습니다.</p>
							<p className="ion-padding sub-title">관심있는 영상은 북마크를 눌러 보관하세요.</p>
						</IonLabel>
					) : (
						<></>
					)}
					{bookmarkType === "Video" ? (
						<IonList className="pg-skin-video">
							{videoList?.map((video, index) => (
								<VideoItem video={video} key={video.id}></VideoItem>
							))}
						</IonList>
					) : (
						<></>
					)}

					{/* 병원 북마크 리스트 */}
					{isLoadedData && bookmarkType === "Clinic" && clnicList.length === 0 ? (
						<IonLabel>
							<p className="ion-padding title">북마크한 병원이 없습니다.</p>
							<p className="ion-padding sub-title">관심있는 병원은 북마크를 눌러 보관하세요.</p>
						</IonLabel>
					) : (
						<></>
					)}
					{bookmarkType === "Clinic" ? (
						<IonList className="pg-skin-video">
							{clnicList?.map((clinic, index) => (
								<ClinicItem clinic={clinic} key={`clinic_${clinic.id}`} showBookmark={true}></ClinicItem>
							))}
						</IonList>
					) : (
						<></>
					)}

					{/* 쏙쏙 피부 정보 북마크 리스트 */}
					{isLoadedData && bookmarkType === "Info" && infoList.length === 0 ? (
						<IonLabel>
							<p className="ion-padding title">북마크한 쏙쏙 피부 정보가 없습니다.</p>
							<p className="ion-padding sub-title">관심있는 피부 정보는 북마크를 눌러 보관하세요.</p>
						</IonLabel>
					) : (
						<></>
					)}
					{bookmarkType === "Info" ? (
						<div className="pg-skin-info">
							<IonList className="info-contents">
								{infoList?.map((info, index) => (
									<SkinInfoItem info={info} key={info.id} />
								))}
							</IonList>
						</div>
					) : (
						<></>
					)}

					{/* Footer */}
					{isLoadedData &&
					((bookmarkType === "Video" && videoList?.length === 0) ||
						(bookmarkType === "Clinic" && clnicList?.length === 0) ||
						(bookmarkType === "Info" && infoList?.length === 0)) ? (
						<IonButton
							expand="full"
							className="ion-padding main-btn"
							mode="md"
							shape="round"
							onClick={() => {
								history.push(
									`${
										(bookmarkType === "Video" && "/t/video") ||
										(bookmarkType === "Clinic" && "/t/hospital") ||
										(bookmarkType === "Info" && "/t/info")
									}`,
								);
							}}
						>
							{(bookmarkType === "Video" && "톡톡 피부 영상 보러가기") ||
								(bookmarkType === "Clinic" && "병원 찾아보기") ||
								(bookmarkType === "Info" && "쏙쏙 피부 정보 보러가기")}
						</IonButton>
					) : (
						<></>
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Bookmark;
