/* eslint-disable react-hooks/exhaustive-deps */
import {IonPage, IonRow} from "@ionic/react";
import {useCallback, useEffect, useRef, useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import {useRecoilState, useSetRecoilState} from "recoil";
import {HeaderBase} from "../../../../components";
import {Modal, Webcam} from "../../../../components/presentational";
import {analysisBodyPhoto, analysisGuidePopupState} from "../../../../store/atoms";
import {bodyGuidePopupContents, faceModeEnv, faceModeUser} from "../../../configs";

const UploadPage: React.FC<RouteComponentProps> = ({history}) => {
	const camRef = useRef<any>(null);
	const audioRef = useRef<any>(null);
	const [photo, setPhoto] = useState(null);

	const setBodyPhotoState = useSetRecoilState(analysisBodyPhoto);

	// 촬영 가이드 팝업
	const [showGuidePopup, setShowGuidePopup] = useRecoilState(analysisGuidePopupState);

	// 촬영 모드 전환
	const videoConstraints = {
		facingMode: faceModeEnv,
	};
	const [facingMode, setFacingMode] = useState(faceModeEnv);

	// 가이드 팝업
	const openModal = () => {
		setShowGuidePopup(true);
	};

	const closeModal = () => {
		setShowGuidePopup(false);
	};

	// 카메라 전환
	const handleReverseCamera = useCallback(() => {
		setFacingMode(prevState => (prevState === faceModeEnv ? faceModeUser : faceModeEnv));
	}, []);

	// 카메라 셔터
	const handleCameraShutterAudio = () => {
		audioRef.current.play();
	};

	const handleTakePhoto = useCallback(() => {
		const img = camRef.current;

		if (img) {
			audioRef.current.play();
			const imgSrc = img.getScreenshot();
			setPhoto(imgSrc);
			setBodyPhotoState([{contentBase64: imgSrc, tag: "body"}]);
		} else {
			setPhoto(null);
			setBodyPhotoState([]);
		}
	}, [photo]);

	useEffect(() => {
		if (photo) {
			setTimeout(() => history.push("/skin-analysis/upload/body/result"), 400);
		}
	}, [photo]);

	useEffect(() => {
		setBodyPhotoState([]);
	}, []);

	const renderCameraMode = () => {
		return (
			<>
				<div className="content-padding analysis-upload__cam">
					<div className="camera">
						<Webcam
							camRef={camRef}
							handleTakePhoto={handleTakePhoto}
							photo={photo}
							videoConstraints={{
								...videoConstraints,
								facingMode,
							}}
							mirrorMode={facingMode === faceModeUser ? true : false}
						/>
						<div className="guideline-wrap">
							<div className="guideline body" />
						</div>
					</div>
				</div>
				<div className="content-margin analysis-upload__camera">
					<div className="guideline-state">
						<p>
							사각 가이드라인 안에 증상 부위가
							<br />잘 보이도록 찍어주세요!
						</p>
					</div>
					{/** Camera Controls */}
					<div className="camera-controls">
						<IonRow className="camera-controls__align">
							<button onClick={openModal} className="btn-area">
								<img src="assets/icon/icon_guide.svg" alt="" />
								<span>가이드</span>
							</button>
							<div className="ion-text-center camera-area">
								<button onClick={handleTakePhoto} />
							</div>
							<div className="ion-text-right">
								<button onClick={handleReverseCamera} className="btn-area">
									<img src="assets/icon/icon_camera_reverse.svg" alt="" />
									<span>전환</span>
								</button>
							</div>
						</IonRow>
					</div>
				</div>
			</>
		);
	};

	return (
		<IonPage className="analysis-upload">
			<HeaderBase backHref="/" backMode homeMode />

			{renderCameraMode()}

			{/** 촬영 가이드 팝업 */}
			<Modal
				isOpen={showGuidePopup}
				closeMode
				isClose={closeModal}
				isCloseComplete={closeModal}
				slideMode
				sliderContents={bodyGuidePopupContents}
				className="guide-popup"
			/>

			{/** 카메라 촬영 셔터 사운드 */}
			<audio src="assets/audio/camera-shutter.mp3" ref={audioRef} onPlay={handleCameraShutterAudio} />
		</IonPage>
	);
};

export default UploadPage;
