/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
	IonPage,
	IonAvatar,
	IonCol,
	IonContent,
	IonGrid,
	IonIcon,
	IonItem,
	IonLabel,
	IonRow,
	IonList,
} from "@ionic/react";
import {useRecoilState} from "recoil";

import HeaderBase from "../../components/HeaderBase";
import {videoSingleState} from "../../store/atoms";
import {useApi} from "../../hooks/api";
import default_Img from "../../img/example.png";
import default_Img_Doctor from "../../img/profile_default.png";
import ReactPlayer from "react-player/lazy";
import BookmarkButton from "../../components/container/BookmarkButton";
import VideoItem from "../../components/VideoItem";
import {DATE_FORMAT} from "../../utils/DateUtil";
import moment from "moment";

const SkinVideoDetail: React.FC = () => {
	const history = useHistory();
	const params: any = useParams();
	const [video, setVideo] = useRecoilState(videoSingleState);
	const [bookmark, setBookmark] = useState<any>(video?.isFav);
	const [date, setDate] = useState<any>();

	const {processApi} = useApi();
	const fetchAndSetVideoBookmark = useCallback(async () => {
		try {
			const data = await processApi<any>("video-item", {id: params.id});
			setVideo(data);
			setBookmark(data.isFav);

			const newDate = data.dateCreated.slice(0, 3).toString();
			setDate(newDate);
		} catch (error) {
			setVideo(null);
			setBookmark(null);
		}
	}, [params.id]);

	useEffect(() => {
		fetchAndSetVideoBookmark();

		return () => {
			setVideo(null);
			setBookmark(null);
		};
	}, [params.id]);

	const onErrorImg = (e: any) => {
		e.target.src = default_Img;
	};

	const onErrorImgDoctor = (e: any) => {
		e.target.src = default_Img_Doctor;
	};

	return (
		<IonPage className="pg-video-detail">
			<HeaderBase title="톡톡 피부 영상" backHref="/" />
			{video === null ? (
				<IonContent>
					<div className="content-inner">
						<div className="no-result">톡톡 피부 영상 정보를 불러오지 못했습니다.</div>
					</div>
				</IonContent>
			) : (
				<IonContent>
					<div className="content-inner">
						<div className="video-thumbnail-wrapper">
							<div className="video-thumbnail">
								<ReactPlayer
									playing
									controls={true}
									url={video.videoLink}
									width="100%"
									height="100%"
									light={video.thumbnail}
									onStart={() => {
										processApi("video-playcount-increase", {id: params.id});
									}}
								/>
							</div>
						</div>

						<IonGrid className="ion-padding">
							<IonRow>
								<IonCol className="padding-bottom-0">
									<IonItem>
										<IonLabel>
											<h2>{video.title}</h2>
											{(video.hashTags?.length ?? 0) > 0 ? (
												<h4 className="hashtag">
													{video.hashTags?.map(x => {
														return (
															<span key={x} onClick={() => history.push(`/search/video`, x)}>{`#${x} `}</span>
														);
													})}
												</h4>
											) : (
												<></>
											)}
											<p>게시일 {date ? moment(date).format(DATE_FORMAT) : "-"}</p>
											{video.isAd ? (
												<div className="bookmark ad">
													<i className="icon-ad" />
												</div>
											) : (
												<BookmarkButton
													bookmark={bookmark}
													setBookmark={setBookmark}
													type={"Video"}
													item={video}
												/>
											)}
										</IonLabel>
									</IonItem>
								</IonCol>
							</IonRow>
							{video.description && (
								<IonRow className="description">
									{video.description.split("\r\n").map((splitedDescription, index) => (
										<p key={index} dangerouslySetInnerHTML={{__html: splitedDescription}} />
									))}
								</IonRow>
							)}
							{!video.isAd && (
								<IonRow>
									<IonCol className="padding-0">
										<div
											onClick={() => window.open("https://naver.me/FijKgAtg", "_blank")}
											className="btn-ask ion-padding"
										>
											<div className="text-wrap">
												<p>피부에 대한 모든 궁금증</p>
												<p>스닥에게 질문하세요!</p>
											</div>
											<img src="assets/img/skin-info_img01.png" alt="docter" />
										</div>
									</IonCol>
								</IonRow>
							)}
							{video.isAd && (
								<IonRow className="ad-area">
									<button className="btn-ad" onClick={() => window.open(video.adLink, "_blank")}>
										{video.adLinkTitle}
									</button>
								</IonRow>
							)}
							{!video.isAd && video.doctorName && (
								<IonRow className="profile">
									<IonCol>
										<h3>의사프로필</h3>
										<IonItem>
											<IonAvatar slot="start">
												<img
													src={video.doctorImg ? video.doctorImg : "assets/img/profile_default.png"}
													onError={onErrorImgDoctor}
													alt="doctor"
												/>
											</IonAvatar>
											<IonLabel>
												<h4>{video.doctorName}</h4>
												<h5>{video.doctorClinic}</h5>
											</IonLabel>
										</IonItem>
									</IonCol>
								</IonRow>
							)}

							<IonRow>
								<IonCol>
									{(video.links?.length ?? 0) > 0 ? <h3>관련영상</h3> : <></>}
									<IonList className="related-video">
										{video.links?.map((linkVideo: any, index) => {
											return <VideoItem video={linkVideo} key={`linkVideo_${linkVideo.id}`} />;
										})}
									</IonList>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</IonContent>
			)}
		</IonPage>
	);
};

export default SkinVideoDetail;
