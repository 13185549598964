/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useCallback, useState} from "react";
import {IonPage, IonItem, IonLabel, IonContent, IonAccordion, IonAccordionGroup} from "@ionic/react";
import {useRecoilState} from "recoil";
import moment from "moment";
import {noticeListState, noticeSingleState} from "../../store/atoms";
import HeaderBase from "../../components/HeaderBase";
import {useApi} from "../../hooks/api";
import {NoticeDto} from "../../store/types";
import {DATE_FORMAT} from "../../utils/DateUtil";

const Notice: React.FC = () => {
	const {processApi} = useApi();

	const [list, setList] = useRecoilState(noticeListState);
	const [notice, setNotice] = useRecoilState(noticeSingleState);

	const [id, setId] = useState<number>(1);
	const [date, setDate] = useState<any>();

	const fetchList = useCallback(async () => {
		const list = await processApi<NoticeDto[]>("notice");
		setList(list);
	}, []);

	const fetchData = useCallback(async (id: number) => {
		const data = await processApi<any>("notice-item", {id: id});
		setNotice(data);

		const newDate = data.lastUpdated.slice(0, 3).toString();
		setDate(newDate);
	}, []);

	useEffect(() => {
		fetchList();
	}, []);

	useEffect(() => {
		fetchData(id);
	}, [id]);

	return (
		<IonPage className="pg-notice">
			<HeaderBase title="공지 사항" backHref="/"></HeaderBase>

			<IonContent>
				<div className="content-inner">
					<IonAccordionGroup>
						{list?.map((item, index) => (
							<IonAccordion key={index}>
								<IonItem slot="header" onClick={() => setId(Number(item.id))} className="header">
									<IonLabel className="ion-padding">
										<h2>{item.title}</h2>
										<p>{date ? moment(date).format(DATE_FORMAT) : "-"}</p>
									</IonLabel>
								</IonItem>

								<IonItem slot="content" className="content">
									<p className="ion-padding">{notice?.content}</p>
								</IonItem>
							</IonAccordion>
						))}
					</IonAccordionGroup>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Notice;
