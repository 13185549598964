/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState, useRef} from "react";
import {
	IonPage,
	IonContent,
	IonButton,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonSearchbar,
	IonItem,
	IonLabel,
	IonList,
	useIonAlert,
	useIonToast,
} from "@ionic/react";

import {useHistory, useLocation, useParams} from "react-router";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
	enableRecentSearchState,
	recentClinicListState,
	recentVideoListState,
	recentWikiListState,
	searchClinicListState,
	searchPageIndexState,
	searchVideoListState,
	searchWikiListState,
	searchPageTypeState,
	searchInfoListState,
	recentInfoListState,
} from "../../store/atoms";
import {useApi} from "../../hooks/api";
import VideoItem from "../../components/VideoItem";
import {ListData, RecentSearchItem, SearchPageType} from "../../store/types";
import ClinicItem from "../../components/presentational/ClinicItem";
import {recentSearchSelector} from "../../store/selectors";
import useCurrentLocation, {geoLocationOptions} from "../../hooks/useCurrentPosition";
import {SkinInfoItem} from "../../components";

const Search: React.FC = () => {
	const pageSize = 10;
	const [pageIndex, setPageIndex] = useRecoilState(searchPageIndexState);

	const searchPageType = ((useParams() as any)?.type as SearchPageType) ?? "clinic";

	const {processApi} = useApi();
	const history = useHistory();
	const location = useLocation();
	const {location: currentLocation, error: currentError} = useCurrentLocation(geoLocationOptions);

	const [clinicList, setClinicList] = useRecoilState(searchClinicListState);
	const [videoList, setVideoList] = useRecoilState(searchVideoListState);
	const [wikiList, setWikiList] = useRecoilState(searchWikiListState);
	const [infoList, setInfoList] = useRecoilState(searchInfoListState);

	const [enableRecentSearch, setEnableRecentSearch] = useRecoilState(enableRecentSearchState);

	const setRecentClinicList = useSetRecoilState(recentClinicListState);
	const setRecentVideoList = useSetRecoilState(recentVideoListState);
	const setRecentWikiList = useSetRecoilState(recentWikiListState);
	const setRecentInfoList = useSetRecoilState(recentInfoListState);
	const recentSearchList = useRecoilValue(recentSearchSelector);
	const setSearchPageType = useSetRecoilState(searchPageTypeState);

	const [showRecentSearch, setShowRecentSearch] = useState<boolean>(true);
	const [present] = useIonAlert();
	const [showToast] = useIonToast();

	const [lastSearchText, setLastSearchText] = useState<string>("");

	const searchInputRef: any = useRef(null);

	const fetchList = useCallback(
		async (searchPageType: SearchPageType, pageIndex: number, text: string, currentLocation) => {
			const path = searchPageType === "wiki" ? "skinwiki" : searchPageType.toString();

			let params: any = {pageIndex: pageIndex, pageSize: pageSize};
			if (text) {
				params["keyword"] = text;
			}

			if (searchPageType === "clinic" && currentLocation !== undefined) {
				params["lat"] = currentLocation.lat;
				params["lng"] = currentLocation.lng;
			}

			const data = await processApi<any>(path, params);

			let setter = null;
			if (searchPageType === "clinic") {
				setter = setClinicList;
			} else if (searchPageType === "video") {
				setter = setVideoList;
			} else if (searchPageType === "wiki") {
				setter = setWikiList;
			} else if (searchPageType === "info") {
				setter = setInfoList;
			}

			if (setter !== null) {
				setter((prev: ListData<any>) => {
					if (pageIndex === 0) return data;
					else return {total: data.total, list: [...prev.list, ...data.list]};
				});
			}
			setPageIndex(pageIndex + 1);
		},
		[],
	);

	// 검색
	const onSearch = (text: string) => {
		if (text && enableRecentSearch) {
			let setter: any = null;

			if (searchPageType === "clinic") {
				setter = setRecentClinicList;
			} else if (searchPageType === "video") {
				setter = setRecentVideoList;
			} else if (searchPageType === "wiki") {
				setter = setRecentWikiList;
			} else if (searchPageType === "info") {
				setter = setRecentInfoList;
			}

			setter((list: RecentSearchItem[]) => {
				let index = list.findIndex(x => x.searchPageType === searchPageType && x.text === text);

				if (index !== -1) {
					const listA = list.slice(0, index);
					const listB = list.slice(index + 1, list.length);
					return [{text: text, searchPageType: searchPageType}, ...listA, ...listB].slice(0, 5);
				} else {
					return [{text: text, searchPageType: searchPageType}, ...list].slice(0, 5);
				}
			});

			setShowRecentSearch(false);
		} else if (text === "") {
			showToast({
				message: "검색어를 입력해주세요.",
				duration: 600,
			});
			return;
		}

		setLastSearchText(text);
		setShowRecentSearch(false);

		fetchList(searchPageType, 0, text, currentLocation);
		searchInputRef.current.value = text;
		//setFormText(text);
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setPageIndex(0);

		searchInputRef.current.getInputElement().then((inputEl: any) => {
			inputEl.blur();
			onSearch(searchInputRef.current.value);
		});
	};

	// 자동 저장 Alert
	const handleAutoSaveClick = useCallback(() => {
		if (!enableRecentSearch) {
			showToast({
				message: "자동 저장이 켜졌습니다.",
				duration: 600,
				onWillPresent: () => {
					setEnableRecentSearch(true);
				},
			});
		}

		if (enableRecentSearch) {
			present({
				message: "자동 저장 기능 사용을</br>중지 하시겠습니까?",
				buttons: [
					"취소",
					{
						text: "확인",
						cssClass: "primary",
						handler: () => {
							showToast({
								message: "자동 저장이 꺼졌습니다.",
								duration: 600,
								onWillPresent: () => {
									setEnableRecentSearch(false);
								},
							});
						},
					},
				],
				cssClass: "alert-css2",
			});
		}
	}, [enableRecentSearch]);

	// 전체 삭제 Alert

	function handleDeleteAllClick() {
		present({
			message: "최근 검색어 기록을</br>모두 삭제 하시겠습니까?",
			buttons: [
				"취소",
				{
					text: "확인",
					cssClass: "primary",
					handler: () => {
						let setter: any = null;
						if (searchPageType === "clinic") {
							setter = setRecentClinicList;
						} else if (searchPageType === "video") {
							setter = setRecentVideoList;
						} else if (searchPageType === "wiki") {
							setter = setRecentWikiList;
						} else if (searchPageType === "info") {
							setter = setRecentInfoList;
						}
						showToast({
							message: "최근 검색어가 모두 삭제되었습니다.",
							duration: 600,
							onWillPresent: () => setter([]),
						});
					},
				},
			],
			cssClass: "alert-css2",
		});
	}

	useEffect(() => {
		setSearchPageType(searchPageType);

		if (localStorage.getItem("enableRecentSearch") === null) {
			setEnableRecentSearch(true);
		}
		if (location.state) onSearch((location.state as string).toString());

		searchInputRef.current.setFocus();
	}, []);

	return (
		<IonPage className="pg-search">
			<IonHeader>
				<IonToolbar>
					<IonButtons className="back" slot="start">
						<IonButton>
							<img
								src="assets/icon/icons_back.png"
								alt="back"
								className="icon"
								// 뒤로 버튼 클릭 시 최근 검색이 열려있으면 닫음
								onClick={() => {
									let listCount = 0;
									if (searchPageType === "clinic") {
										listCount = clinicList.list.length;
									} else if (searchPageType === "video") {
										listCount = videoList.list.length;
									} else if (searchPageType === "wiki") {
										listCount = wikiList.list.length;
									} else if (searchPageType === "info") {
										listCount = infoList.list.length;
									}

									if (showRecentSearch && listCount > 0) {
										setShowRecentSearch(false);
										searchInputRef.current.value = null;
									} else {
										setClinicList({list: [], total: 0});
										setVideoList({list: [], total: 0});
										setWikiList({list: [], total: 0});
										setInfoList({list: [], total: 0});
										history.goBack();
									}
								}}
							/>
						</IonButton>
					</IonButtons>

					<form onSubmit={handleSubmit} method="post" action="" className="form-search">
						<IonSearchbar
							onIonInput={(event: any) => {
								if (searchInputRef.current.value === "") setShowRecentSearch(true);
							}}
							ref={searchInputRef}
							placeholder="검색어를 입력해주세요."
							showClearButton="focus"
							onFocus={() => {
								setShowRecentSearch(true);
							}}
						>
							<IonButtons slot="end">
								<IonButton slot="end" type="submit">
									<img src="assets/icon/icons_search.png" className="icon" alt="search"></img>
								</IonButton>
							</IonButtons>
						</IonSearchbar>
					</form>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{showRecentSearch ? (
					<>
						<div className="content-inner">
							<IonItem className="ion-padding">
								<IonLabel className="impt">최근 검색어</IonLabel>
							</IonItem>
							{!enableRecentSearch ? (
								<div className="content-sub ion-padding">최근 검색어 저장 기능이 꺼져 있습니다.</div>
							) : (
								<></>
							)}

							{enableRecentSearch && recentSearchList.length === 0 ? (
								<div className="content-sub">최근 검색어가 없습니다.</div>
							) : (
								<></>
							)}

							{enableRecentSearch && recentSearchList.length > 0 ? (
								<IonList className="ion-padding recent-search">
									{recentSearchList?.map((item, index) => (
										<IonItem key={`item${index}`}>
											<IonLabel
												onClick={() => {
													onSearch(item.text);
												}}
											>{`${item.text}`}</IonLabel>
											<img
												src="assets/icon/icons_cancel.png"
												className="icon cancel"
												alt="cancel"
												onClick={() => {
													let setter: any = null;
													if (searchPageType === "clinic") {
														setter = setRecentClinicList;
													} else if (searchPageType === "video") {
														setter = setRecentVideoList;
													} else if (searchPageType === "wiki") {
														setter = setRecentWikiList;
													} else if (searchPageType === "info") {
														setter = setRecentInfoList;
													}

													setter((list: RecentSearchItem[]) => {
														let index = list.findIndex(
															x => x.searchPageType === searchPageType && x.text === item.text,
														);
														if (index !== -1) {
															const listA = list.slice(0, index);
															const listB = list.slice(index + 1, list.length);
															return [...listA, ...listB];
														} else {
															return [...list];
														}
													});
												}}
											/>
										</IonItem>
									))}
								</IonList>
							) : (
								<></>
							)}

							<IonItem className="ion-padding chk">
								<IonLabel slot="start" onClick={handleAutoSaveClick}>
									{enableRecentSearch && <span>자동저장 끄기</span>}
									{!enableRecentSearch && <span>자동저장 켜기</span>}
								</IonLabel>
								<IonLabel slot="end" onClick={handleDeleteAllClick}>
									<span>전체삭제</span>
								</IonLabel>
							</IonItem>
						</div>
					</>
				) : (
					<></>
				)}
				<>
					<div className="content-inner">
						{/* 검색 결과 : 병원 */}
						{!showRecentSearch && searchPageType === "clinic" && (clinicList?.list?.length ?? 0) === 0 ? (
							<div className="no-result">검색 결과가 없습니다.</div>
						) : (
							<></>
						)}
						{!showRecentSearch && searchPageType === "clinic" ? (
							<IonList>
								{clinicList?.list?.map((clinic, index) => (
									<ClinicItem clinic={clinic} key={clinic.id}></ClinicItem>
								))}
								{(clinicList?.list?.length ?? 0) > 0 &&
								(clinicList?.total ?? 0) > (pageIndex === 0 ? 1 : pageIndex) * pageSize ? (
									<IonItem
										className="btn-more clinic"
										onClick={() => fetchList(searchPageType, pageIndex, lastSearchText, currentLocation)}
									>
										<p>
											더보기<em></em>
										</p>
									</IonItem>
								) : (
									<></>
								)}
							</IonList>
						) : (
							<></>
						)}

						{/* 검색 결과 : 피부영상 */}
						{!showRecentSearch && searchPageType === "video" && (videoList?.list?.length ?? 0) === 0 ? (
							<div className="no-result">검색 결과가 없습니다.</div>
						) : (
							<></>
						)}
						{!showRecentSearch && searchPageType === "video" ? (
							<IonList className="pg-skin-video">
								{videoList.list?.map((video, index) => (
									<VideoItem video={video} key={video.id}></VideoItem>
								))}
								{(videoList?.list?.length ?? 0) > 0 &&
								(videoList?.total ?? 0) > (pageIndex === 0 ? 1 : pageIndex) * pageSize ? (
									<IonItem
										className="btn-more"
										onClick={() => fetchList(searchPageType, pageIndex, lastSearchText, currentLocation)}
									>
										<p>
											더보기<em></em>
										</p>
									</IonItem>
								) : (
									<></>
								)}
							</IonList>
						) : (
							<></>
						)}

						{/* 검색 결과 : 백과사전 */}
						{!showRecentSearch && searchPageType === "wiki" && (wikiList?.list?.length ?? 0) === 0 ? (
							<div className="no-result">검색 결과가 없습니다.</div>
						) : (
							<></>
						)}
						{!showRecentSearch && searchPageType === "wiki" ? (
							<IonList className="pg-skin-dict">
								{wikiList.list.map((x, index) => (
									<IonItem
										className="ion-padding"
										key={`item${index}`}
										onClick={() => {
											history.push(`/wiki/${x.id}`);
										}}
									>
										<IonLabel>{`${x.mainSubject}`}</IonLabel>
										<img src="assets/icon/icons_next.png" className="icon" alt="move" />
									</IonItem>
								))}
								{(wikiList?.list?.length ?? 0) > 0 &&
								(wikiList?.total ?? 0) > (pageIndex === 0 ? 1 : pageIndex) * pageSize ? (
									<IonItem
										className="btn-more"
										onClick={() => fetchList(searchPageType, pageIndex, lastSearchText, currentLocation)}
									>
										<p>
											더보기<em></em>
										</p>
									</IonItem>
								) : (
									<></>
								)}
							</IonList>
						) : (
							<></>
						)}

						{/* 검색 결과 : 피부정보 */}
						{!showRecentSearch && searchPageType === "info" && (infoList?.list?.length ?? 0) === 0 ? (
							<div className="no-result">검색 결과가 없습니다.</div>
						) : (
							<></>
						)}
						{!showRecentSearch && searchPageType === "info" ? (
							<IonList className="pg-skin-info">
								<div className="info-contents">
									{infoList.list?.map((info, index) => (
										<SkinInfoItem info={info} key={info.id} />
									))}
								</div>
								{(infoList?.list?.length ?? 0) > 0 &&
								(infoList?.total ?? 0) > (pageIndex === 0 ? 1 : pageIndex) * pageSize ? (
									<IonItem
										className="btn-more"
										onClick={() => fetchList(searchPageType, pageIndex, lastSearchText, currentLocation)}
									>
										<p>
											더보기<em></em>
										</p>
									</IonItem>
								) : (
									<></>
								)}
							</IonList>
						) : (
							<></>
						)}
					</div>
				</>
			</IonContent>
		</IonPage>
	);
};

export default Search;
