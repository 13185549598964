/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useEffect} from "react";
import {
	IonPage,
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonGrid,
	IonCol,
	IonRow,
	IonButton,
	IonLoading,
	useIonAlert,
	useIonToast,
} from "@ionic/react";

import HeaderBase from "../../components/HeaderBase";
import {useApi} from "../../hooks/api";
import {useHistory} from "react-router";
import {Controller, useForm} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";
import {useRecoilState} from "recoil";
import {userState} from "../../store/atoms";

interface FormData {
	nick?: string;
}

const MyInfoNicknm: React.FC = () => {
	const {processApi} = useApi();
	const history = useHistory();

	const [showLoading, setShowLoading] = useState(false);
	const [nicknameValidation, setNicknameValidation] = useState(false);
	const [user, setUser] = useRecoilState(userState);
	const [showToast] = useIonToast();
	const [present] = useIonAlert();

	const [form, setForm] = useState({
		nick: "",
	});
	const message = "이미 존재하는 닉네임입니다.";

	const {
		control,
		getValues,
		handleSubmit,
		setError,
		watch,
		clearErrors,
		formState: {errors},
	} = useForm<FormData>({
		defaultValues: form,
	});

	const nickname: any = useRef();
	nickname.current = watch("nick");

	useEffect(() => {
		setNicknameValidation(false);
	}, [nickname.current]);

	const checkNick = async (e: any) => {
		e.preventDefault();
		const input = getValues("nick");
		if (input) {
			const duplicatedNickname = await processApi<any>("check-nick", {nick: input});
			setNicknameValidation(!duplicatedNickname);
			if (duplicatedNickname) {
				setError("nick", {
					type: "manual",
					message,
				});
			} else {
				clearErrors();
			}
		}
	};
	const handleSubmitNickname = async (data: any, e: any) => {
		e.preventDefault();
		if (nicknameValidation) {
			try {
				setShowLoading(true);
				await processApi<any>("update-user-nick", {nick: data.nick});
				localStorage.setItem("nick", data.nick);
				setUser({...user, nick: data.nick});
				setShowLoading(false);
				present({
					message: "닉네임 변경이<br/>완료되었습니다.",
					buttons: [{text: "확인", cssClass: "primary", handler: () => history.replace("/myinfo")}],
					cssClass: "alert-css2",
				});
			} catch (error: any) {
				setShowLoading(false);
				showToast({
					message: "처리 중 오류가 발생하였습니다.",
					duration: 600,
				});
			}
		}
	};

	return (
		<IonPage className="pg-mypage-info pg-nicknm">
			<HeaderBase title="닉네임 변경" backHref="/" />
			<IonContent fullscreen>
				<div className="content-inner">
					<IonLoading
						isOpen={showLoading}
						message="Logging in..."
						onDidDismiss={() => setShowLoading(false)}
					/>
					<form onSubmit={handleSubmit(handleSubmitNickname)} method="post" action="">
						<IonGrid className="ion-padding">
							<IonRow>
								<IonCol>
									<IonList>
										<IonItem>
											<IonLabel position="stacked">닉네임</IonLabel>
											<Controller
												name="nick"
												control={control}
												rules={{
													required: true,
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={10}
														type="text"
														onIonChange={field.onChange}
														placeholder="변경할 닉네임을 입력해주세요."
														className={errors.nick === undefined ? "" : "error"}
													/>
												)}
											/>
											<div className="chck nknm" onClick={checkNick}>
												중복확인
											</div>
										</IonItem>
										<ErrorMessage
											errors={errors}
											name="nick"
											message="닉네임 중복확인이 필요합니다."
											as={<div className="error-message" />}
										/>
										{nicknameValidation && (
											<div className="success-message">사용할 수 있는 닉네임입니다.</div>
										)}
									</IonList>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton
										type="submit"
										expand="full"
										mode="md"
										shape="round"
										disabled={!nicknameValidation}
									>
										닉네임 변경하기
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					</form>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default MyInfoNicknm;
