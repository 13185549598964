import React from "react";
import {RecoilRoot} from "recoil";
import {IonApp, setupIonicReact} from "@ionic/react";
import PageRoute from "./pages/PageRoute";
import UserEffect from "./components/UserEffect";
import {IonReactRouter} from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Main CSS - GYJ */
import "./css/styles.scss";

setupIonicReact({
	mode: "ios",
});

const App: React.FC = () => {
	return (
		<RecoilRoot>
			<UserEffect />
			<IonApp className="app">
				{/* media query로 css 처리*/}
				<IonReactRouter>
					<PageRoute />
				</IonReactRouter>
			</IonApp>
		</RecoilRoot>
	);
};

export default App;
