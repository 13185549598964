/* eslint-disable react-hooks/exhaustive-deps */
import {ErrorMessage} from "@hookform/error-message";
import {
	IonButton,
	IonContent,
	IonLoading,
	IonList,
	IonInput,
	IonItem,
	IonLabel,
	IonPage,
	IonGrid,
	IonRow,
	IonCol,
	useIonAlert,
	useIonToast,
} from "@ionic/react";
import {useEffect, useState, useRef} from "react";
import {Controller, useForm} from "react-hook-form";
import {useHistory} from "react-router";
import {useRecoilState} from "recoil";
import {useApi} from "../../hooks/api";
import HeaderBase from "../../components/HeaderBase";
import {userState, tokenState} from "../../store/atoms";

interface FormData {
	password?: string;
	passwordConfirm?: string;
}

const ResetPassword: React.FC = () => {
	const [form, setForm] = useState({
		password: "",
		passwordConfirm: "",
		route: "EMAIL", // 임시
	});
	const [showLoading, setShowLoading] = useState(false);
	const [token, setToken] = useRecoilState(tokenState);
	const [user] = useRecoilState(userState);
	const [present] = useIonAlert();
	const history = useHistory();
	const {processApi} = useApi();
	const [showToast] = useIonToast();

	useEffect(() => {
		if (user) {
			history.replace("/");
		}
	}, [user]);

	const onInputChange = (event: any, fieldName: string) => {
		setForm({
			...form,
			[fieldName]: event.detail.value,
		});
	};

	const {
		control,
		handleSubmit,
		watch,
		formState: {errors},
	} = useForm<FormData>({
		defaultValues: form,
	});

	const password: any = useRef();
	password.current = watch("password");

	const handleSubmitPassword = async (data: any, e: any) => {
		e.preventDefault();
		try {
			setShowLoading(true);
			const response = await processApi<any>("login", form);
			setToken({token: response});
			//history.push("/home");
			setShowLoading(false);
			present({
				message: "비밀번호 변경이<br/>완료되었습니다.",
				buttons: [{text: "확인", cssClass: "primary", handler: () => history.replace("/login")}],
				cssClass: "alert-css2",
			});
		} catch (error: any) {
			setShowLoading(false);
			showToast({
				message: "처리 중 오류가 발생하였습니다.",
				duration: 600,
			});
		}
	};
	const [checked, setChecked] = useState(false);
	return (
		<IonPage className="pg-sign-up pg-email-login pg-reset-pw">
			<HeaderBase title="비밀번호 변경" backHref="/" />

			<IonContent fullscreen>
				<div className="content-inner">
					<IonGrid className="ion-padding">
						<IonLoading
							isOpen={showLoading}
							message="Logging in..."
							onDidDismiss={() => setShowLoading(false)}
						/>
						<form onSubmit={handleSubmit(handleSubmitPassword)} method="post" action="">
							<IonRow>
								<IonCol>
									<IonList>
										<IonItem>
											<IonLabel position="stacked">새 비밀번호</IonLabel>
											<Controller
												name="password"
												control={control}
												rules={{
													required: true,
													pattern: {
														value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{6,}$/,
														message: "영문, 숫자, 기호를 모두 포함 6자리 이상 입력해주세요.",
													},
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={40}
														type="password"
														onIonChange={field.onChange}
														placeholder="새 비밀번호를 입력해주세요."
														className={errors.password === undefined ? "" : "error"}
													/>
												)}
											/>
										</IonItem>
										<ErrorMessage
											errors={errors}
											message="새 비밀번호를 입력해주세요."
											name="password"
											as={<div className="error-message" />}
										/>
									</IonList>
									<IonList>
										<IonItem>
											<IonLabel position="stacked">새 비밀번호 확인</IonLabel>
											<Controller
												name="passwordConfirm"
												control={control}
												rules={{
													required: true,
													validate: value => value === password.current,
												}}
												render={({field}) => (
													<IonInput
														{...field}
														maxlength={40}
														type="password"
														onIonChange={field.onChange}
														placeholder="새 비밀번호를 한번 더 입력해주세요."
														className={errors.passwordConfirm === undefined ? "" : "error"}
													/>
												)}
											/>
										</IonItem>
										<ErrorMessage
											errors={errors}
											name="passwordConfirm"
											message="새 비밀번호가 일치하지 않습니다."
											as={<div className="error-message" />}
										/>
									</IonList>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton className="" expand="full" type="submit" mode="md" shape="round">
										비밀번호 재설정하기
									</IonButton>
								</IonCol>
							</IonRow>
						</form>
					</IonGrid>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ResetPassword;
