import {Route, Redirect, RouteProps} from "react-router-dom";
import {useAuth} from "../../hooks/auth";

function AuthRoute({component, ...rest}: RouteProps) {
	const {isLoggedIn} = useAuth();
	return (
		<>
			<Route {...rest} component={component} />
			{!isLoggedIn && <Redirect to="/login" />}
		</>
	);
}

export default AuthRoute;
