/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {IonButton, IonCard, IonContent, IonFooter, IonItem, IonLabel, IonPage} from "@ionic/react";

import HeaderBase from "../../components/HeaderBase";
import {useRecoilState} from "recoil";
import {clinicIdState, clinicSingleState} from "../../store/atoms";
import {useApi} from "../../hooks/api";
import default_Img from "../../img/img_default.png";
import Map from "../../components/Map";
import MapMarker from "../../components/MapMarker";
import ClinicItem from "../../components/presentational/ClinicItem";
import useCurrentLocation, {geoLocationOptions} from "../../hooks/useCurrentPosition";
import {BookmarkButton} from "../../components/container";

const FindHospitalDetail: React.FC = () => {
	const params: any = useParams();
	const {processApi} = useApi();

	const [map, setMap] = useState<kakao.maps.Map>();
	const {location: currentLocation, error: currentError} = useCurrentLocation(geoLocationOptions);

	const [isLoaded, setIsLoaded] = useState<boolean | null>(false);

	const [clinicId, setClinicId] = useRecoilState(clinicIdState);
	const [clinic, setClinic] = useRecoilState(clinicSingleState);
	const [bookmark, setBookmark] = useState<any>(clinic?.isFav);

	const fetchData = useCallback(async (currentLocation: {lat: number; lng: number} | undefined) => {
		try {
			let parematers: any = {id: params.id};

			if (currentLocation !== undefined) {
				parematers["lat"] = currentLocation.lat;
				parematers["lng"] = currentLocation.lng;
			}

			const data = await processApi<any>("clinic-item", parematers);
			setClinic(data);
			setBookmark(data.isFav);
			setIsLoaded(true);
		} catch (error) {
			setClinic(null);
		}
	}, []);

	useEffect(() => {
		fetchData(currentLocation);

		return () => {
			setIsLoaded(null);
			setClinic(null);
			setClinicId(null);
			setMap(undefined);
			setBookmark(null);
		};
	}, [clinicId, currentLocation]);

	// const convertToHtml = (directions: any) => {
	// 	return <div dangerouslySetInnerHTML={{__html: directions}}></div>;
	// };

	// const onErrorImg = (e: any) => {
	// 	e.target.src = default_Img;
	// };

	const images = clinic?.images;
	const officeHours: any = clinic?.officeHours;

	map?.relayout();

	return (
		<IonPage className="pg-find-clinic-detail">
			<HeaderBase title={`${clinic?.name ?? "병원 찾기"}`} backHref="/" />
			<div className="bookmark-contents">
				<BookmarkButton bookmark={bookmark} setBookmark={setBookmark} item={clinic} type={"Clinic"} />
			</div>

			{isLoaded && clinic == null ? (
				<IonContent>
					<div className="content-inner">
						<div className="no-result">병원 정보를 불러오지 못했습니다.</div>
					</div>
				</IonContent>
			) : (
				<></>
			)}

			{isLoaded && clinic != null ? (
				<>
					<IonContent>
						<div className="content-inner">
							{/* <IonItem>
								<IonSlides
									pager={true}
									className="slide-banner"
									options={{
										centeredSlides: true,
										loop: true,
									}}
								>
									{imagesArr ? (
										<div>
											{imagesArr?.map((image: any, index) => {
												return (
													<IonSlide key={`image_${clinic.id}_${image}`}>
														<img src={image} alt="clinic" onError={onErrorImg} />
													</IonSlide>
												);
											})}
										</div>
									) : (
										<IonSlide>
											<img src="assets/img/img_default.png" alt="clinic" />
										</IonSlide>
									)}
								</IonSlides>
							</IonItem> */}

							<ClinicItem
								clinic={clinic}
								showBookmark={false}
								homepage={true}
								notGoToDetailPage={true}
							></ClinicItem>

							{(clinic.officeHours?.length ?? 0) > 0 ? (
								<IonItem className="ion-padding">
									<IonLabel>
										<h4>진료 시간</h4>
										<div className="officeHours-box">
											{clinic?.officeHours?.map((item, index) => {
												if (index % 2 === 0) {
													return (
														<div className="officeHours-row" key={`officeHours_${clinic.id}_${index}`}>
															<div>
																{officeHours.length > index ? (
																	<>
																		<label>{officeHours[index].day}</label>
																		<p>
																			{officeHours[index].openTime}~{officeHours[index].closingTime}
																		</p>
																	</>
																) : (
																	<></>
																)}
															</div>
															<div>
																{officeHours.length > index + 1 ? (
																	<>
																		<label>{officeHours[index + 1].day}</label>
																		<p>
																			{officeHours[index + 1].openTime}~{officeHours[index].closingTime}
																		</p>
																	</>
																) : (
																	<></>
																)}
															</div>
														</div>
													);
												}
											})}
											{officeHours.length > 6 ? (
												<div className="officeHours-row">
													<div className="sunday">
														<label>{officeHours[6].day}</label>
														<p>
															{officeHours[6].openTime}~{officeHours[6].closingTime}
														</p>
													</div>
												</div>
											) : (
												<></>
											)}
										</div>
									</IonLabel>
								</IonItem>
							) : (
								<></>
							)}
							<IonItem className="ion-padding location-info">
								<IonLabel>
									<h4>위치 정보</h4>
									{clinic.directions ? <p>{clinic.directions}</p> : <></>}
								</IonLabel>
							</IonItem>

							<IonCard className="ion-padding">
								<Map
									id="map"
									center={{lat: 37.5283169, lng: 126.734086}}
									style={{
										width: "100%",
										height: "250px",
									}}
									onCreate={map => {
										setMap(map);
										map.setCenter(new kakao.maps.LatLng(clinic?.lat ?? 0, clinic?.lng ?? 0));
									}}
									draggable={false}
									zoomable={false}
									level={5}
								>
									<MapMarker position={{lat: clinic?.lat ?? 0, lng: clinic?.lng ?? 0}} />
								</Map>
							</IonCard>

							<IonItem className="ion-padding">
								<IonLabel>
									<h5>제공 : 건강보험심사평가원</h5>
								</IonLabel>
							</IonItem>
						</div>
					</IonContent>

					<IonFooter>
						<IonButton
							expand="full"
							onClick={() => {
								document.location.href = "tel:" + clinic.phoneNumber;
							}}
						>
							전화하기
						</IonButton>
					</IonFooter>
				</>
			) : (
				<></>
			)}
		</IonPage>
	);
};

export default FindHospitalDetail;
